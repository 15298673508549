import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import { IconButton, Modal } from "@fluentui/react";
import { Container, ChatImage, CloseModal } from "./style";

const close = { iconName: "ChromeClose" };

export const ChatImageModal = ({ isModalOpen, hideModal, image }) => {
  const titleId = useId("title");

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      isBlocking={false}
    >
      {image && (
        <Container>
          <CloseModal>
            <IconButton
              onClick={() => hideModal()}
              iconProps={close}
              title='Close'
              ariaLabel='Close'
            />
          </CloseModal>
          <ChatImage src={image} />
        </Container>
      )}
    </Modal>
  );
};
