import React, { useState } from "react";
import Button from "../../../../../../core/ui/buttonFluent";
import {
  CalloutContainer,
  Input,
  ButtonsContainer,
  FileUploader,
  UploaderContainer,
} from "./style";
import {
  DirectionalHint,
  TextField,
  DefaultButton,
  Separator,
} from "@fluentui/react";

export const FileUpload = ({
  isCalloutVisible,
  toggleIsCalloutVisible,
  buttonId,
  handleLink,
  setFile,
  file,
  uploadFile,
  validLinks,
  clearLinkErrors,
}) => {
  return (
    <>
      {isCalloutVisible && (
        <CalloutContainer
          directionalHint={DirectionalHint.topAutoEdge}
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          <Input>
            <TextField
              label='Link da imagem'
              placeholder='https://...'
              autoComplete="new-password"
              errorMessage={validLinks.href}
              onFocus={() => clearLinkErrors("href")}
              onChange={(e, val) => handleLink("href", val)}
            />
          </Input>
          <Separator> ou </Separator>
          <UploaderContainer>
            <FileUploader
              type='file'
              id='file'
              accept='image/*'
              onChange={(event) => {
                setFile(event?.target?.files[0]);
              }}
            />
            <label htmlFor='file'>Carregar do meu computador</label>
            <span htmlFor='file'>{file?.name}</span>
          </UploaderContainer>
          <ButtonsContainer>
            <DefaultButton onClick={() => toggleIsCalloutVisible()}>
              Cancelar
            </DefaultButton>
            <Button text='Inserir' onClick={() => uploadFile()} />
          </ButtonsContainer>
        </CalloutContainer>
      )}
    </>
  );
};

export default FileUpload;
