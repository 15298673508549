const initialState = {
  isAuthenticated: false,
  token: null,
  email: null,
  name: null,
  account: null,
  loadingTokens: false,
  authCredentialsAdmin: null,
  adInfo: null,
  loadingAd: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_AUTH_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_USER_ACCOUNT":
      return {
        ...state,
        account: action.payload,
      };
    case "GET_TOKENS_START":
      return {
        ...state,
        loadingTokens: true,
      };
    case "GET_TOKENS_SUCCESS":
      return {
        ...state,
        authCredentialsAdmin: action.payload,
        loadingTokens: false,
      };
    case "GET_AD_INFO_START":
      return {
        ...state,
        loadingAd: true,
      };
    case "GET_AD_INFO_SUCCESS":
      return {
        ...state,
        adInfo: action.payload,
        loadingAd: false,
      };
    case "GET_AD_INFO_FAILURE":
      return {
        ...state,
        loadingAd: false,
      };
    case "GET_TOKENS_FAILURE":
      return {
        ...state,
        authCredentialsAdmin: null,
        loadingTokens: false,
      };
    default:
      return state;
  }
};

export default authReducer;
