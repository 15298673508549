import moment from "moment/moment";
import React from "react";
import { DefaultButton } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { getUpdateList, updateFilter, handleOpenPushPanel } from "../../../../../../core/redux/actions/clientsAction";
import { DatePickerInput } from "../../../../../signals/components/headerFilterList/style";
import {
  FiltersContainer,
  SearchContainer,
  SearchButton,
  SearchInput,
  FilterOptions,
  Select,
  Divider,
} from "./style";

const optionsPlans = [
  { key: 3, text: "Todos" },
  { key: 0, text: "Plano Free" },
  { key: 1, text: "Plano ICI" },
  { key: 2, text: "Plano TCI" },
  { key: 3, text: "Plano PRO" },
];

const Filters = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((store) => store.clients);

  return (
    <FiltersContainer>
      <FilterOptions>
        <Select
          label='Plano'
          options={optionsPlans}
          selectedKey={filters.Plans ?? optionsPlans[0].key}
          value={filters.Plans}
          onChange={(ev, val) => dispatch(updateFilter("Plans", val.key))}
        />
        <DatePickerInput
          label='Criado até'
          placeholder='Selecione uma data...'
          showMonthPickerAsOverlay={true}
          value={filters.CreatedUntil}
          onSelectDate={(val) => dispatch(updateFilter('CreatedUntil', val))}
          formatDate={(date) => moment(date).format('DD/MM/YYYY')}
        />
        <DatePickerInput
          label='Ativo até'
          placeholder='Selecione uma data...'
          showMonthPickerAsOverlay={true}
          value={filters.ActiveUntil}
          onSelectDate={(val) => dispatch(updateFilter('ActiveUntil', val))}
          formatDate={(date) => moment(date).format('DD/MM/YYYY')}
        />
      </FilterOptions>
      <Divider />
      <SearchContainer>
        <SearchInput
          placeholder='Buscar por Nome, Email ou Documento...'
          value={filters.NameEmailDocument}
          onChange={(ev, val) => dispatch(updateFilter('NameEmailDocument', val))}
        />
        <SearchButton
          text='Buscar'
          iconProps={{ iconName: 'Search' }}
          onClick={() => dispatch(getUpdateList())}
        />
      </SearchContainer>
      {/* <Divider />
      <DefaultButton text='Enviar push' onClick={() => dispatch(handleOpenPushPanel(true))} /> */}
    </FiltersContainer>
  );
};

export default Filters;
