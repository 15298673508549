import React from 'react';
import Select, { components } from 'react-select';
import MenuList from '../coinsMenuList';
import Option from '../coinsOptions';
import { FontIcon } from '@fluentui/react/lib/Icon';

const handleBorder = (disabled, focus) => {
  if (disabled) return 'transparent'
  if (focus) return brandColor
  return 'rgb(54, 54, 53)'
}

const brandColor = '#DB6714';

const customStyles = {
  indicatorSeparator: () => ({
    dislay: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#000',
    fontSize: '14px',
    backgroundColor: state.isSelected ? brandColor : '#fff',
    '&:hover': {
      backgroundColor: state.isSelected ? brandColor : '#f7f2f2',
      color: state.isSelected ? '#fff' : '#000',
    },
  }),
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    borderRadius: '2px',
    // border: '1px solid rgba(0, 0, 0, 0.815)',
    boxShadow: state.isFocused ? 0 : 0,
    borderWidth: state.isFocused ? '2px' : '1px',
    borderColor: handleBorder(state.isDisabled, state.isFocused),
    '&:hover': {
      borderColor: state.isFocused ? brandColor : 'rgba(0, 0, 0, 0.815)',
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? 'rgba(54, 54, 53, 0.63)' : 'rgb(54, 54, 53)',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    color: '#1F2937',
    fontSize: '14px',
  }),
};

const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontIcon aria-label="Search" iconName="Search" />
      </components.DropdownIndicator>
    );
  };

const CoinSelectBox = (props) => {
  const { options, value, onChange, placeholder, loading, disabled, error } = props;

  return (
    <>
      <Select
        options={options}
        value={value && [value]}
        onChange={onChange}
        classNamePrefix='react-select'
        styles={customStyles}
        placeholder={placeholder}
        isLoading={loading}
        isDisabled={disabled}
        components={{
          MenuList,
          Option,
          DropdownIndicator,
        }}
        {...props}
      />
      {error && <p color='red'>Erro ao escolher a moeda</p>}
    </>
  );
};

export default CoinSelectBox;
