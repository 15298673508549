import React from 'react';
import {
  DatePickerInput,
  Container,
  InitialFilters,
  CreateSignalButton,
  ButtonsAlign,
  TextInput,
  ComboInput,
} from './style';
import { DefaultButton } from '@fluentui/react/lib/Button';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCupomById,
  getCupomsList,
  setPainelIsOpen,
  updateFilter,
} from '../../../../core/redux/actions/cupomsActions';

const optionsCupomStatus = [
  { key: null, text: "Todos" },
  { key: true, text: "Ativos" },
  { key: false, text: "Não ativos" },
];

const HeaderFilterList = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((store) => store.cupom);

  return (
    <Container>
      <InitialFilters>
        <TextInput
          label='Nome'
          placeholder='Digite o nome...'
          value={filters.name}
          onChange={(e, val) => dispatch(updateFilter('name', val))}
        />
        <TextInput
          label='Porcentagem'
          placeholder='Desconto...'
          value={filters.percentage}
          onChange={(e, val) => dispatch(updateFilter('percentage', val))}
        />
        <ComboInput
          placeholder='Selecione uma opção...'
          label='Status'
          options={optionsCupomStatus}
          value={filters.active}
          onChange={(ev, val) => dispatch(updateFilter("active", val.key))}
        />
        <DatePickerInput
          label='Data de início'
          placeholder='Selecione uma data...'
          value={filters.startDate}
          showMonthPickerAsOverlay={true}
          onSelectDate={(val) => dispatch(updateFilter('startDate', val))}
          formatDate={(date) => moment(date).format('DD/MM/YYYY')}
        />
        <DatePickerInput
          label='Data final'
          placeholder='Selecione uma data...'
          value={filters.endDate}
          showMonthPickerAsOverlay={true}
          onSelectDate={(val) => dispatch(updateFilter('endDate', val))}
          formatDate={(date) => moment(date).format('DD/MM/YYYY')}
        />

        <ButtonsAlign>
          <DefaultButton onClick={() => dispatch(getCupomsList())} iconProps={{ iconName: 'Search' }}>
            Buscar
          </DefaultButton>
        </ButtonsAlign>

        <CreateSignalButton>
          <ButtonsAlign>
            <DefaultButton
              text='Criar Cupom'
              onClick={() => {
                dispatch(setPainelIsOpen(true));
                dispatch(getCupomById(null));
              }}
              iconProps={{ iconName: 'Add' }}
            />
          </ButtonsAlign>
        </CreateSignalButton>
      </InitialFilters>
    </Container>
  );
};

export default HeaderFilterList;
