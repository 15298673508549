export const breakPoints = {
    xs: "600px",
    sm: "960px",
    md: "1280px",
    xl: "1600px",
    xxl: "1920px"
};

export const textSizes = {
    level1: "12px",
    level2: "14px",
    level3: "16px",
    level4: "18px",
    level5: "22px",
    level6: "24px",
    level7: "32px",
    level8: "48px",
};

export const colors = {
    primary: '#000',
    secondary: '#000',
    contrast: '#000',
    favorite: '#000',
};

export const spaces = {
    level1: "12px",
    level2: "16px",
    level3: "18px",
    level4: "20px",
    level5: "24px",
    level6: "32px",
    level7: "64px",
    level8: "86px",
    level9: "124px",
};