import { createGlobalStyle } from 'styled-components';
import { colors } from './constants';

export default createGlobalStyle`
    * {
        box-sizing: border-box;
        color: ${colors.primary};
    }

    body {
        margin: 0;
    }

    .ms-Panel-commands {
      background: #FFFFFF;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    [contenteditable]:focus {
      outline-color: #DB6714;
    }

    .ms-Button--primary{
      span {
        color: #FFFFFF;
      }
    }

    .ms-Persona-initials {
      span {
        color: #FFFFFF;
        font-size: 24px;
      }
    }

    .react-select {
      &__option {
        display: block;
        font-size: 16px;
        color: rgb(50, 49, 48);
        padding: 11px 12px;
        width: 100%;
        user-select: none;
        box-sizing: border-box;
        &:hover {
          background-color: #f7f2f2;
        }
        &_selected {
          background-color: #DB6714;
          color: #FFFFFF;
          &:hover {
            color: rgb(50, 49, 48);
            background-color: #f7f2f2;
          }
        }
      }
    }

    .ms-TeachingBubble-primaryButton {
      .ms-Button-textContainer {
        span {
          color: #000 !important;
        }
      }
    }  
    .ms-CalendarDay-dayIsToday {
      span {
        color: #f7f1f1;
      }
    }
`;
