import styled from 'styled-components';
import { Stack, Text, Button, TextField, PrimaryButton } from '@fluentui/react';

export const Container = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  background: #fff;
  border: solid 1px rgb(243, 242, 241);
  box-shadow: -24px 24px 30px 16px rgba(235, 228, 228, 0.2);
  opacity: 1;
  transition: opacity 0.1s ease;
  z-index: 2;
`;

export const ContainerGraphic= styled(Stack)`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  justify-content: space-around;
  padding: 20px;
  background: #fff;
  border: solid 1px rgb(243, 242, 241);
`;
              
export const HistoricalContainer = styled.div`
  height: calc(100vh - 350px);
`;

export const ComboBoxStyles = styled(Stack)`
 width: 290px;
 height: 254px;
 background: rgb(236, 236, 236);
`;

export const InstructionsContainer = styled.div`
  max-width: 450px;
  width: 100%;
`;

export const SearchBoxStyles = styled(Stack)``;

export const horizontalGapStackTokens = {
  childrenGap: 10,
  width: '100%',
  padding: "10px 0",
};

export const fluentCombobox = styled(Stack)`
  font-family: sans-serif;
  padding: 1em;  
`;

export const DefaultButton = styled(Stack)`
  width: 100%;
  background-color: #DB6714;
  border-radius: 8px;
  height: 55px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
`;

export const GraphicInput = styled(TextField)`
  width: 100%;
`;

export const ShowGraphic = styled.button`
  cursor: pointer;
  font-weight: 500;
  border: none;
  background: transparent;
  padding: 0;
  border-bottom: solid 1px transparent;
  transform: ease 0.2s;
  color: #DB6714;
  margin-top: 10px;

  &:hover {
    border-color: #DB6714;
  }
`;

export const FiltersSection = styled.div`
  background-color: #faf6f6ee;
  padding: 6px 18px;
  border-radius: 2px;
  margin-bottom: 30px;
  min-width: 590px;
  width: 100%;
`;

export const TargetsValues = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
`;

export const TargetsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-bottom: 12px;
  row-gap: 30px;
`;

export const PercentInput = styled(TextField)`
  width: 60px;
  border: none;

  .fieldGroup {
    border: none;
  }
`;

export const EntryInput = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const InputSize = styled.div`
  width: calc(100% - 60px);
`;

export const ButtonStyles = styled(PrimaryButton)`
  width: 100%;

  span {
    color: ${props => props.textColor || "#fff"};
  }
`;

export const EditGraphic = styled.div`
  position: relative;
  width: 100%;
`;

export const ShowGraphicEdit = styled.div`
  position: absolute;
  right: 12px;
  top: -3px;
`;

export const Alerts = styled.div`
  padding-top: 30px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const IntructionsColumn = styled.div`
  min-width: 300px;
  max-width: 400px;
  width: 100%;
`;

export const DeleteIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: -8px;
`;

export const AddContainer = styled.div``;