import { DefaultButton, Stack } from "@fluentui/react";
import Long from "../../core/assets/images/long.svg";
import Short from "../../core/assets/images/short.svg";
import moment from "moment";
import { PositionImage, EntryType } from "./style";
import {
  signalCreationDate,
  signalCloseDate,
} from "../../core/redux/actions/signalsAction";

const now = moment();

const renderDate = (dateStop, dateClosed) => {
  if (dateStop) {
    return moment(dateStop).locale("pt-br").format("DD/MM/YYYY HH:mm:ss");
  } else if (dateClosed) {
    return moment(dateClosed).locale("pt-br").format("DD/MM/YYYY HH:mm:ss");
  } else {
    return "";
  }
};

const renderEntryType = (value) => {
  if (value || value === 0) {
    if (value === 0) return "Mercado";
    if (value === 1) return "Rompimento";
    if (value === 2) return "Retração";
  } else {
    return "";
  }
};

const renderStatusTrade = (value, date) => {
  if (value || value === 0) {
    if (value === 0) return "Fechado";
    if (value === 1) return "Ativado";
    if (value === 2) return "Stand-by";
  } else {
    return "";
  }
};

const renderActivated = (activatedAt, date, entryType) => {
  if (entryType === 0) return moment(date).locale("pt-br").format("DD/MM/YYYY HH:mm:ss");

  if (activatedAt) {
    return moment(activatedAt).locale("pt-br").format("DD/MM/YYYY HH:mm:ss");
  } else {
    return '';
  }
};

const renderMoeda = (value, signalType) => {
  if (signalType === 2 && !value.includes("PERP")) {
    return `${value}_PERP`;
  } else {
    return value;
  }
};

const renderPosition = (value) => {
  if (value === 0) return <PositionImage src={Long} />;
  if (value === 1) return <PositionImage src={Short} />;
};

export default (onEdit) => [
  {
    key: "column1",
    name: "Moeda",
    fieldName: "symbol",
    minWidth: 190,
    maxWidth: 190,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => renderMoeda(item.symbol, item.signalType),
  },
  {
    key: "column2",
    name: "Data de criação",
    fieldName: "date",
    minWidth: 160,
    maxWidth: 160,
    styles: "styleColumn",
    isResizable: true,
    onRender: (item) =>
      moment(item.date).locale("pt-br").format("DD/MM/YYYY HH:mm:ss"),
  },
  {
    key: "column3",
    name: "Status",
    fieldName: "statusTrade",
    minWidth: 130,
    maxWidth: 130,
    styles: "styleColumn",
    isResizable: true,
    onRender: (item) => renderStatusTrade(item.statusTrade),
  },
  {
    key: "column4",
    name: "Input",
    fieldName: "input",
    minWidth: 140,
    maxWidth: 140,
    isResizable: true,
    styles: "styleColumn",
  },
  {
    key: "column5",
    name: "Stop Loss",
    fieldName: "stopLoss",
    minWidth: 140,
    maxWidth: 140,
    isResizable: true,
    styles: "styleColumn",
  },
  {
    key: "column6",
    name: "Data de encerramento",
    fieldName: "dateStop",
    minWidth: 200,
    maxWidth: 200,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => renderDate(item.dateStop, item.dateClosed),
  },
  {
    key: "column7",
    name: "Data de ativação",
    fieldName: "date",
    minWidth: 160,
    maxWidth: 160,
    styles: "styleColumn",
    isResizable: true,
    onRender: (item) => renderActivated(item?.activatedAt, item?.date, item?.entryType),
  },
  {
    key: "column8",
    name: "Tipo de entrada",
    fieldName: "dateStop",
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => (
      <EntryType>
        {renderEntryType(item.entryType)} {renderPosition(item.position)}
      </EntryType>
    ),
  },
  {
    key: "column9",
    name: "Ações",
    fieldName: "",
    minWidth: 110,
    maxWidth: 110,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => (
      <Stack horizontal>
        <DefaultButton text='Editar' onClick={() => onEdit(item.id)} />
      </Stack>
    ),
  },
];
