const initialState = {
  //List
  clientList: [],
  loadingList: false,
  hasMore: true,
  page: 1,
  filters: {
    NameEmailDocument: null,
    CreatedUntil: null,
    ActiveUntil: null,
    SubscriptionStatus: null,
    Plans: null,
  },
  //Details
  user: null,
  panelIsOpen: false,
  clientDetails: {},
  loadingDetails: false,

  //Update
  loadingUpdate: false,
  updateSuccess: false,
  updateError: null,
  loadingCancel: false,
  successCancel: false,
  failureCancel: false,

  //extract
  loadingExtract: false,
  extract: [],

  //user delete
  loadingDelete: false,
  successDelete: false,
  failureDelete: false,

  //Push
  pushPanelIsOpen: false,
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLIENT_LIST_LOADING":
      return {
        ...state,
        loadingList: true,
      };
    case "CLIENT_LIST_SUCCESS":
      return {
        ...state,
        clientList: action.payload.list,
        hasMore: action.payload.hasMore,
        page: action.payload.page,
        loadingList: false,
      };
    case "CLIENT_LIST_FAILURE":
      return {
        ...state,
        loadingList: false,
      };
    case "CLIENT_LIST_RESET":
      return {
        ...state,
        clientList: [],
        hasMore: true,
        page: 1,
      };
    case "CLIENT_DETAILS_LOADING":
      return {
        ...state,
        loadingDetails: true,
      };
    case "CLIENT_DETAILS_SUCCESS":
      return {
        ...state,
        clientDetails: action.payload,
        loadingDetails: false,
      };
    case "CLIENT_DETAILS_FAILURE":
      return {
        ...state,
        loadingDetails: false,
      };
    case "CLIENT_OPEN_PANEL":
      return {
        ...state,
        panelIsOpen: action.payload,
        clientDetails: action.payload
          ? state.clientDetails
          : initialState.clientDetails,
      };
    case "OPEN_PUSH_PANEL":
      return {
        ...state,
        pushPanelIsOpen: action.payload,
      };
    case "CLIENT_UPDATE_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case "CLIENT_CANCEL_LOADING":
      return {
        ...state,
        loadingCancel: true,
      };
    case "CLIENT_CANCEL_SUCCESS":
      return {
        ...state,
        loadingCancel: false,
        successCancel: true,
      };
    case "CLIENT_CANCEL_FAILURE":
      return {
        ...state,
        loadingCancel: false,
        failureCancel: true,
      };
    case "RESET_CANCEL_ERRORS":
      return {
        ...state,
        failureCancel: false,
        successCancel: false,
      };
    case "CLIENT_UPDATE_LOADING":
      return {
        ...state,
        loadingUpdate: true,
        updateSuccess: false,
        updateError: null,
      };
    case "CLIENT_UPDATE_SUCCESS":
      return {
        ...state,
        loadingUpdate: false,
        updateSuccess: true,
        updateError: null,
      };
    case "CLIENT_UPDATE_FAILURE":
      return {
        ...state,
        loadingUpdate: false,
        updateSuccess: false,
        updateError: action.payload,
      };
    case "GET_EXTRACT_START":
      return {
        ...state,
        loadingExtract: true,
      };
    case "GET_EXTRACT_SUCCESS":
      return {
        ...state,
        loadingExtract: false,
        extract: action.payload,
      };
    case "GET_EXTRACT_FAILURE":
      return {
        ...state,
        loadingExtract: false,
      };
    case "DELETE_USER_START":
      return {
        ...state,
        loadingDelete: true,
      };
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_USER_FAILURE":
      return {
        ...state,
        loadingDelete: false,
        failureDelete: true,
      };
    case "RESET_DELETE_ERRORS":
      return {
        ...state,
        successDelete: false,
        failureDelete: false,
      };
    case "RESET_CLIENT_ERRORS":
      return {
        ...state,
        updateSuccess: false,
      };
    default:
      return state;
  }
};

export default clientReducer;
