import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Panel,
  PanelType,
  ComboBox,
  Stack,
  DefaultButton,
} from "@fluentui/react";
import {
  resetListResults,
  setFilterPainelIsOpen,
  updateFilter,
} from "../../../../core/redux/actions/signalsAction";
import { horizontalGapStackTokens, panelStyles, dropdownStyles } from "./style";

const optionsExchange = [
  { key: "Binance", text: "Binance" },
  { key: "Binance Future", text: "Binance Future" },
];

const optionsTypeOfTrade = [
  { key: 0, text: "Scalp" },
  { key: 1, text: "Day Trade" },
  { key: 2, text: "Swing Trade" },
];

const optionsStatus = [
  { key: 0, text: "Fechado" },
  { key: 1, text: "Ativo" },
  { key: 2, text: "Stand-by" },
];

const optionsPosition = [
  { key: 0, text: "Long" },
  { key: 1, text: "Short" },
];

const optionsTypeOfInput = [
  { key: 0, text: "Mercado" },
  { key: 1, text: "Rompimento" },
  { key: 2, text: "Retração" },
];

export const FilterList = () => {
  const dispatch = useDispatch();
  const { filterIsOpen, filters } = useSelector((store) => store.signal);

  const onRenderFooterContent = () => (
    <DefaultButton onClick={() => dispatch(resetListResults())}>
      Buscar
    </DefaultButton>
  );

  return (
    <Panel
      isOpen={filterIsOpen}
      onDismiss={() => dispatch(setFilterPainelIsOpen(false))}
      headerText='Filtrar sinal'
      closeButtonAriaLabel='Close'
      onRenderFooterContent={onRenderFooterContent}
      type={PanelType.custom}
      customWidth='300px'
      isFooterAtBottom={true}
      styles={panelStyles}
    >
      <Stack tokens={horizontalGapStackTokens}>
        <ComboBox
          label='Exchange'
          allowFreeform
          options={optionsExchange}
          value={filters.coinId}
          onChange={(ev, val) => dispatch(updateFilter("coinId", val.key))}
        />
        <ComboBox
          placeholder='Selecione uma opção...'
          label='Posição'
          options={optionsPosition}
          styles={dropdownStyles}
          value={filters.position}
          onChange={(ev, val) => dispatch(updateFilter("position", val.key))}
        />
        <ComboBox
          placeholder='Selecione uma opção...'
          label='Tipo de Trade'
          options={optionsTypeOfTrade}
          styles={dropdownStyles}
          value={filters.tradeType}
          onChange={(ev, val) => dispatch(updateFilter("tradeType", val.key))}
        />
        <ComboBox
          placeholder='Selecione uma opção...'
          label='Status'
          options={optionsStatus}
          styles={dropdownStyles}
          value={filters.tradeType}
          onChange={(ev, val) => dispatch(updateFilter("statusTrade", val.key))}
        />
      </Stack>

      <Stack tokens={horizontalGapStackTokens}>
        <ComboBox
          placeholder='Selecione uma opção...'
          label='Tipo de entrada'
          options={optionsTypeOfInput}
          styles={dropdownStyles}
          value={filters.entryType}
          onChange={(ev, val) => dispatch(updateFilter("entryType", val.key))}
        />
      </Stack>
    </Panel>
  );
};

export default FilterList;
