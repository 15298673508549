import styled from 'styled-components';
import { Stack, Text, border, borderRadius, container } from '@fluentui/react';

export const MessageItemContainer = styled.div`
  padding: 12px;
  margin-bottom: 6px;
  background-color: white;
  border: 1px solid #d6d4d1;
  border-radius: 2px;
  transition: all 0.5s;
`;

export const ContainerMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  overflow: auto;
  min-width: 450px;
  max-width: 450px;
  width: 100%;
  height: calc(100vh - 355px);

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b0b4b0;
    background-clip: content-box;
    border-left: 12px solid transparent;
  }
`;

export const Card = styled.div`
  border-radius: 8px;
  padding: 12px;
  min-height: 120px;
  background: rgb(232, 121, 0);
  background: white;
  border: 1px solid #DB6714;
  border: none;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 500;
  transition: box-shadow 0.25s ease 0s;
  letter-spacing: 0.08rem;
  z-index: 2;
  position: relative;
`;

export const CardImage = styled.img`
  max-width: 100%;
  margin-top: 10px;
  height: auto;
`;

export const CardDate = styled.div`
  font-size: 0.8rem;
  text-align: right;
  color: #8d898f;
`;

export const CardConten = styled.p`
  font-size: 14px;
`;

export const DeleteIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: -8px;
`;