import React, { useState, useRef } from "react";
import { msalApp } from "../../providers/AuthProvider";
import useOnClickOutside from "../../utils/hooks/useOnClickOutside";
import { useMsal } from "@azure/msal-react";
import Logo from "../../../core/assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  HeaderLogo,
  UserContainer,
  UserIcon,
  UserInfo,
  User,
  InfoIcon,
  InfoContainer,
  InfoHeader,
  LogoutButton,
  Menu,
  MenuAction,
} from "./style";
import { Persona, PersonaInitialsColor, PersonaSize } from "@fluentui/react";
import { signOut } from "firebase/auth";
import { auth } from "../../constants/firebase";
import { handleOpenPushPanel } from "../../redux/actions/clientsAction";

const Header = ({}) => {
  const [open, setOpen] = useState(false);
  const infoRef = useRef(null);
  useOnClickOutside(infoRef, () => setOpen(false));
  const { account } = useSelector((store) => store.auth);
  const { instance } = useMsal();
  const handleOpen = () => setOpen(!open);
  const dispatch = useDispatch();
  const jobTitle = sessionStorage.getItem("jobTitle");

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        window.localStorage.clear();
        msalApp.logoutRedirect({
          account: instance.getAccountByHomeId(account.localAccountId),
          postLogoutRedirectUri: "http://localhost:3000/",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showPushButton = () => {
    if (jobTitle === "Técnico") return true
    if (jobTitle === "Master") return true
    return false
  }

  return (
    <Container>
      <HeaderLogo src={Logo} />
      <>
        <UserContainer>
          <UserIcon onClick={handleOpen}>{account?.name?.charAt(0)}</UserIcon>
        </UserContainer>
        <UserInfo open={open} ref={infoRef}>
          <InfoContainer>
            <InfoHeader src={Logo} />
            <LogoutButton onClick={handleLogout}>Sair</LogoutButton>
            <Persona
              initialsColor={PersonaInitialsColor.orange}
              initialsTextColor='#fff'
              imageInitials={account?.name?.charAt(0)}
              size={PersonaSize.size48}
            />
            <User>{account?.name}</User>
          </InfoContainer>
          {showPushButton() && (
              <Menu>
                <MenuAction onClick={() => dispatch(handleOpenPushPanel(true))}>
                  Enviar push
                </MenuAction>
              </Menu>
            )}
        </UserInfo>
      </>
    </Container>
  );
};

export default Header;
