import moment from "moment";
import axiosPrivate from "../utils/api/privateApi";
import axiosPublic from "../utils/api/publicApi";

export const requestUserList = (page = 1, filter) => {
  let query = `?page=${page}&limit=20`;
  if (filter.Plans !== null && filter.Plans >= 0)
    query += `&Plans=${filter.Plans}`;
  if (filter.CreatedUntil !== null)
    query += `&CreatedUntil=${moment(filter.CreatedUntil).format(
      "YYYY-MM-DDThh:mm:ssZ"
    )}`;
  if (filter.ActiveUntil !== null)
    query += `&ActiveUntil=${moment(filter.ActiveUntil).format(
      "YYYY-MM-DDThh:mm:ssZ"
    )}`;
  if (filter.SubscriptionStatus !== null && filter.SubscriptionStatus >= 0)
    query += `&SubscriptionStatus=${filter.SubscriptionStatus}`;
  if (filter.NameEmailDocument !== null)
    query += `&NameEmailDocument=${filter.NameEmailDocument}`;

  return new Promise((resolve, reject) => {
    axiosPrivate
      .get(`/admin/users${query}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
};

export const requestUserDetails = (id) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .get(`/admin/users/${id}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestUserExtract = (id) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .get(`/site/subscriptions/${id}/extracts`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestToken = (body) =>
  new Promise((resolve, reject) => {
    axiosPublic
      .post("/auth/adm-login", body)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const postRefreshToken = (body) =>
  new Promise((resolve, reject) => {
    axiosPublic
      .post("/auth/refresh-token", body)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const saveDatabase = (body) =>
  new Promise((resolve, reject) => {
    axiosPublic
      .post("/admin/Users", body)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestUpdateUser = (id, body) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .put(`/admin/Users`, body)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestDeleteUser = (id) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .delete(`/admin/Users/${id}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const sendManualPush = (body) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .post(`/admin/Users/notifications`, body)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
