import moment from "moment";

const initialState = {
  //List
  listIsLoading: false,
  listHasError: null,
  hasMore: true,
  walletList: [],
  loadingTypes: false,
  typesError: '',

  //Details
  panelIsOpen: false,
  walletDetails: {
    id: null,
    createdAt: null,
    updatedAt: null,
    lastDateOperation: null,
    name: "",
    description: "",
    items: [
      {
        coinDto: { name: "USDT", id: "A0F2E141-C383-4479-83F2-7CA41CD30D3F" },
        createdAt: moment().locale("pt-br"),
        lastUpdatedAt: moment().locale("pt-br"),
        newItem: true,
        weight: 100,
        remove: false,
        entryValue: 1,
      },
    ],
    walletType: null,
    profit: null,
  },
  walletProfitLoading: false,
  walletDetailsLoading: false,
  walletDetailsError: null,
};

const useReducer = (state = initialState, action) => {
  switch (action.type) {
    case "WALLET_LIST_START":
      return {
        ...state,
        listIsLoading: true,
        listHasError: null,
      };
    case "WALLET_LIST_SUCCESS":
      return {
        ...state,
        listIsLoading: false,
        hasMore: action.payload.hasMore,
        walletList: action.payload.list,
      };
    case "WALLET_LIST_ERROR":
      return {
        ...state,
        listIsLoading: false,
        listHasError: action.payload,
      };
    case "WALLET_OPEN_PANEL":
      return {
        ...state,
        panelIsOpen: action.payload.open,
        detailsLoading: false,
        walletDetails: action.payload.wallet || initialState.walletDetails,
        walletDetailsError: null,
      };
    case "WALLET_DETALIL_REQUEST_LOADING":
      return {
        ...state,
        walletDetailsLoading: true,
        walletDetailsError: null,
      };
    case "WALLET_CREATE_SUCCESS":
      return {
        ...state,
        walletDetailsLoading: false,
        walletDetails: action.payload,
      };
    case "WALLET_UPDATE_SUCCESS":
      return {
        ...state,
        walletDetailsLoading: false,
      };
    case "WALLET_UPDATE_ERROR":
    case "WALLET_CREATE_ERROR":
      return {
        ...state,
        walletDetailsLoading: false,
        walletDetailsError: action.payload,
      };
    case "WALLET_CLOSED_SUCCESS":
      return {
        ...state,
        walletDetailsLoading: false,
        panelIsOpen: false,
        walletDetails: initialState.walletDetails,
        walletDetailsError: null,
      };
    case "WALLET_PROFIT_LOADING":
      return {
        ...state,
        walletProfitLoading: true,
        walletDetailsError: null,
      };
    case "WALLET_PROFIT_DETAILS":
      return {
        ...state,
        walletProfitLoading: false,
        walletDetails: {
          ...state.walletDetails,
          profit: action.payload,
        },
      };
    case "UPDATE_OPTIONS_START":
      return {
        ...state,
        loadingTypes: true,
      };
    case "UPDATE_OPTIONS_SUCCESS":
      return {
        ...state,
        loadingTypes: false,
      };
    case "UPDATE_OPTIONS_FAILURE":
      return {
        ...state,
        loadingTypes: false,
        typesError: action.payload,
      };
    case "WALLET_PROFIT_ERROR":
      return {
        ...state,
        walletProfitLoading: false,
        walletDetailsError: action.payload,
      };
    default:
      return state;
  }
};

export default useReducer;
