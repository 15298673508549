import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
`;

export const ContentContainer = styled.div`
    display: flex;
    width: 100%;
`;