import moment from "moment";
import Coin from "../../entities/coin";
import {
  requestCoinDetails,
  requestCoinList,
  requestCoinListWallet,
} from "../../services/coin";

export const getCoinsList =
  (type = 0, onlyUsdt = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: "COINS_LIST_START" });

      const responseList = await requestCoinList(type);

      if (Array.isArray(responseList)) {
        const coinList = responseList
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => new Coin(item));
        const coins = responseList.map((coin) => ({
          value: coin.symbol,
          label: coin.name,
          name: coin.name,
          type: coin.coinType,
          coinValue: coin.value,
        }));

        if (onlyUsdt) {
          let filteredCoins = coins.filter((coin) => {
            if (coin.name.indexOf("USDT") === -1) return false;
            return true;
          });

          filteredCoins = filteredCoins.map((coin) => ({
            ...coin,
            label: coin.label.replace("USDT", ""),
          }));
          dispatch({
            type: "COINS_LIST_SUCCESS",
            payload: { list: filteredCoins, exchange: type },
          });
          return;
        }

        dispatch({
          type: "COINS_LIST_SUCCESS",
          payload: { list: coins, exchange: type },
        });
      }
    } catch (e) {
      console.error("OCORREU UM ERRO AO CHAMAR A LISTA DE MOEDAS", e);
      dispatch({ type: "COINS_LIST_FAILURE" });
    }
  };

export const getCoinsListWallet =
  (type = 0, onlyUsdt = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: "COINS_LIST_START" });

      const responseList = await requestCoinListWallet(type);

      if (Array.isArray(responseList)) {
        const coins = responseList.map((coin) => ({
          value: coin.id,
          label: coin.name,
          name: coin.name,
          coinValue: coin.id,
          id: coin.id,
        }));

        dispatch({
          type: "COINS_LIST_SUCCESS",
          payload: { list: coins, exchange: type },
        });
      }
    } catch (e) {
      console.error("OCORREU UM ERRO AO CHAMAR A LISTA DE MOEDAS", e);
      dispatch({ type: "COINS_LIST_FAILURE" });
    }
  };

export const getCoinDetails =
  (id, isNewCoin, setWalletCoins, walletCoins, setCoinError) => async (dispatch) => {
    try {
      dispatch({ type: "COIN_DETAILS_START" });

      const response = await requestCoinDetails(id);

      const coin = {
        value: response.value,
        label: response.name,
        name: response.name,
        url: response.url,
        coinValue: response.value,
        id: response.id,
      };

      if (Boolean(response?.id)) {
        let newComposition = walletCoins.map((coin) => {
          if (coin.coinDto?.name === coin.name) {
            isNewCoin = false;
            return {
              coinDto: coin.coinDto,
              lastUpdatedAt: moment().locale("pt-br").subtract(3, "hour"),
              newItem: false,
              id: coin.id,
              weight: parseFloat(coin.weight),
              remove: false,
              entryValue: coin.coinValue,
            };
          }
          return coin;
        });

        if (isNewCoin) {
          newComposition.push({
            coinDto: { name: coin.name, id: coin.id },
            createdAt: moment().locale("pt-br").subtract(3, "hour"),
            lastUpdatedAt: moment().locale("pt-br").subtract(3, "hour"),
            newItem: true,
            weight: 1,
            remove: false,
            entryValue: coin.coinValue,
          });
        }

        setWalletCoins(() => newComposition);
        dispatch({ type: "COIN_DETAILS_SUCCESS", payload: coin });
      } else {
        dispatch({ type: "COIN_DETAILS_FAILURE" });
        setCoinError(true)
      }
      
    } catch (e) {
      console.error("OCORREU UM ERRO AO CHAMAR A LISTA DE MOEDAS", e);
      dispatch({ type: "COIN_DETAILS_FAILURE" });
    }
  };
