import styled from "styled-components";
import { Callout } from "@fluentui/react";

export const horizontalGapStackTokens = {
  childrenGap: 10,
  padding: "10px 0",
};

export const CalloutContainer = styled(Callout)`
  max-width: 300px;
  width: 100%;
  padding: 12px;
`;

export const CalloutTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
`;

export const Input = styled.div`
  margin: 12px 0;
  margin-top: 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

export const UploaderContainer = styled.div`
  margin-top: 2px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    background-color: #fff;
    border: solid 1px #4b4949;
    padding: 8px;
    width: 100%;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
      background-color: #faf5f5;
    }
  }

  span {
    text-align: center;
    font-weight: 500;
    margin-top: 8px;
  }
`;

export const FileUploader = styled.input`
  display: none;
`;