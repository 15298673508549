import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import reactStringReplace from "react-string-replace";

import {
  ReplyContainer,
  MessageContainer,
  Author,
  ReplyHeader,
  CreatedAt,
  Message,
  TextContainer,
  CloseButton,
  Image,
  Link,
  ImageActions,
} from "./style";
import LinkPreview from "../linkPreview";

const closeIcon = { iconName: "ChromeClose" };

export default function ReplyMessage({
  reply,
  closeReply,
  readMessages,
  previewImages,
}) {
  const { account } = useSelector((store) => store.auth);

  const renderName = () => {
    if (reply?.id === account.localAccountId) return "Você";
    return reply?.name?.split(" ").slice(0, 2).join(" ");
  };

  const handleTextRendered = (text) => {
    if (!text) return "";

    let formatted = text;

    if (text.match(/<blockquote/)) {
      formatted = text.split("</blockquote>").pop();
    }

    const removeAllHtml = formatted.replace(/(<([^>]+)>)/gi, "");
    const cleanText = removeAllHtml.replace(/\n/g, "");

    return cleanText;
  };

  const renderLinksAndText = (text) => {
    if (!text) return "";
    let urlArr = [];

    const formattedText = handleTextRendered(text).replace(/\&nbsp;/g, " ");
    const tagsArray = text.match(/<[^>]+>[^<]*<\/[^>]+>/g); // match all html tags
    const regex = /<a.*?href=['"](.*?)['"]/;

    tagsArray?.map((tag) => {
      let obj = { url: regex.exec(tag)?.[1], text: handleTextRendered(tag) };
      urlArr.push(obj);
    });

    let replacedText = formattedText;
    let key = 1;

    if (urlArr.length > 0) {
      urlArr.forEach(
        (link, idx) =>
          (replacedText = reactStringReplace(
            replacedText,
            link.text,
            (match, i) => (
              <Link key={(key += 1)} href={link.url} target='_blank'>
                {match}
              </Link>
            )
          ))
      );
      return replacedText;
    } else {
      // Match URLs
      replacedText = reactStringReplace(
        formattedText,
        /(https?:\/\/\S+)/g,
        (match, i) => (
          <Link key={match + i} href={match}>
            {match}
          </Link>
        )
      );
      return replacedText;
    }
  };

  return (
    <ReplyContainer
      readMessages={readMessages}
      style={readMessages && { marginBottom: 6 }}
    >
      <MessageContainer outgoing={reply?.id === account.localAccountId}>
        <ReplyHeader>
          <Author>{renderName()}</Author>
          <CreatedAt>{moment(reply?.created?.toDate()).calendar()}</CreatedAt>
          {!readMessages && (
            <CloseButton
              iconProps={closeIcon}
              title='Close'
              ariaLabel='Close'
              onClick={() => closeReply()}
            />
          )}
        </ReplyHeader>
        <TextContainer>
          <Message>{renderLinksAndText(reply?.text)}</Message>
          {reply?.linkPreview?.map((preview, idx) => (
            <div key={idx}>
              <LinkPreview received={false} preview={preview} />
            </div>
          ))}
          {reply?.images?.map((img, idx) => (
            <ImageActions key={idx} onClick={() => previewImages(img, true)}>
              <Image src={img} />
            </ImageActions>
          ))}
        </TextContainer>
      </MessageContainer>
    </ReplyContainer>
  );
}
