import React ,{ useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleOpenPushPanel,
} from "../../../core/redux/actions/clientsAction";
import { PanelStyles } from "./style";
import {
  ComboBox,
  Panel,
  PanelType,
  TextField,
  PrimaryButton,
  Stack,
} from "@fluentui/react";

import { sendManualPush } from '../../../core/services/user.js'

export default function ClientDetails() {
  const [selectedPlan, setSelectedPlan] = useState({})
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [externalLink, setExternalLink] = useState('')

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch();
  const {
    pushPanelIsOpen
  } = useSelector((store) => store.clients);

  const optionsPlans = [
    { key: 3, text: "Todos" },
    { key: 0, text: "Plano Free" },
    { key: 1, text: "Plano ICI" },
    { key: 2, text: "Plano TCI" },
    { key: 3, text: "Plano PRO" },
  ];

  async function submitForm() {
    setLoading(true)
    const body = {
      title,
      message,
      plans: selectedPlan,
      externalLink
    }

    try {
      console.log(body)
      await sendManualPush(body);

      dispatch(handleOpenPushPanel(false));
      setSelectedPlan({});
      setTitle('');
      setMessage('');
      setExternalLink('');
    } catch(err) {
      console.log(err)
    } finally {
      setLoading(false)
    }

  } 

  return (
    <>
      <Panel
        isOpen={pushPanelIsOpen}
        onDismiss={() => dispatch(handleOpenPushPanel(false))}
        headerText={"Enviar push notification"}
        closeButtonAriaLabel='Close'
        isFooterAtBottom={true}
        headerClassName={{
          backgroundColor: "#fff",
          padding: "12px 0",
          marginTop: "-10px",
        }}
        type={PanelType.smallFixedFar}
        styles={PanelStyles}
      >
        <ComboBox
          label='Plano'
          options={optionsPlans}
          selectedKey={selectedPlan?.key}
          value={selectedPlan}
          onChange={(ev, val) => setSelectedPlan(val.key)}
        />

        <TextField
          label='Título'
          value={title}
          onChange={(e, val) => setTitle(val)}
        />

        <TextField
          label='Mensagem'
          value={message}
          onChange={(e, val) => setMessage(val)}
        />

        <TextField
          label='Link externo'
          value={externalLink}
          onChange={(e, val) => setExternalLink(val)}
        />

        <Stack horizontalAlign="end">
        <PrimaryButton
          text={loading ? "Enviando..." : "Enviar"}
          onClick={() => submitForm()}
          disabled={loading}
          style={{
            marginTop: 20
          }}
        />
        </Stack>
      </Panel>
    </>
  );
}
