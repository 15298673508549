import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DatePicker,
  FontIcon,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import moment from "moment/moment";

import {
  Container,
  SectionTitle,
  Section,
  Content,
  InputContainer,
  Info,
  ActionButton,
  Message,
  Select,
  ExpirationDate,
  Date,
} from "./style";
import {
  resetError,
  updateClient,
  updateSubscription,
} from "../../../../../../core/redux/actions/clientsAction";
import {
  validateEmail,
  validateName,
} from "../../../../../../core/utils/validations";

const iconProps = { iconName: "Calendar" };

// Plan Names
const planNames = (planName) => {
  switch (planName) {
    case "TCI 1":
      return "Plano TCI - Anual à vista";
    case "TCI 2":
      return "Plano TCI - Anual parcelado";
    case "TCI 3":
      return "Plano TCI - Mensal";
    case "ICI 1":
      return "Plano ICI - Anual à vista";
    case "ICI 2":
      return "Plano ICI - Anual parcelado";
    case "ICI 3":
      return "Plano ICI - Mensal";
    default:
      return planName;
  }
};

const optionsPlans = [
  { key: 0, text: "Plano Free" },
  { key: 1, text: "Plano ICI" },
  { key: 2, text: "Plano TCI" },
  { key: 3, text: "Plano PRO" },
];

export default function ClientInfoEdit() {
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const { clientDetails, loadingUpdate, updateSuccess, updateError } =
    useSelector((store) => store.clients);
  const [showMessage, setShowMessage] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const setClientData = (id, value) => {
    setClient({
      ...client,
      [id]: value,
    });
  };

  useEffect(() => {
    let planValue = 0;

    if (clientDetails?.subscription?.planName?.includes("ICI")) {
      planValue = 1;
    } else if (clientDetails?.subscription?.planName?.includes("TCI")) {
      planValue = 2;
    } else if (clientDetails?.subscription?.planName?.includes("PRO")) {
      planValue = 3;
    } else {
      planValue = 0;
    }

    setClient({
      ...client,
      planValue: planValue,
      name: clientDetails.name,
      email: clientDetails.email,
      expirationDate: clientDetails?.subscription?.expiresAt
    });
  }, [clientDetails]);

  useEffect(() => {
    setSubmitError(updateError);
  }, [updateError]);

  const submit = () => {
    let error = null;

    if (!validateEmail(client?.email)) {
      error = "Email inválido";
    } else if (!validateName(client?.name)) {
      error = "Nome inválido";
    }

    if (error) {
      setSubmitError(error);
      return;
    }

    const clientData = {
      userId: clientDetails.userId,
      name: client?.name,
      newPlan: false,
      expirationDate: moment(client?.expirationDate)?.isValid() ? moment(client?.expirationDate)?.format('YYYY-MM-DDThh:mm:ssZ') : null
    };

    if (
      client?.planValue !== clientDetails.planId &&
      client?.planValue !== -1
    ) {
      clientData.plan = client?.planValue;
      clientData.newPlan = true;
    }
    console.log(clientData)
    dispatch(updateClient(clientData));
  };

  useEffect(() => {
    if (updateSuccess) {
      const timer = setTimeout(() => {
        dispatch(resetError());
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [updateSuccess]);
  console.log(client?.planValue)
  return (
    <Container>
      <Content>
        <SectionTitle>Dados do cliente</SectionTitle>
        <Section>
          <Info>
            <InputContainer>
              <Select
                label='Plano'
                options={optionsPlans}
                selectedKey={client?.planValue}
                onChange={(e, val) => setClientData("planValue", val.key)}
              />
              {/* {client?.planValue > 0 && (
                <ExpirationDate>
                  <FontIcon
                    aria-label='Calendar'
                    iconName='Calendar'
                    style={{ color: "#DB6714", fontSize: "16px" }}
                  />
                  <Date>Expira em: {moment().format("DD/MM/YYYY")}</Date>
                </ExpirationDate>
              )} */}
            </InputContainer>
            <InputContainer>
              <DatePicker
                label='Ativo até'
                placeholder='Selecione uma data...'
                showMonthPickerAsOverlay={true}
                disabled={!Boolean(client?.planValue)}
                value={client?.expirationDate}
                onSelectDate={(val) => setClientData("expirationDate", val)}
                formatDate={(date) => moment(date).format('DD/MM/YYYY')}
              />
            </InputContainer>
            <InputContainer>
              <TextField
                label='Nome'
                value={client?.name}
                onChange={(e, val) => setClientData("name", val)}
              />
            </InputContainer>
            <InputContainer>
              <TextField
                label='Email'
                value={client?.email}
                onChange={(e, val) => setClientData("email", val)}
                readOnly
                disabled
              />
            </InputContainer>
            {/* senha é gerenciada pelo firebase */}
            {/* <InputContainer>
              <TextField
                label='Senha'
                value={client?.newPassword}
                onChange={(e, val) => setClientData("newPassword", val)}
              />
            </InputContainer> */}
          </Info>
          <ActionButton>
            <PrimaryButton
              text={loadingUpdate ? "Atualizando..." : "Atualizar"}
              onClick={() => submit()}
              disabled={loadingUpdate}
            />
          </ActionButton>
        </Section>
        {(submitError || updateSuccess) && (
          <Message>
            <MessageBar
              messageBarType={
                submitError ? MessageBarType.error : MessageBarType.success
              }
            >
              {submitError ? submitError : "Dados atualizados com sucesso!"}
            </MessageBar>
          </Message>
        )}
      </Content>
    </Container>
  );
}
