import { DefaultButton, Stack } from "@fluentui/react";
import moment from "moment";
import { formatPricePrecision } from "../../core/utils/formatPrice";

// Plan Names
const planNames = (planName) => {
  switch (planName) {
    case "TCI 1":
      return "TCI - Anual à vista";
    case "TCI 2":
      return "TCI - Anual parcelado";
    case "TCI 3":
      return "TCI - Mensal";
    case "ICI 1":
      return "ICI - Anual à vista";
    case "ICI 2":
      return "ICI - Anual parcelado";
    case "ICI 3":
      return "ICI - Mensal";
    default:
      return planName;
  }
};

// Plan Names
const planTypes = (item) => {
  if (item?.value > 0) return 'Valor fixo';
  return 'Porcentagem';
};

// Plan Names
const planStatus = (active) => {
  if (active) return 'Ativo';
  if (!active) return 'Desativado';
  return '';
};

// Plan Names
const renderDiscount = (item) => {
  if (item?.value > 0) return formatPricePrecision(item?.value);
  if (item?.percentage) return `${item?.percentage}%`;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (onEdit) => [
  {
    key: "column0",
    name: "Nome",
    fieldName: "name",
    minWidth: 190,
    maxWidth: 190,
    isResizable: true,
    styles: "styleColumn",
  },
  {
    key: "column1",
    name: "Status",
    fieldName: "active",
    minWidth: 150,
    maxWidth: 240,
    isResizable: false,
    styles: "styleColumn",
    onRender: (item) => planStatus(item?.active),
  },
  {
    key: "column2",
    name: "Desconto",
    fieldName: "percentage",
    minWidth: 160,
    maxWidth: 240,
    styles: "styleColumn",
    isResizable: true,
    onRender: (item) => renderDiscount(item),
  },
  {
    key: "column3",
    name: "Plano",
    fieldName: "planName",
    minWidth: 200,
    maxWidth: 250,
    styles: "styleColumn",
    isResizable: true,
    onRender: (item) => planNames(item?.planName),
  },
  {
    key: "column4",
    name: "Tipo",
    fieldName: "type",
    minWidth: 140,
    maxWidth: 250,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => planTypes(item),
  },
    {
    key: "column5",
    name: "Válido até",
    fieldName: "validUntil",
    minWidth: 140,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) =>
      moment(item.validUntil).locale("pt-br").format("DD/MM/YYYY"),
  },
  {
    key: "column6",
    name: "Ações",
    fieldName: "",
    minWidth: 110,
    maxWidth: 110,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => (
      <Stack horizontal>
        <DefaultButton text='Editar' onClick={() => onEdit(item.couponId)} />
      </Stack>
    ),
  },
];
