import { requestUserAd } from "../../services/auth";
import { requestToken } from "../../services/user";

export function setUserAuthData(token, name, email) {
  if (token) {
    return {
      type: "SET_USER_AUTH_DATA",
      payload: {
        isAuthenticated: true,
        token,
        email,
        name,
      },
    };
  } else {
    return {
      type: "SET_USER_AUTH_DATA",
      payload: {
        isAuthenticated: false,
        token: null,
        email: null,
        name: null,
      },
    };
  }
}

/**
 * Set user account object from b2c auth
 */
export const setAccount = (data) => ({
  type: "SET_USER_ACCOUNT",
  payload: data,
});

/**
 * request tokens for api auth
 */
export const requestTokensAuth = (body) => async (dispatch) => {
  try {
    dispatch({ type: "GET_TOKENS_START" });

    const result = await requestToken(body);

    localStorage.setItem("authCredentialsAdmin", JSON.stringify(result));

    dispatch({ type: "GET_TOKENS_SUCCESS", payload: result });
  } catch (e) {
    dispatch({ type: "GET_TOKENS_FAILURE" });
  }
};

// Get user ad info
export const getUserAdInfo = (id, accessToken) => async (dispatch, store) => {
  try {
    dispatch({ type: "GET_AD_INFO_START" });

    const data = await requestUserAd(id, accessToken);

    dispatch({ type: "GET_AD_INFO_SUCCESS", payload: data });
  } catch (e) {
    dispatch({
      type: "GET_AD_INFO_FAILURE",
    });
    console.log(e);
  }
};
