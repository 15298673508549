import axios from "axios";
import { memoizedRefreshToken } from "./refreshToken";
import { getApiUrl } from "../../constants/api";

export const axiosPrivate = axios.create({
  baseURL: getApiUrl(),
  // baseURL: "https://localhost:7074/api",
});

let authTokenRequest;

// This function makes a call to get the auth token
// or it returns the same promise as an in-progress call to get the auth token
function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = memoizedRefreshToken();
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }

  return authTokenRequest;
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

axiosPrivate.interceptors.request.use(
  async (config) => {
    const authCredentialsAdmin = JSON.parse(localStorage.getItem("authCredentialsAdmin"));

    if (authCredentialsAdmin?.accessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${authCredentialsAdmin?.accessToken}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 403 && !config?.__isRetryRequest) {
      window.localStorage.clear();
    }

    if (error?.response?.status === 401 && !config?.__isRetryRequest) {
      return getAuthToken().then((response) => {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${response?.accessToken}`,
        };
        config.__isRetryRequest = true;
        return axios(error.config);
     });
    }
    return Promise.reject(error);
  }
);

export default axiosPrivate;
