import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletList,
  handleOpenPanel,
  resetError,
} from "../../../../core/redux/actions/walletActions";
import CommonScrollList from "../../../../core/container/commomScrollList";

import listColumns from "./coluns";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { NothingFound } from "./style";

const ListWallet = () => {
  const dispatch = useDispatch();
  const { listIsLoading, listHasError, panelIsOpen, hasMore, walletList } =
    useSelector((store) => store.wallet);

  useEffect(() => {
    dispatch(getWalletList());
  }, []);

  useEffect(() => {
    if (listHasError) {
      const timer = setTimeout(() => {
        dispatch(resetError());
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [listHasError]);

  const handleOpenEditPanel = (wallet) => {
    dispatch(handleOpenPanel(!panelIsOpen, wallet));
  };

  return (
    <>
      {listHasError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          onDismiss={() => null}
        >
          {listHasError}
        </MessageBar>
      )}
      {walletList?.[0] === "" ? (
        <NothingFound>
          <MessageBar
            messageBarType={MessageBarType.info}
            onDismiss={() => null}
          >
            Nenhum portfólio encontrado.
          </MessageBar>
        </NothingFound>
      ) : (
        <CommonScrollList
          idList='walletList'
          loading={listIsLoading}
          enableShimmer={true}
          columns={listColumns(handleOpenEditPanel)}
          list={walletList}
          getMore={() => null}
        />
      )}
    </>
  );
};

export default ListWallet;
