import * as React from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Stack } from '@fluentui/react';
import { DefaultButton, PrimaryButtonProps } from '@fluentui/react/lib/Button';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';

const SignalEndNotification = ({ text, finishSignal, disabled }) => {
  const buttonId = useId('targetButton');
  const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);

  const SecondaryButtonProps = React.useMemo(
    () => ({
      children: 'Não',
      onClick: toggleTeachingBubbleVisible,
    }),
    [toggleTeachingBubbleVisible],
  );

  return (
    <Stack>
      <DefaultButton
        disabled={disabled}
        id={buttonId}
        onClick={toggleTeachingBubbleVisible}
        text={text}
      />

      {teachingBubbleVisible && (
        <TeachingBubble
        target={`#${buttonId}`}
        primaryButtonProps={{
          children: 'Sim',
          onClick: () => {
            finishSignal();
            toggleTeachingBubbleVisible();
          },
        }}
        secondaryButtonProps={SecondaryButtonProps}
        onDismiss={toggleTeachingBubbleVisible}
        headline="Tem certeza que deseja encerrar sinal?"
        >
        </TeachingBubble>
      )}
    </Stack>
  );
};


export default  SignalEndNotification;