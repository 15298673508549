import moment from "moment";
import { cupomApi } from "../constants/api";
import axiosPrivate from "../utils/api/privateApi";

export const requestCupomsList = (filter) => {
  let query = '';

  if (filter.name) query += `&Name=${filter.name}`;
  if (filter.percentage) query += `&Percentage=${filter.percentage}`;
  if (filter.startDate) query += `&Start=${moment(filter.startDate).format('YYYY-MM-DDThh:mm:ssZ')}`;
  if (filter.endDate) query += `&Final=${moment(filter.endDate).format('YYYY-MM-DDThh:mm:ssZ')}`;
  if (filter.active !== null && filter.active !== undefined) query += `&Active=${filter.active}`;

  return new Promise((resolve, reject) => {
    axiosPrivate
      .get(`${cupomApi}${query?.replace('&', '?')}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const requestUpdateCupom = (cupom) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .put(`${cupomApi}/${cupom.couponId}`, cupom)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const requestCreateCupom = (cupom) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .post(`${cupomApi}`, cupom)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}
