import React, { useRef, useState } from "react";
import {
  MessageBar,
  MessageBarType,
  SelectionMode,
  ShimmeredDetailsList,
} from "@fluentui/react";
import { ListContainer } from "./style";

const InfinityScrollList = ({
  columns,
  list,
  loading,
  getMore,
  onItemInvoked,
  idList,
}) => {
  const scrollView = useRef();
  const [lastScrollPos, setLastScrollPos] = useState(0);

  const checkToLoadMore = (e) => {
    const list = document.getElementsByClassName(idList)[0];

    if (list) {
      const content = list.getElementsByClassName(
        "ms-DetailsList-contentWrapper"
      )[0];
      const lasElmtPos = content?.getBoundingClientRect().bottom;
      const windHeight = window.innerHeight;

      if (
        lastScrollPos !== lasElmtPos &&
        lasElmtPos <= windHeight + windHeight / 10
      ) {
        getMore();
        setLastScrollPos(lasElmtPos);
      }
    }
  };

  return (
    <ListContainer onScroll={(e) => checkToLoadMore(e)}>
      <ShimmeredDetailsList
        items={list}
        getKey={(item) => item.id || item.key}
        setKey='none'
        className={idList}
        columns={columns}
        selectionMode={SelectionMode.none}
        selectionPreservedOnEmptyClick={false}
        onItemInvoked={onItemInvoked}
      />
      <div ref={scrollView} />

      {!loading && list.length === 0 && (
        <MessageBar messageBarType={MessageBarType.info}>
          Nenhum item foi encontrado
        </MessageBar>
      )}
    </ListContainer>
  );
};

export default InfinityScrollList;
