const initialState = {
  // chat
  messages: null,
  loadingChat: false,
  chatError: false,
  sendError: null,
  loadingSending: false,

  // groups
  loadingCreate: false,
  createError: null,
  loadingGroups: false,
  errorGroups: null,
  loadingUpdate: false,
  groupInfo: null,
  groups: [],
};

const communityReducer = (state = initialState, action) => {
  switch (action.type) {
    // messages
    case "GET_MESSAGES_START":
      return {
        ...state,
        loadingChat: true,
      };
    case "GET_MESSAGES_SUCCESS":
      return {
        ...state,
        messages: action.payload,
        loadingChat: false,
      };
    case "GET_MESSAGES_FAILURE":
      return {
        ...state,
        loadingChat: false,
        chatError: action.payload,
      };
    case "SEND_MESSAGE_START":
      return {
        ...state,
        loadingSending: true,
      };
    case "SEND_MESSAGE_SUCCESS":
      return {
        ...state,
        loadingSending: false,
      };
    case "SEND_MESSAGE_FAILURE":
      return {
        ...state,
        sendError: action.payload,
        loadingSending: false,
      };
    // groups
    case "CREATE_GROUP_START":
      return {
        ...state,
        loadingCreate: true,
      };
    case "CREATE_GROUP_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_GROUP_FAILURE":
      return {
        ...state,
        loadingCreate: false,
        createError: action.payload,
      };
    case "GET_GROUPS_START":
      return {
        ...state,
        loadingGroups: true,
      };
    case "GET_GROUPS_SUCCESS":
      return {
        ...state,
        groups: action.payload,
        loadingGroups: false,
      };
    case "GET_GROUPS_FAILURE":
      return {
        ...state,
        loadingGroups: false,
        errorGroups: action.payload,
      };
    case "UPDATE_GROUP_START":
      return {
        ...state,
        loadingUpdate: true,
      };
    case "UPDATE_GROUP_SUCCESS":
      return {
        ...state,
        loadingUpdate: false,
      };
    case "UPDATE_GROUP_FAILURE":
      return {
        ...state,
        loadingUpdate: false,
      };
    case "DELETE_MESSAGE_START":
      return {
        ...state,
        loadingUpdate: true,
      };
    case "DELETE_MESSAGE_SUCCESS":
      return {
        ...state,
        loadingUpdate: false,
      };
    case "DELETE_MESSAGE_FAILURE":
      return {
        ...state,
        loadingUpdate: false,
      };
    case "BAN_USER_START":
      return {
        ...state,
        loadingUpdate: true,
      };
    case "BAN_USER_SUCCESS":
      return {
        ...state,
        loadingUpdate: false,
      };
    case "BAN_USER_FAILURE":
      return {
        ...state,
        loadingUpdate: false,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        createError: action.payload,
      };
    default:
      return state;
  }
};

export default communityReducer;
