class Signal {
  constructor(params) {
    this.id = params.id;
    this.date = params.date;
    this.input = params.input;
    this.activatedAt = params.activatedAt;
    this.inputMax = params.inputMax;
    this.stopLoss = params.stopLoss;
    this.dateStop = params.dateStop || params.dateClosed;
    this.note = params.note;
    this.graphic = params.graphic;
    this.exchange = params.exchange;
    this.tradeType = params.tradeType;
    this.entryType = params.entryType;
    this.targets= params.targets;
    this.coinParity = params.targets;
    this.signalType = params.signalType;
    this.publishFreeAccount = params.publishFreeAccount;
    this.position = params.position;
    this.leverage = params.leverage;
    this.symbol = params.symbol;
    this.statusTrade = params.statusTrade;
  }

  addMoreTargets() {
    //....
  }
}

export default Signal;