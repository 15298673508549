import styled from "styled-components";

export const NothingFound = styled.div`
  margin-top: 20px;
`;

export const CoinsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CoinImage = styled.img`
  width: 22px;
  height: 22px;
  border-radius: 60px;
`;

export const ImageContainer = styled.div`
  border-radius: 60px;
  padding: 2px;
  background-color: #f7f1f1;
  display: flex;
  align-items: center;
  height: 25px;
  width: 25px;
  margin-left: -1px;
`;

export const NumberOfCoins = styled.div`
  align-items: center;
  height: 23px;
  width: 23px;
  border: solid 1px #DB6714;
  border-radius: 60px;
  justify-content: center;

  span {
    font-weight: bold;
    position: relative;
    left: 2px;
    font-size: 12px;
    color: #DB6714;
  }
`;