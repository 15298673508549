import { Callout, IconButton, TextField } from "@fluentui/react";
import { Textarea } from "@fluentui/react-components";
import styled from "styled-components";

export const Avatar = styled.div`
  background-color: #db6714;
  width: 36px;
  height: 36px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
`;

export const MessageContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  flex: 1 1 0;
  position: relative;

  ::-webkit-scrollbar {
    width: 20px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b0b4b0;
    background-clip: content-box;
    border-left: 12px solid transparent;
  }
`;

export const Messages = styled.div`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  padding: 12px 0 22px 0;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
`;

export const SendMessageContainer = styled.div`
  max-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid 1px #f0eded;
  padding: 12px 12px;
  padding-bottom: 6px;
  flex-direction: column;
  overflow: hidden;
`;

export const MessageInput = styled(Textarea)`
  width: 100%;
  border-radius: 3px;
  padding-right: 20px;
`;

export const SendMessage = styled(IconButton)`
  transition: 0.1s ease-in;

  i {
    font-size: 22px;
  }

  &:hover {
    background: #f5f1f1;
  }
`;

export const TextArea = styled.div`
  max-width: 974px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const Message = styled.div`
  background: ${({ outgoing }) => (outgoing ? `#d5e2ff87` : "#faf6f6")};
  padding: 8px 14px;
  border-radius: 6px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ outgoing }) => (outgoing ? `#d5e2ff1f` : "#faf1f1")};
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    .more-options {
      opacity: 1;
    }
  }
`;

export const NoMessages = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;

  span {
    margin-top: 50px;
    font-size: 20px;
    font-weight: 500;
  }

  p {
    font-size: 15px;
    color: #383535;
  }
`;

export const Text = styled.span`
  white-space: pre-wrap;
`;

export const CreatedAt = styled.span`
  font-size: 12px;
  color: #383838;
  padding-bottom: 4px;
`;

export const MessageHeader = styled.div`
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  max-height: 26px;
`;

export const IncomingName = styled.span`
  margin-right: 14px;
  font-weight: 600;
  font-size: 13px;

  .ban {
    font-size: 12px;
    color: red;
  }

  .admin {
    color: green;
  }
`;

export const MessageContent = styled.div`
  align-self: ${({ outgoing }) => (outgoing ? `flex-end` : "flex-start")};
  max-width: 65%;
  display: flex;
  gap: 12px;

  .ms-Persona-initials {
    span {
      font-size: 16px;
    }
  }
`;

export const MessageAlign = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: ${({ outgoing }) => (outgoing ? `flex-end` : "flex-start")};
  width: 100%;
`;

export const Hashtag = styled.span`
  font-size: 18px;
  color: #504b4b;
  position: relative;
  top: 2px;
  margin-right: 6px;
`;

export const MessageArea = styled.div`
  width: 100%;
  min-height: 46px;
  max-height: 200px;
  border: solid 1px #d6cfcf;
  border-radius: 1.5px;
  margin-top: 12px;
  display: block;
  background: #faf8f8;
  padding: 12px 0;
  overflow: auto;

  a {
    color: #7373f3;
  }

  ::-webkit-scrollbar {
    width: 20px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b0b4b0;
    background-clip: content-box;
    border-left: 12px solid transparent;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
`;

export const LinkIcon = styled.div``;

export const MessageTitle = styled.span`
  font-size: 14px;
  color: #322130;
  font-weight: 600;
  justify-self: flex-start;
`;

export const ChatActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ImageContainer = styled.div``;

export const ChatImage = styled.img`
  width: 100%;
  margin: 8px 0;
  height: 300px;
`;

export const Link = styled.a`
  color: #1b3aec;
  word-break: break-all;
`;

export const ImageActions = styled.div`
  cursor: pointer;
`;

export const Options = styled.div`
  align-self: center;
  justify-self: flex-end;
  padding-left: 12px;
  position: relative;
  bottom: 4px;
  right: -8px;
  opacity: 0;
  transition: 0.1s ease;

  i {
    font-size: 20px;
    color: black;
    font-weight: bold;
  }
`;

export const OptionsCallout = styled(Callout)`
  max-width: 160px;
  width: 100%;
  padding: 8px 6px;
`;

export const Option = styled.button`
  background-color: transparent;
  padding: 8px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 3px;
  border: none;
  transition: 0.1s ease;

  i {
    font-size: 16px;
    margin-right: 8px;
  }

  &:hover {
    background-color: #f3ecec;
  }
`;

export const OptionsContainer = styled.div``;

export const LoadingInfo = styled.div`
  background-color: #1f2937;
  position: absolute;
  align-self: center;
  top: 6px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;

  span {
    color: #fff;
    font-weight: 500;
  }
`;

export const DeletedMessage = styled.em`
  margin-top: 4px;
`;

export const UndoDeleted = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  span {
    color: #265cf0;
    font-weight: 600;
  }
`;