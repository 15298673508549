import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../../../../../../core/redux/actions/communityActions";
import LinkCallout from "../../../../../wallet/containers/sidePanel/components/linkCallout";
import FileUpload from "../fileUpload";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { isValidUrl } from "../../../../../../core/utils/validations";
import { IconButton, TooltipHost } from "@fluentui/react";
import { storage } from "../../../../../../core/constants/firebase";
import { ChatImageModal } from "../imageModal";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ReplyMessage from "../replyMessage";
import reactStringReplace from "react-string-replace";
import axios from "axios";

import {
  SendMessageContainer,
  SendButton,
  TextArea,
  TextBox,
  LinkContainer,
  LinkIcon,
  ChatActions,
  PreviewContainer,
  PreviewIcon,
  Previews,
  DeleteIcon,
  CustomSpinner,
} from "./style";

const sendMsg = { iconName: "Send" };
const linkIcon = { iconName: "AddLink" };
const fileImage = { iconName: "FileImage" };
const closeIcon = { iconName: "ChromeClose" };

export default function SendMessage({
  chat,
  openImage,
  imageModal,
  hideImage,
  previewImages,
  openReply,
  replyMessage,
  closeReply,
  scrollToBottom,
}) {
  const { account } = useSelector((store) => store.auth);
  const [newMessage, setNewMessage] = useState("");
  const [linkCallout, { toggle: toggleLinkCallout }] = useBoolean(false);
  const [fileCallout, { toggle: toggleFileCallout }] = useBoolean(false);
  const [images, setImages] = useState([]);
  const linkReference = useId("link-button");
  const fileReference = useId("file-button");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [validLinks, setValidLinks] = useState({});
  const [link, setLink] = useState({});
  const dispatch = useDispatch();
  const emptyImgLink = Object.keys(link).length === 0 || link?.href === "";
  const [links, setLinks] = useState(null);

  const canUpload = () => {
    if (!emptyImgLink && file) return false;

    if (emptyImgLink && !file) return false;

    return true;
  };

  const checkCanSend = () => {
    if (!newMessage && images.length === 0) return false;

    return true;
  };


  const handleTextRendered = (text) => {
    if (!text) return "";

    let formatted = text;

    if (text.match(/<blockquote/)) {
      formatted = text.split("</blockquote>").pop();
    }

    const removeAllHtml = formatted.replace(/(<([^>]+)>)/gi, "");

    return removeAllHtml;
  };
  
  const handleMessage = (text) => {
    const removedDivs = text?.replaceAll("<div>", "\n");
    const removeCloseDivs = removedDivs?.replaceAll("</div>", "");
    setNewMessage(handleTextRendered(removeCloseDivs?.replaceAll("<br>", "\n")));
  };

  const handleLink = (id, value) => {
    setLink({
      ...link,
      [id]: value,
    });
  };

  const createLink = () => {
    const textarea = document.getElementById("message");

    let linkTag = document.createElement("A");
    linkTag.href = link.href;
    linkTag.textContent = link.text;
    linkTag.setAttribute("target", "_blank");
    linkTag.onclick = function () {
      window.open(link.href, "_blank").focus();
    };

    if (link.text && !isValidUrl(linkTag.href)) {
      setValidLinks({
        ...validLinks,
        href: "Endereço inválido",
      });

      return;
    }

    if (!link.text) {
      setValidLinks({
        ...validLinks,
        text: "Informe o texto",
      });

      return;
    }

    textarea.appendChild(linkTag);
    handleMessage(textarea?.innerHTML?.replaceAll("</div>", ""));
    toggleLinkCallout();
  };

  const clearLinkErrors = (id) => {
    setValidLinks({
      ...validLinks,
      [id]: false,
    });
  };

  const closeLinkCallout = () => {
    setLink({});
    toggleLinkCallout();
  };

  const handleUpload = async () => {
    const promises = [];
    const fileImages = images.filter((file) => !file.href);

    fileImages?.slice(0, 5).forEach((file) => {
      const storageRef = ref(storage, `chat/images/${file.name}`);
      promises.push(
        uploadBytesResumable(storageRef, file).then((uploadResult) => {
          return getDownloadURL(uploadResult.ref);
        })
      );
    });

    return await Promise.all(promises);
  };

  const handleImages = () => {
    if (!canUpload()) return;

    if (!emptyImgLink) {
      setImages([...images, link]);
    } else {
      setImages([...images, file]);
    }

    toggleFileCallout();
    setLink({});
    setFile(null);
  };

  const deleteImages = (id) => {
    setImages(images.filter((i, idx) => idx !== id));
  };

  const handleCloseFile = () => {
    if (fileCallout) {
      setFile(null);
      setLink({});
    }

    toggleFileCallout();
  };

  const fetchLink = async (url) => {
    return axios
      .get(url)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return { success: false };
      });
  };

  const requestAllLinks = (urls) => {
    return Promise.all(urls.map(fetchLink));
  };

  const handleSendMessage = async () => {
    let linkPreview;
    if (links?.length > 0) {
      setLoading(true);
      linkPreview = await requestAllLinks(links);
      setLoading(false);
      setLinks(null);
    }

    if (images.length > 0) {
      try {
        setLoading(true);
        const linkImages = images.filter((file) => file.href);
        const linkHrefs = linkImages
          ?.slice(0, 5)
          .map((file) => file.href && file.href);
        const uploadedImages = await handleUpload();
        dispatch(
          sendMessage(
            chat,
            newMessage,
            account,
            replyMessage,
            [...linkHrefs, ...uploadedImages],
            scrollToBottom,
            linkPreview
          )
        );
        setImages([]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert(error);
      }
    } else {
      if (checkCanSend()) {
        dispatch(
          sendMessage(
            chat,
            newMessage,
            account,
            replyMessage,
            [],
            scrollToBottom,
            linkPreview
          )
        );
      }
    }

    document.getElementById("message").innerHTML = "";
    setNewMessage("");
    closeReply();
  };

  // Render all links from user messages
  const getAllLinks = () => {
    const text = newMessage;
     /** @FIX - Secure this values if link api upgrade plan is needed */
    const tempkey = process.env.REACT_APP_CRYPTO_ENVIROMENT === 'production' ? 'cec92a7bfaa2687430c0b56255999c85' : '7e82a15d563164f3618a5794f967783b';
    if (!text) return "";
    let urlArr = [];
    let linksArr = [];

    const formattedText = handleTextRendered(text).replace(/\&nbsp;/g, " ");
    const tagsArray = text.match(/<[^>]+>[^<]*<\/[^>]+>/g); // match all html tags
    const regex = /<a.*?href=['"](.*?)['"]/;

    tagsArray?.map((tag) => {
      let obj = { url: regex.exec(tag)?.[1], text: handleTextRendered(tag) };
      urlArr.push(obj);
    });

    let replacedText = formattedText;
    let key = 1;

    // Populate array for links preview
    if (urlArr.length > 0) {
      urlArr.forEach((link) =>
        reactStringReplace(replacedText, link.text, (match) =>
          linksArr.push(
            `https://api.linkpreview.net/?key=${tempkey}&q=${match}`
          )
        )
      );
    } else {
      // Match URLs
      reactStringReplace(formattedText, /(https?:\/\/\S+)/g, (match, i) =>
        linksArr.push(
          `https://api.linkpreview.net/?key=${tempkey}&q=${match}`
        )
      );
    }

    setLinks(linksArr);
  };

  useEffect(() => {
    getAllLinks();
  }, [newMessage]);

  const handleKeypress = (e) => {
    if (e.keyCode == 13) {
      //      if (e.shiftKey === true)
      if (e.shiftKey) {
        // thruthy
        return;
      } else {
        e.preventDefault();
        handleSendMessage();
      }
      return false;
    }
  };

  return (
    <SendMessageContainer>
      <ChatImageModal
        isModalOpen={openImage}
        hideModal={hideImage}
        image={imageModal}
      />
      <TextArea>
        <Previews style={{ marginBottom: 8 }}>
          {images.length > 0 &&
            images?.slice(0, 5).map((img, idx) => (
              <div key={idx} style={{ position: "relative" }}>
                <PreviewContainer onClick={() => previewImages(img)}>
                  <PreviewIcon aria-label='Image' iconName='FileImage' />
                  <span>{img?.name || img?.href}</span>
                </PreviewContainer>
                <DeleteIcon>
                  <IconButton
                    title='Fechar'
                    iconProps={closeIcon}
                    ariaLabel='Fechar'
                    onClick={() => deleteImages(idx)}
                  />
                </DeleteIcon>
              </div>
            ))}
        </Previews>
        {openReply && (
          <Previews>
            <ReplyMessage reply={replyMessage} closeReply={closeReply} />
          </Previews>
        )}
        <TextBox
          contentEditable={chat.name !== ""}
          id='message'
          onInput={(e) => handleMessage(e.target.innerHTML)}
          onKeyDown={(e) => handleKeypress(e)}
          contenteditable='plaintext-only'
          suppressContentEditableWarning={true}
        />
        <ChatActions>
          <LinkContainer>
            <LinkCallout
              buttonId={linkReference}
              isCalloutVisible={linkCallout}
              validLinks={validLinks}
              clearLinkErrors={clearLinkErrors}
              toggleIsCalloutVisible={toggleLinkCallout}
              handleLink={handleLink}
              closeLinkCallout={closeLinkCallout}
              createLink={createLink}
            />
            <FileUpload
              buttonId={fileReference}
              isCalloutVisible={fileCallout}
              validLinks={validLinks}
              file={file}
              setFile={setFile}
              clearLinkErrors={clearLinkErrors}
              toggleIsCalloutVisible={handleCloseFile}
              handleLink={handleLink}
              uploadFile={handleImages}
            />
            <LinkIcon>
              <IconButton
                id={linkReference}
                iconProps={linkIcon}
                title='Adicionar Link'
                ariaLabel='Adicionar Link'
                disabled={!chat?.name || loading}
                style={{ backgroundColor: "transparent" }}
                onClick={() => toggleLinkCallout()}
                styles={{
                  icon: { color: "#000", fontSize: 18 },
                }}
              />
            </LinkIcon>
            <LinkIcon>
              <IconButton
                id={fileReference}
                iconProps={fileImage}
                title='Adicionar imagem'
                ariaLabel='Adicionar imagem'
                disabled={!chat?.name || loading}
                style={{ backgroundColor: "transparent" }}
                onClick={() => toggleFileCallout()}
                styles={{
                  icon: { color: "#000", fontSize: 18 },
                }}
              />
            </LinkIcon>
          </LinkContainer>
          <TooltipHost content='Enviar'>
            {loading ? (
              <CustomSpinner />
            ) : (
              <SendButton
                iconProps={sendMsg}
                onClick={() => handleSendMessage()}
                disabled={!chat?.name || loading}
              />
            )}
          </TooltipHost>
        </ChatActions>
      </TextArea>
    </SendMessageContainer>
  );
}
