import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessageBar, MessageBarType } from "@fluentui/react";

import {
  getCupomsList,
  getCupomById,
  setPainelIsOpen,
} from "../../core/redux/actions/cupomsActions";

import HeaderFilterList from "./components/headerFilterList";
import CumpomPanel from "./components/cupomPanel";
import listColumns from "./listColumns";
import { Container } from "./style";
import CommonScrollList from "../../core/container/commomScrollList";

const CupomsView = () => {
  const dispatch = useDispatch();
  const {
    list,
    listLoading,
    listError,
    openDetailPanel,
  } = useSelector((store) => store.cupom);

  useEffect(() => {
    if (!openDetailPanel) {
      dispatch(getCupomsList());
    }
  }, [openDetailPanel]);

  const handleOpenEditPanel = (id) => {
    dispatch(setPainelIsOpen(true));
    if (id) dispatch(getCupomById(id));
  };

  return (
    <Container>
      {listError && (
        <MessageBar messageBarType={MessageBarType.error} onDismiss={() => null}>
          OCORREU UM ERRO AO CHAMAR A LISTA DE CUPONS
        </MessageBar>
      )}
      <HeaderFilterList />
      <CommonScrollList
        idList="signalsList"
        loading={listLoading}
        enableShimmer={true}
        columns={listColumns(handleOpenEditPanel)}
        list={list}
        getMore={() => null}
      />
      <CumpomPanel />
    </Container>
  );
};

export default CupomsView;
