import React from 'react';
import ReactLoading from 'react-loading';

import { Container } from './style';

const LoadingPage = () => (
  <Container>
    <ReactLoading type="spin" color="#DB6714" />
  </Container>
);

export default LoadingPage;
