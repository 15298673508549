import axios from "axios";

export const requestUserAd = (id, accessToken) =>
  new Promise((resolve, reject) => {
    const apiConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(`https://graph.microsoft.com/v1.0/users/${id}`, apiConfig)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
