import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useId } from "@fluentui/react-hooks";
import {
  FontIcon,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  TeachingBubble,
  TextField,
} from "@fluentui/react";
import moment from "moment/moment";

import {
  Container,
  SectionTitle,
  Section,
  Content,
  InputContainer,
  Info,
  ActionButtons,
  Message,
  Select,
  SubStatus,
  Date,
  Status,
} from "./style";
import {
  cancelSignature,
  resetCancelErrors,
  updateClient,
} from "../../../../../../core/redux/actions/clientsAction";

const iconProps = { iconName: "Calendar" };

const optionsPlans = [
  { key: -1, text: "Todos" },
  { key: 0, text: "Plano Free" },
  { key: 1, text: "Plano ICI" },
  { key: 2, text: "Plano TCI" },
  { key: 3, text: "Plano PRO" },
];

export default function PaymentInfo() {
  const dispatch = useDispatch();
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [teachingBubbleVisible, setTeachingBubbleVisible] = useState(false);
  const [showMessage, setShowMessage] = useState(null);
  const { clientDetails, loadingCancel, successCancel, failureCancel } =
    useSelector((store) => store.clients);
  const buttonId = useId("targetCancelSubscriptionButton");
  const documentType = 0;
  const subscriptionStatus = 1;

  const SecondaryButtonProps = React.useMemo(
    () => ({
      children: "Não",
      onClick: () => setTeachingBubbleVisible(false),
    }),
    [teachingBubbleVisible]
  );

  const setClientData = (id, value) => {
    setPaymentInfo({
      ...paymentInfo,
      [id]: value,
    });
  };

  useEffect(() => {
    setPaymentInfo({
      // cpf: "673.480.970-65",
      phone: clientDetails?.phone,
      cep: clientDetails?.zipCode,
      street: clientDetails?.address,
      number: clientDetails?.number,
      city: clientDetails?.city,
      state: clientDetails?.state,
    });
  }, [clientDetails]);

  useEffect(() => {
    if (successCancel) {
      const timer = setTimeout(() => {
        dispatch(resetCancelErrors());
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [successCancel]);

  useEffect(() => {
    if (failureCancel) {
      const timer = setTimeout(() => {
        dispatch(resetCancelErrors());
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [failureCancel]);

  return (
    <Container>
      <Content>
        <SectionTitle>Dados do último pagamento</SectionTitle>
        <Section>
          <Info>
            {/* {documentType === 0 && (
              <InputContainer>
                <TextField
                  label='CPF'
                  value={paymentInfo?.cpf}
                  onChange={(e, val) => setClientData("cpf", val)}
                  readOnly
                />
              </InputContainer>
            )}
            {documentType === 1 && (
              <InputContainer>
                <TextField
                  label='Passaporte'
                  value={paymentInfo?.passport}
                  onChange={(e, val) => setClientData("passport", val)}
                  readOnly
                />
              </InputContainer>
            )} */}
            <InputContainer>
              <TextField
                label='Celular'
                value={paymentInfo?.phone}
                onChange={(e, val) => setClientData("phone", val)}
                readOnly
              />
            </InputContainer>
            <InputContainer>
              <TextField
                label={paymentInfo?.country !== "Brazil" ? "Zip Code" : "CEP"}
                value={paymentInfo?.cep}
                onChange={(e, val) => setClientData("zipCode", val)}
                readOnly
              />
            </InputContainer>
            <InputContainer>
              <TextField
                label='Endereço'
                value={paymentInfo?.street}
                onChange={(e, val) => setClientData("street", val)}
                readOnly
              />
            </InputContainer>
            <InputContainer>
              <TextField
                label='Número'
                value={paymentInfo?.number}
                onChange={(e, val) => setClientData("number", val)}
                readOnly
              />
            </InputContainer>
            <InputContainer>
              <TextField
                label='Cidade'
                value={paymentInfo?.city}
                onChange={(e, val) => setClientData("city", val)}
                readOnly
              />
            </InputContainer>
            <InputContainer>
              <TextField
                label='Estado'
                value={paymentInfo?.state}
                onChange={(e, val) => setClientData("state", val)}
                readOnly
              />
            </InputContainer>
            {/* <InputContainer>
              <TextField
                label='País'
                value={paymentInfo?.country}
                onChange={(e, val) => setClientData("country", val)}
                readOnly
              />
            </InputContainer> */}
          </Info>
          <ActionButtons>
            {clientDetails?.status === 1 ||
              (true && (
                <>
                  <PrimaryButton
                    text={
                      loadingCancel ? "Atualizando..." : "Cancelar assinatura"
                    }
                    id={buttonId}
                    disabled={loadingCancel}
                    style={{
                      backgroundColor: "#f13a3a",
                      borderColor: "#f13a3a",
                    }}
                    onClick={() => setTeachingBubbleVisible(true)}
                  />
                  {/* <PrimaryButton text='Forçar recorrência' /> */}
                </>
              ))}
            {/* <PrimaryButton text='Atualizar' onClick={() => dispatch(updateClient(clientDetails))} /> */}
          </ActionButtons>
          <SubStatus>
            <Status>
              Situação de recorrência:{" "}
              <span style={{ fontWeight: 400 }}>{clientDetails?.subscription?.statusDescription}</span>
            </Status>
          </SubStatus>
        </Section>
        {(successCancel || failureCancel) && (
          <Message>
            <MessageBar
              messageBarType={
                failureCancel ? MessageBarType.error : MessageBarType.success
              }
            >
              {failureCancel
                ? "Ocorreu um erro ao cancelar assinatura."
                : "Assinatura cancelada com sucesso!"}
            </MessageBar>
          </Message>
        )}
      </Content>
      {teachingBubbleVisible && (
        <TeachingBubble
          target={`#${buttonId}`}
          primaryButtonProps={{
            children: "Sim",
            onClick: () => {
              setTeachingBubbleVisible(false);
              dispatch(
                cancelSignature(clientDetails?.subscription?.subscriptionId)
              );
            },
          }}
          secondaryButtonProps={SecondaryButtonProps}
          onDismiss={setTeachingBubbleVisible}
          headline='Tem certeza que deseja cancelar esta assinatura?'
        />
      )}
    </Container>
  );
}
