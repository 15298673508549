import { FontIcon, IconButton } from "@fluentui/react";
import styled from "styled-components";

export const Container = styled.div`
  width: 300px;
  height: 100%;
`;

export const Header = styled.div`
  border-bottom: solid 1px #e0d6d6;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const HeaderTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

export const GroupsContainer = styled.div`
  padding: 3px 6px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-height: calc(100vh - 108px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 20px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cecece;
    background-clip: content-box;
    border-left: 12px solid transparent;
  }
`;

export const Group = styled.div`
  border: none;
  cursor: pointer;
  width: 100%;
  min-height: 45px;
  background-color: transparent;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 3px;
  transition: 0.1s ease-in;
  background-color: ${({ selected }) => (selected ? "#eb7420" : `transparent`)};

  &:hover {
    background: ${({ selected }) => (selected ? "#f17d2a" : `#f5f1f1`)};

    .actions {
      opacity: 1;
    }
  }
`;

export const GrupoName = styled.h1`
  font-size: 16px;
  word-break: break-all;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;
  color: ${({ selected }) => (selected ? "#fff" : `#1a1919`)};
`;

export const CreateButton = styled(IconButton)`
  transition: 0.1s ease-in;
  &:hover {
    background: #f5f1f1;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 4px;
  opacity: 0;
  transition: 0.1s ease-in;

  i {
    font-size: 14px;
    color: ${({ selected }) => (selected ? "#fff" : `#000`)};
  }
`;

export const GroupAction = styled(IconButton)`
  transition: 0.1s ease-in;
  width: 35px;
  padding: 0 4px;
  &:hover {
    background: ${({ selected }) => (selected ? "#f78738" : `#fcf9f9`)};
  }
`;

export const GroupsTitle = styled.span`
  color: #535050;
  font-size: 13px;
  padding: 12px 12px 2px 12px;
`;

export const GroupIcon = styled(FontIcon)`
  font-size: 17px;
  margin-right: 12px;
  position: relative;
  top: 2px;
  color: ${({ selected }) => (selected ? "#fff" : `#333030`)};
`;

export const UnreadDot = styled.div`
  padding: 5px;
  background-color: red;
  border-radius: 60px;
  position: relative;
  top: 2px;
`;
