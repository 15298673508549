import React from 'react';
import { Stack } from '@fluentui/react';
import {
  horizontalGapStackTokens,
  AlignItem,
  DatePickerInput,
  Container,
  InitialFilters,
  CreateSignalButton,
  ButtonsAlign,
  MoreFilterBtn,
} from './style';
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSignalsById,
  resetListResults,
  setFilterPainelIsOpen,
  setPainelIsOpen,
  updateFilter,
} from '../../../../core/redux/actions/signalsAction';
import FilterPanel from '../filterPanel';

const HeaderFilterList = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((store) => store.signal);

  return (
    <Container>
      <InitialFilters>
        <DatePickerInput
          label='Data de início'
          placeholder='Selecione uma data...'
          value={filters.beginDate}
          showMonthPickerAsOverlay={true}
          onSelectDate={(val) => dispatch(updateFilter('beginDate', val))}
          formatDate={(date) => moment(date).format('DD/MM/YYYY')}
        />
        <DatePickerInput
          label='Data final'
          placeholder='Selecione uma data...'
          value={filters.endDate}
          showMonthPickerAsOverlay={true}
          onSelectDate={(val) => dispatch(updateFilter('endDate', val))}
          formatDate={(date) => moment(date).format('DD/MM/YYYY')}
        />

        <ButtonsAlign>
          <MoreFilterBtn>
            <DefaultButton
              text='Mais Filtros'
              onClick={() => dispatch(setFilterPainelIsOpen(true))}
              iconProps={{ iconName: 'Filter' }}
            />
          </MoreFilterBtn>
        </ButtonsAlign>

        <ButtonsAlign>
          <DefaultButton onClick={() => dispatch(resetListResults())} iconProps={{ iconName: 'Search' }}>
            Buscar
          </DefaultButton>
        </ButtonsAlign>

        <CreateSignalButton>
          <ButtonsAlign>
            <DefaultButton
              text='Criar Sinal'
              onClick={() => {
                dispatch(setPainelIsOpen(true));
                dispatch(getSignalsById(null));
              }}
              iconProps={{ iconName: 'Add' }}
            />
          </ButtonsAlign>
        </CreateSignalButton>
      </InitialFilters>
     
      <FilterPanel />
    </Container>
  );
};

export default HeaderFilterList;
