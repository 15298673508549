import { collection, limit, orderBy, query } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../../constants/firebase";

const useUnreadMessages = (chatName) => {
  const [message, setMessage] = useState(null);
  const [messagesCollection, loadingMessages, error] = useCollection(
    chatName && 
      query(
        collection(db, "chat", chatName, "messages"),
        orderBy("created", "desc"),
        limit(1)
      )
  );

  useEffect(() => {
    const newMessages =
      messagesCollection?.docs.map((doc) => ({
        ...doc.data(),
        key: doc.id,
      }))[0] || null;

    setMessage(newMessages);
  }, [messagesCollection]);

  return {
    message,
    loadingMessages,
    error,
  };
};

export default useUnreadMessages;
