import * as React from 'react';
import moment from 'moment/moment';
import { IconButton } from '@fluentui/react';
import {
  ContainerMessage,
  MessageItemContainer,
  Card,
  CardDate,
  CardConten,
  CardImage,
  DeleteIconContainer,
} from './style';

const deleteIcon = { iconName: 'Delete' };
const now = moment();

export const HistoryPanel = ({
  messages,
  removeInitialMessage,
  signalId,
  detailPanel,
}) => (
  <ContainerMessage>
    {messages?.map((itemMessage, idx) => (
      <MessageItemContainer key={idx}>
        <Card>
          {!signalId && !detailPanel && (
            <DeleteIconContainer>
              <IconButton
                title='Remover'
                iconProps={deleteIcon}
                ariaLabel='Remover'
                style={{ backgroundColor: 'transparent' }}
                onClick={() => removeInitialMessage([])}
              />
            </DeleteIconContainer>
          )}
          <CardConten>{itemMessage.note}</CardConten>
          {itemMessage.graphic && <CardImage src={itemMessage.graphic} />}
          <CardDate>
            {moment(itemMessage.date)
              .locale('pt-br')
              // .subtract(3, 'hour')
              .format('DD/MM/YYYY, HH:mm:ss')}
          </CardDate>
        </Card>
      </MessageItemContainer>
    ))}
  </ContainerMessage>
);

export default HistoryPanel;
