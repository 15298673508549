import User from '../../entities/user'

const initialState = {
  //List
  userList: [],
  userListLoading: false,
  
  //Details
  user: null,
};

const useReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LIST_LOADING':
      return {
        ...state,
        userListLoading: action.payload,
      }
    case "USER_LIST":
      return{
        ...state,
        userList: action.payload,
      }
    case "USER_DETAILS":
      return {
        ...state,
        user: action.payload,
      }
    default:
      return state;
  }
};

export default useReducer;
