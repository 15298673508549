import React from 'react';
import { Stack } from '@fluentui/react';
import Header from '../Header';
import { horizontalGapStackTokens } from './style';


const headerStackStyles = (p, theme) => ({
  root: {
    backgroundColor:" #DB6714",
    padding: '0 12px',
    boxShadow: theme.effects.elevation16,
  },
});

const NavBar = () => {
  return (
    <Stack horizontal verticalAlign="center" grow={0} styles={headerStackStyles}>
      <Header/>
    </Stack>
  );
}

export default NavBar;

