import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../../core/ui/buttonFluent";
import { useBoolean, useId } from "@fluentui/react-hooks";
import {
  deleteGroup,
  updateGroup,
} from "../../../../../../core/redux/actions/communityActions";
import { DialogContent, Check, Error, CloseIcon, CheckContainer } from "./style";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  TeachingBubble,
  TextField,
} from "@fluentui/react";

const close = { iconName: "ChromeClose" };

const dialogContentProps = {
  type: DialogType.largeHeader,
  title: "Editar grupo",
};

export default function EditGroup({ hideDialog, toggleHideDialog, group }) {
  const { loadingUpdate } = useSelector((store) => store.community);
  const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
  const [groupName, setGroupName] = useState("");
  const buttonId = useId("targetButton");
  const [onlyAdmin, setOnlyAdmin] = useState(false);
  const [subRestriction, setSubRestriction] = useState(false);
  const dispatch = useDispatch();

  const handleUpdate = async () => {
    dispatch(updateGroup(groupName, onlyAdmin, toggleHideDialog, subRestriction));
  };

  useEffect(() => {
    setGroupName(group?.name);
    setOnlyAdmin(group?.onlyAdmin);
    setSubRestriction(group?.subRestriction);
  }, [group]);

  const SecondaryButtonProps = React.useMemo(
    () => ({
      children: "Não",
      onClick: toggleTeachingBubbleVisible,
    }),
    [toggleTeachingBubbleVisible]
  );

  return (
    <Dialog
      hidden={hideDialog}
      onDismiss={toggleHideDialog}
      dialogContentProps={dialogContentProps}
    >
      <DialogContent>
        <CloseIcon iconProps={close} onClick={() => toggleHideDialog()} />
        <TextField
          placeholder='Escreve o nome do grupo...'
          label='Nome do grupo'
          value={groupName}
          readOnly
        />
        <CheckContainer>
          <Check
            label='Somente administradores'
            checked={onlyAdmin}
            onChange={(ev, val) => setOnlyAdmin(val)}
          />
          <Check
            label='Restrição dos 7 dias'
            checked={subRestriction}
            onChange={(ev, val) => setSubRestriction(val)}
          />
        </CheckContainer>
      </DialogContent>
      <DialogFooter>
        <Button
          onClick={() => handleUpdate()}
          text='Salvar'
          loading={loadingUpdate}
        />
        <DefaultButton
          id={buttonId}
          disabled={loadingUpdate}
          onClick={toggleTeachingBubbleVisible}
          text='Excluir Grupo'
        />
        {teachingBubbleVisible && (
          <TeachingBubble
            target={`#${buttonId}`}
            primaryButtonProps={{
              children: "Sim",
              onClick: () => {
                toggleTeachingBubbleVisible();
                dispatch(deleteGroup(groupName, toggleHideDialog));
              },
            }}
            secondaryButtonProps={SecondaryButtonProps}
            onDismiss={toggleTeachingBubbleVisible}
            headline='Tem certeza que deseja excluir esse grupo?'
          />
        )}
      </DialogFooter>
    </Dialog>
  );
}
