import Signal from '../../entities/signal';

const initialState = {
    // List
    signalsList: [],
    signalsListLoading: false,
    signalListError: "",
    hasMore: true,
    page: 1,
    limit: 50,

    // List filters
    filterIsOpen: false,
    filters: {
      coinId: null,
      beginDate: null,
      endDate: null,
      signalType: null,
      position: null,
      tradeType: null,
      entryType: null,
      statusTrade: null, 
    },

    // Details
    openPanel: false,
    signal: null,
    loadingSignal: false,
    openPopup: false,
    signalError: null,
    noteLoading: false,
    finishSignal: false,
    signalId: '',
    // Trade Type percentiles
    percentileLoading: false,
    stopLoss: 0,
    openDetailPanel: false,
    entryRange: 0,
    showMensage: false,
    targets: [0],
};


const signalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGNAL_LIST_LOADING":
      return {
        ...state,
        signalsListLoading: action.payload,
      }
      case "SIGNAL_LIST_ERROR":
        return {
          ...state,
          signalListError: action.payload,
        }
    case "SIGNAL_LIST":
      return {
        ...state,
        signalsList: action.payload.list,
        hasMore: action.payload.hasMore,
        page: action.payload.page,
      }
    case "SIGNAL_LIST_RESET":
      return {
        ...state,
        signalsList: [],
        hasMore: true,
        page: 1,
        filterIsOpen: false,
      }
    case "SIGNAL_UPDATE_FILTER": 
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        }
      }
    
    case "SIGNAL_PANEL_OPEN":
      return {
        ...state,
        openPanel: action.payload,
        signalError: action.payload === false ? null : state.signalError,
        signal: action.payload === false ? null : state.signalError,
        signalId: action.payload === false ? '' : state.signalId,
        showMensage: action.payload === false ? false : state.showMensage,

      }
      case "SIGNAL_DETAILPANEL_OPEN":
        return {
          ...state,
        openDetailPanel: action.payload,
      }
    case "SIGNAL_POPUP_OPEN":
      return{
        ...state,
        openPopup: action.payload,
      }
    case "SIGNAL_FILTER_PANEL_OPEN":
      return {
        ...state,
        filterIsOpen: action.payload,
      }
    case "SIGNAL_TRADE_PERCENTILE_LOADING":
      return {
        ...state,
        percentileLoading: action.payload,
      }
    case "SIGNAL_TRADE_PERCENTILE":
      return {
        ...state,
        ...action.payload,
      };
    
    case "SIGNAL_DETAIL_LOADING": 
      return {
        ...state,
        loadingSignal: action.payload,
        signalError: action.payload === true ? null : state.signalError,
      };

    case "SET_SIGNAL_ID": 
      return {
        ...state,
        signalId: action.payload,
        showMensage: true,
      };
    
    case "SIGNAL_DETAIL":
      return {
        ...state,
        signal: action.payload,
      };
    
    case "SIGNAL_DETAIL_ERROR":
      return {
        ...state,
        signalError: action.payload,
        showMensage: true,
      }

    case "SIGNAL_FILTER":
      return {
        ...state,
        signalFilter: action.payload,
      }
    case "SIGNAL_LIST_ID":
      return {
        ...state,
        signalListId: action.payload,
      }

    // Notes
    case "SIGNAL_NOTE_LOADING":
      return {
        ...state,
        noteLoading: action.payload,
      }
    case "SIGNAL_NOTE":
      return {
        ...state,
        signal: {
          ...state.signal,
          instructions: action.payload,
        }
      }
      case "SIGNAL_FINISH":
        return {
          ...state,
          finishSignal: action.payload,
          showMensage: true,
        }
    default:
      return state;
  };
};

export default signalReducer;
