import { db } from "../constants/firebase";
import axiosPrivate from "../utils/api/privateApi";

export const getMessages = (collection) =>
  new Promise((resolve, reject) => {
    db.collection(collection)
      .get()
      .then((r) => console.log(r))
      .catch((e) => reject(e));
  });

  /**
 * Trigger chat push notification
 */
  export const sendMessagePushNotification = (groupName) =>
  new Promise((resolve, reject) => {
    axiosPrivate
    .post(`/admin/users/notifications/chat/${groupName}`)
    .then(({ data }) => resolve(data))
    .catch((err) => reject(err));
});