import React from "react";
import {
  Container,
  LinkImage,
  LinkImageWeb,
  ImageContainer,
  LinkContent,
  LinkDescription,
  Title,
  Description,
  Link,
  HiddenContent,
} from "./style";

const LinkPreview = ({ received, preview }) => {
  function removeHttp(urlString) {
    let url = urlString;

    if (url?.slice(-1) === "/") url = urlString?.slice(0, -1);

    if (url?.startsWith("https://")) {
      const https = "https://";
      return url.slice(https.length);
    }

    if (url?.startsWith("http://")) {
      const http = "http://";
      return url?.slice(http.length);
    }

    return url;
  }

  const routeChange = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {preview?.success !== false && (
        <Container
          isReceived={received}
          onClick={() => routeChange(preview?.url)}
          style={{ height: Boolean(preview?.description) ? 370 : 310 }}
        >
          <ImageContainer>
            <LinkImageWeb isReceived={received} src={preview?.image} />
            <HiddenContent>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at
              aliquam urna. Mauris pulvinar, est a cursus mollis, diam mi
              ultricies metus, eu laoreet felis metus sed nisl. Cras efficitur
              lacus non purus tincidunt, nec commodo eros rhoncus.
            </HiddenContent>
          </ImageContainer>
          <LinkContent
            style={{ height: Boolean(preview?.description) ? 370 : 310 }}
          >
            <LinkDescription>
              <Title>{preview?.title}</Title>
              <Description>{preview?.description}</Description>
            </LinkDescription>
            <Link>{removeHttp(preview?.url)}</Link>
          </LinkContent>
        </Container>
      )}
    </>
  );
};

export default LinkPreview;
