import axiosPrivate from "../utils/api/privateApi";
import {
  walletApi,
  walletCloseApi,
  walletUpdateApi,
  walletProfitApi,
} from "../constants/api";

export const requestWalletList = () =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .get(`${walletApi}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestNewWallet = (wallet) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .post(`${walletApi}`, wallet)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestUpdateWallet = (wallet) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .post(`${walletUpdateApi}`, wallet)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestCloseWallet = (id) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .put(`${walletCloseApi}/${id}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestWalletDetail = (id) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .get(`/admin/Wallet/${id}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestUpdateWalletDetails = (data, id) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .put(`${walletApi}/${id}`, data)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestWalletProfit = (id) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .get(`${walletProfitApi}/${id}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
