import React from 'react';
import { SpinnerSize } from '@fluentui/react';
import { CustomButton, ButtonText, CustomSpinner, CheckIcon } from './style';

const Button = ({
  loading,
  text,
  icon,
  isAdded,
  disabled,
  onClick,
  styles,
}) => {
  return (
    <CustomButton style={styles} disabled={loading || disabled} iconProps={icon} onClick={() => onClick()}>
      {loading && (
        <CustomSpinner size={SpinnerSize.small} />
      )}
      {isAdded && (
        <CheckIcon iconName="CheckMark" />
      )}
      <ButtonText isDisabled={loading || disabled}>{text}</ButtonText>
    </CustomButton>
  );
};

export default Button;
