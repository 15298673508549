import styled from "styled-components";
import { PrimaryButton, Stack } from '@fluentui/react';

export const INavLinkGroup= styled(Stack)`
    display: ${({ hiddenPagination }) => (hiddenPagination ? 'none' : 'flex')};
    border: 2px solid black;
    background-color: #fff;
    border-radius: 8px;
`;


export const navLinkGroups = styled.link`
  align-self: flex-end;
  border-radius: 8px;

`;


export const horizontalGapStackTokens = {
  childrenGap: 10,
  padding: "10px 0",
};

// export const stackStyles = styled(Stack)`
 
// `;