import {
  requestCloseWallet,
  requestNewWallet,
  requestUpdateWallet,
  requestUpdateWalletDetails,
  requestWalletDetail,
  requestWalletList,
  requestWalletProfit,
} from "../../services/wallet";

export const getWalletList =
  (update = false) =>
  async (dispatch, store) => {
    const { hasMore, listIsLoading, page, filters, walletList } =
      store().wallet;

    if (!update && !hasMore) return;
    if (listIsLoading) return;
    dispatch({ type: "WALLET_LIST_START" });

    try {
      const walletRequest = await requestWalletList(page, filters);
      dispatch({
        type: "WALLET_LIST_SUCCESS",
        payload: {
          list: update ? walletRequest : walletList.concat(walletRequest),
          page: page + 1,
          hasMore: false,
        },
      });
    } catch (error) {
      dispatch({ type: "WALLET_LIST_ERROR", payload: "Error to get list" });
    }
  };

export const resetError = () => ({
  type: "WALLET_LIST_ERROR",
  payload: "",
});

export const resetErrorUpdates = () => ({
  type: "WALLET_UPDATE_ERROR",
  payload: "",
});

export const handleOpenPanel = (open, wallet) => ({
  type: "WALLET_OPEN_PANEL",
  payload: { open, wallet },
});

export const handleDetails = (wallet) => ({
  type: "SET_WALLET_DETAILS",
  payload: { wallet },
});

export const postNewWallet =
  (wallet, setSuccessMessage, setWalletCoins) => async (dispatch, store) => {
    dispatch({ type: "WALLET_DETALIL_REQUEST_LOADING" });

    try {
      const result = await requestNewWallet(wallet);
      const details = await requestWalletDetail(result.id);
      dispatch({ type: "WALLET_CREATE_SUCCESS", payload: result });
      setWalletCoins(details.items.filter((item) => item.weight !== 0));
      dispatch(getWalletList(true));
      setSuccessMessage(true);
    } catch (e) {
      dispatch({ type: "WALLET_CREATE_ERROR", payload: e?.errors?.toString() });
    }
  };

export const putWallet =
  (wallet, setSuccessMessage, setWalletCoins, id) =>
  async (dispatch, store) => {
    dispatch({ type: "WALLET_DETALIL_REQUEST_LOADING" });

    try {
      const res = await requestUpdateWallet(wallet);
      const details = await requestWalletDetail(res.id || id);
      setWalletCoins(details.items.filter((item) => item.weight !== 0));
      dispatch({ type: "WALLET_UPDATE_SUCCESS" });
      setSuccessMessage(true);
      dispatch(getWalletList(true));
    } catch (e) {
      dispatch({
        type: "WALLET_UPDATE_ERROR",
        payload: "Ocorreu um erro na transação da carteira",
      });
    }
  };

export const closeWallet = () => async (dispatch, store) => {
  const { walletDetails } = store().wallet;
  if (!walletDetails?.id) return;

  dispatch({ type: "WALLET_DETALIL_REQUEST_LOADING" });

  try {
    await requestCloseWallet(walletDetails?.id);
    dispatch({ type: "WALLET_CLOSED_SUCCESS" });
    dispatch(getWalletList(true));
  } catch (e) {
    dispatch({
      type: "WALLET_UPDATE_ERROR",
      payload: "Ocorreu um erro no fechamendo da carteira",
    });
  }
};

export const updateWalletName =
  (name, description, setSuccessMessage) => async (dispatch, store) => {
    const { walletDetails } = store().wallet;
    if (!walletDetails?.id) return;

    dispatch({ type: "WALLET_DETALIL_REQUEST_LOADING" });
    try {
      await requestUpdateWalletDetails(
        { name, description },
        walletDetails?.id
      );
      dispatch({ type: "WALLET_UPDATE_SUCCESS" });
      dispatch(getWalletList(true));
      setSuccessMessage(true);
    } catch (e) {
      dispatch({
        type: "WALLET_UPDATE_ERROR",
        payload: "Ocorreu um ao atualizar o nome da carteira",
      });
    }
  };

export const updateWalletTypes = (frequency, walletType, name, description, setSuccessMessage, setSaveType) => async (dispatch, store) => {
    const { walletDetails } = store().wallet;
    if (!walletDetails?.id) return;

    dispatch({ type: "UPDATE_OPTIONS_START" });
    try {
      await requestUpdateWalletDetails({ frequency, walletType, name, description }, walletDetails?.id);
      dispatch({ type: "UPDATE_OPTIONS_SUCCESS" });
      dispatch(getWalletList(true));
      setSuccessMessage(true);
      setSaveType(false);
    } catch (e) {
      dispatch({
        type: "UPDATE_OPTIONS_FAILURE",
        payload: "Ocorreu um erro ao atualizar a carteira",
      });
    }
  };

export const getProfitData = () => async (dispatch, store) => {
  const { walletDetails } = store().wallet;
  if (!walletDetails?.id) return;

  dispatch({ type: "WALLET_PROFIT_LOADING" });
  try {
    const response = await requestWalletProfit(walletDetails?.id);
    dispatch({ type: "WALLET_PROFIT_DETAILS", payload: response });
  } catch (e) {
    dispatch({ type: "WALLET_PROFIT_ERROR", payload: e?.errors.toString() });
  }
};
