import styled from 'styled-components';
import { Stack } from '@fluentui/react';


export const fluentOption  = styled(Stack)` 
  width: 100%;
  margin: 0 auto;
`;

export const Container = styled.div`
  width: calc(100% - 177px);
  display: flex;
  flex-direction: column;
  padding: 18px;
  background: #fff;
  border: solid 1px rgb(243, 242, 241);
  box-shadow: -2px 2px 10px 4px rgba(235, 228, 228, 0.2);
  opacity: 1;
  transition: opacity 0.1s ease;
  z-index: 2;
`;

export const EntryType = styled.div`
  display: flex;
  align-items: center;
`;

export const PositionImage = styled.img`
  margin-left: 12px;
`;

export const horizontalGapStackTokens = {
  childrenGap: 10,
  padding: "10px 0",
};

export const ISignalsViewProps = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
`;

export const DetailsList = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: -10px;
`;

