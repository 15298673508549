import { IconButton, Stack, TextField, DatePicker, defaultDatePickerStrings } from '@fluentui/react';
import moment from 'moment';
import React from 'react';

export default function WalletComposition({ walletComposition, onChangeWallet }) {
  const handleName = (name) => {
    if (typeof name !== 'string') return '';
    if (name === 'USDT') return name;
    return name.replace('USDT', '');
  }

  return (
    <Stack gap="12" style={{ margin: "12px 0" }}>
      {walletComposition
        ?.filter((walltetItem) => walltetItem.remove === false)
        .map((walletItem, idx) => (
          <Stack horizontal gap="12" verticalAlign="end" key={idx}>
            <Stack.Item grow>
              <TextField
                label={idx === 0 && "Moeda"}
                placeholder="Moeda"
                value={handleName(walletItem.coinDto?.name)}
                readOnly
              />
            </Stack.Item>
            <Stack.Item grow>
              <TextField
                type="number"
                value={walletItem.entryValue}
                placeholder="Valor Moeda"
                label={idx === 0 && "Valor de entrada"}
                inputProps={{ lang: "en-US" }}
                onChange={(ev, val) =>
                  onChangeWallet({
                    ...walletItem,
                    entryValue: val,
                  })
                }
              />
            </Stack.Item>

            <Stack.Item style={{ width: "250px", position: 'relative', top: 5 }}>
              <DatePicker
                label={idx === 0 && "Data"}
                placeholder="Selecione uma data"
                ariaLabel="Selecione uma data"
                value={moment(walletItem?.createdAt).toDate()}
                strings={defaultDatePickerStrings}
                onSelectDate={(val) =>
                  onChangeWallet({ ...walletItem, createdAt: moment(val).format('YYYY-MM-DDThh:mm:ssZ') })
                }
                formatDate={(date) => moment(date).format("DD/MM/YYYY")}
              />
            </Stack.Item>

            <Stack.Item style={{ width: "120px" }}>
              <TextField
                class="Peso"
                label={idx === 0 && "Peso"}
                value={walletItem.weight}
                placeholder="Peso"
                type="number"
                min={0}
                prefix="%"
                onChange={(ev, val) =>
                  onChangeWallet({ ...walletItem, weight: val })
                }
              />
            </Stack.Item>

            <Stack>
              <IconButton
                iconProps={{ iconName: "Delete" }}
                title="Remover"
                ariaLabel="Remover"
                style={{ backgroundColor: "transparent" }}
                onClick={() =>
                  onChangeWallet({
                    ...walletItem,
                    currentWeight: 0,
                    weight: 0,
                    remove: true,
                  })
                }
                styles={{
                  icon: { color: "red" },
                }}
              />
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};