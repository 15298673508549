import { Stack } from "@fluentui/react";
import React, { useState } from "react";
import { createFilter } from "react-select";
import CoinSelectBox from "../../../../../../core/container/searchCoin/coinSelectBox";
import Button from "../../../../../../core/ui/buttonFluent";

export default function AddCoin({
  coinList,
  coinsListLoading,
  onAddCoin,
  loading,
  disabled
}) {
  const [coinValue, setCoinValue] = useState({ coinParity: "" });
  
  return (
    <Stack horizontal gap='12'>
      <Stack.Item style={{ width: "100%" }}>
        <CoinSelectBox
          disabled={disabled || coinsListLoading}
          loading={coinsListLoading}
          value={coinValue?.coinParity}
          filterOption={createFilter({ ignoreAccents: false })}
          placeholder='Adicionar moeda'
          onChange={setCoinValue}
          options={coinList}
        />
      </Stack.Item>
      <Button
        text='Adicionar moeda'
        onClick={() => onAddCoin(coinValue)}
        loading={loading}
        styles={{ padding: "8px", height: "38px", minWidth: "150px" }}
        disabled={disabled}
      />
    </Stack>
  );
}
