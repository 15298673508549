import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 18px;
  padding-top: 0;
  background: #fff;
  padding-left: 0;
  padding-right: 0;
  border: solid 1px rgb(243, 242, 241);
  box-shadow: -2px 2px 10px 2px rgba(116, 112, 112, 0.123);
  opacity: 1;
  transition: opacity 0.1s ease;
  z-index: 2;
  min-height: 97vh;
`;