import axiosPrivate from "../utils/api/privateApi";

export const requestDeleteSignarue = (id) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .delete(`/site/subscriptions/${id}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestPutSubscription = (id, subscription) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .put(`/site/subscriptions/${id}`, subscription)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });