import styled from "styled-components";
import { Callout } from "@fluentui/react";

export const horizontalGapStackTokens = {
  childrenGap: 10,
  padding: "10px 0",
};

export const CalloutContainer = styled(Callout)`
  max-width: 300px;
  width: 100%;
  padding: 12px;
`;

export const CalloutTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
`;

export const Input = styled.div`
  margin: 12px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;