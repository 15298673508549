import React, { useState } from "react";
import { PrimaryButton, Stack, TextField } from "@fluentui/react";

export default function WalletData({
  edit,
  name,
  onChangeName,
  about,
  onChangeAbout,
  onUpdate,
  loading,
}) {
  const [showButton, setShowButton] = useState(false);

  return (
    <Stack gap="10">
      <TextField
        label="Nome da carteira"
        value={name}
        onChange={(e, val) => { onChangeName(val); setShowButton(true);}}
      />
      <TextField
        placeholder="Sobre esta carteira..."
        multiline
        rows={5}
        value={about}
        onChange={(e, val) => { onChangeAbout(val); setShowButton(true);}} 
      />

      {edit && showButton && (
        <PrimaryButton onClick={() => onUpdate()} text={loading ? "Aguarde..." : "Salvar"} disabled={loading} />
      )}
    </Stack>
  );
}
