import {
  requestDeleteSignarue,
  requestPutSubscription,
} from "../../services/signature";
import {
  requestUserList,
  requestUserDetails,
  requestUpdateUser,
  requestUserExtract,
  requestDeleteUser,
} from "../../services/user";

export const handleOpenPanel = (open) => ({
  type: "CLIENT_OPEN_PANEL",
  payload: open,
});

export const handleOpenPushPanel = (open) => ({
  type: "OPEN_PUSH_PANEL",
  payload: open,
});

export const resetCancelErrors = () => ({
  type: "RESET_CANCEL_ERRORS",
});

export const resetDeleteErrors = () => ({
  type: "RESET_DELETE_ERRORS",
});

export const getClientsList = () => async (dispatch, store) => {
  if (!store().clients.hasMore) return;
  if (store().clients.loadingList) return;

  dispatch({ type: "CLIENT_LIST_LOADING" });

  try {
    const clientList = await requestUserList(
      store().clients.page,
      store().clients.filters
    );

    if (Array.isArray(clientList?.itens)) {
      const lastList = store().clients.clientList;
      dispatch({
        type: "CLIENT_LIST_SUCCESS",
        payload: {
          list: lastList.concat(clientList.itens),
          page: clientList.pageIndex + 1,
          hasMore: clientList.hasNextPage,
        },
      });
    }
  } catch (e) {
    console.error("Ocorreu um erro ao CHAMAR A LISTA DE SINAIS", e);
    dispatch({ type: "CLIENT_LIST_FAILURE" });
  }
};

export const getClientDetails = (id) => async (dispatch) => {
  dispatch({ type: "CLIENT_DETAILS_LOADING" });

  try {
    const client = await requestUserDetails(id);

    if (client) {
      dispatch({
        type: "CLIENT_DETAILS_SUCCESS",
        payload: client,
      });
    }
  } catch (e) {
    console.error("Ocorreu um erro ao CHAMAR OS DADOS DO CLIENTE", e);
    dispatch({ type: "CLIENT_DETAILS_FAILURE" });
  }
};

export const getClientExtract = (id) => async (dispatch) => {
  dispatch({ type: "GET_EXTRACT_START" });

  try {
    const data = await requestUserExtract(id);
    const concatItems = [].concat(...data?.map(({ items, type, paymentMethod, startAt }) => items?.map((itemsCombined) => ({ ...itemsCombined, paymentMethod: paymentMethod || type, startAt: startAt }) || [])));
    const orderExtracts = concatItems?.sort(function (a, b) {
      return new Date(b?.createdAt) - new Date(a?.createdAt);
    });

    if (data) {
      dispatch({
        type: "GET_EXTRACT_SUCCESS",
        payload: orderExtracts,
      });
    }
  } catch (e) {
    console.error("Ocorreu um erro ao CHAMAR A LISTA DE EXTRATO", e);
    dispatch({ type: "GET_EXTRACT_FAILURE" });
  }
};

export const getUpdateList = () => async (dispatch, store) => {
  dispatch({ type: "CLIENT_LIST_LOADING" });

  try {
    const signalsList = await requestUserList(1, store().clients.filters);

    dispatch({
      type: "CLIENT_LIST_SUCCESS",
      payload: {
        list: signalsList.itens,
        page: signalsList.pageIndex + 1,
        hasMore: signalsList.hasNextPage,
      },
    });
  } catch (e) {
    console.error("Ocorreu um erro ao CHAMAR A LISTA DE SINAIS", e);
    dispatch({ type: "CLIENT_LIST_FAILURE", payload: false });
  }
};

export const updateFilter = (filterName, value) => {
  const newValue = {};
  newValue[filterName] = value;

  return {
    type: "CLIENT_UPDATE_FILTER",
    payload: newValue,
  };
};

export const resetError = () => ({
  type: "RESET_CLIENT_ERRORS",
});

export const updateClient = (client) => async (dispatch) => {
  dispatch({ type: "CLIENT_UPDATE_LOADING" });

  try {
    const updatedClient = await requestUpdateUser(client.userId, client);

    if (updatedClient) {
      dispatch({
        type: "CLIENT_UPDATE_SUCCESS",
        payload: updatedClient,
      });
    }
  } catch (e) {
    console.error("Ocorreu um erro ao atualizar os dados do cliente", e);
    dispatch({
      type: "CLIENT_UPDATE_FAILURE",
      payload:
        e?.response?.data?.message ||
        "Ocorreu um erro ao atualizar os dados do cliente",
    });
  }
};

export const cancelSignature = (id) => async (dispatch) => {
  dispatch({ type: "CLIENT_CANCEL_LOADING" });

  try {
    const updatedClient = await requestDeleteSignarue(id, {
      SubscriptionStatus: 3,
    });

    if (updatedClient) {
      dispatch({
        type: "CLIENT_CANCEL_SUCCESS",
        payload: updatedClient,
      });
    }
  } catch (e) {
    console.error("Ocorreu um erro ao cancelar a assinatura", e);
    dispatch({ type: "CLIENT_CANCEL_FAILURE" });
  }
};

export const updateSubscription = (id, subscription) => async (dispatch) => {
  dispatch({ type: "CLIENT_UPDATE_SUBSCRIPTION_LOADING" });

  try {
    const updatedClient = await requestPutSubscription(id, subscription);

    if (updatedClient) {
      dispatch({
        type: "CLIENT_UPDATE_SUBSCRIPTION_SUCCESS",
        payload: updatedClient,
      });
    }
  } catch (e) {
    console.error("Ocorreu um erro ao atualizar a assinatura", e);
    dispatch({ type: "CLIENT_UPDATE_SUBSCRIPTION_FAILURE" });
  }
};

export const deleteClient = (id) => async (dispatch) => {
  dispatch({ type: "DELETE_USER_START" });

  try {
    await requestDeleteUser(id);

    dispatch({
      type: "DELETE_USER_SUCCESS",
    });
    dispatch(handleOpenPanel(false));
  } catch (e) {
    console.error("Ocorreu um erro ao DESATIVAR O USUÁRIO", e);
    dispatch({ type: "DELETE_USER_FAILURE" });
  }
};
