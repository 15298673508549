import moment from "moment";
// import { resolve } from "path";
import { signalApi, tradeTypePercentileApi, signalApiId, signalApiIdClose} from "../constants/api";
import axiosPrivate from "../utils/api/privateApi";

export const requestSignalsList = (
  //estava com 0
  page = 1,
  filter,
) => {
  let query = `?page=${page}&limit=20`;
  // console.log(filter.position >= 0 ?'a' : 'b')
  // let query = `?page=${page}&size=20&navigation=True`; Deu erro 500
  if (filter.beginDate !== null && filter.beginDate >= 0) query += `&BeginDate=${moment(filter.beginDate).format('YYYY-MM-DDThh:mm:ssZ')}`;
  if (filter.endDate) query += `&EndDate=${moment(filter.endDate).format('YYYY-MM-DDThh:mm:ssZ')}`;
  if (filter.signalType !== null && filter.signalType >= 0) query += `&SignalType=${filter.signalType}`;
  if (filter.position !== null && filter.position >= 0) query += `&Position=${filter.position}`;
  if (filter.tradeType !== null && filter.tradeType >= 0) query += `&TradeType=${filter.tradeType}`;
  if (filter.entryType !== null && filter.entryType >= 0) query += `&EntryType=${filter.entryType}`;
  if (filter.statusTrade !== null && filter.statusTrade) query += `&StatusTrade=${filter.statusTrade}`;


  return new Promise((resolve, reject) => {
    axiosPrivate
      .get(`${signalApi}${query}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}


export const requestTradeTypePercentile = (tradeType) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get(`${tradeTypePercentileApi}/${tradeType}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const postSignal = (signalObj) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .post(`${signalApi}`, signalObj)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const putSignal = (signalObj, id) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .put(`${signalApi}/${id}`, signalObj)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const postNewNote = (signalObj, note, ) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .post(`${signalApi}/${signalObj}/add-instruction`, note)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const putFinishSignal = (id) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .put(`${signalApi}/${id}/close`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const requestSignalById = (id) => { 
  return new Promise((resolve, reject) => {
    axiosPrivate
    .get(`${signalApiId}/${id}`)
    .then((r) => resolve(r.data))
    .catch((e) => reject(e));
  });
}

export const putUpdateSignalListId = (id) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
    .put(`${signalApiIdClose}/${id}`)
    .then((r) => resolve(r.data))
    .catch((e)=> reject(e));
  });
}


