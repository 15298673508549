const initialState = {
    instructionList: [],
    instructionsListLoading: false,
    instructionCreate: true,
  };
  
  const instructionReducer = (state = initialState, action) => {
    switch (action.type) {
      case "INSTRUCTION_LIST_LOADING":
        return {
          ...state,
          instructionsListLoading: action.payload,
        };
      case "INSTRUCTIONS_LIST":
        return {
          ...state,
          instructionList: action.payload,
        };
        case "INSTRUCTION_CREATE":
          return {
            ...state,
            instructionCreate: action.payload,
          }
      default:
        return state;
    }
  };
  
  export default instructionReducer;
  