import styled from 'styled-components';
import { DatePicker, Stack } from '@fluentui/react';


export const HeaderFilterList = styled.header`

`;

export const headerList = styled(Stack)`

`;

export const horizontalGapStackTokens = {

};

export const AlignItem = styled(Stack.Item)`
  margin-bottom: 4px;
`;

export const DatePickerInput = styled(DatePicker)`
  width: 190px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: solid 1px #e9dada;
  padding-bottom: 10px;
  margin-top: 6px;
  margin-bottom: 4px;
`;

export const ButtonsAlign = styled.div`
  position: relative;
  top: -5px;
`;

export const MoreFilterBtn = styled.div`
  position: relative;
  top: 0px;
`;

export const CreateSignalButton = styled.div`
  position: relative;
  margin-left: 22px;
  &::after {
    content: '';
    width: 1px;
    height: 30px;
    background-color: #ddd2d2;
    position: absolute;
    left: -22px;
    top: -4px;
  }
`;

export const InitialFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 25px;
`;