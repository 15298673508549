import styled from 'styled-components';

export const ListContainer = styled.div`
  height: calc(100vh - 185px);
  overflow: scroll;
  width: 100%;
  overflow-x: auto;
  margin-top: 5px;

  @media (max-width: 1495px) {
    width: calc(100% - 32px);
  }

  ::-webkit-scrollbar {
    width: 20px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b0b4b0;
    background-clip: content-box;
    border-left: 12px solid transparent;
  }
  .ms-List-page:empty {
    height: auto;
  }

  & .ms-DetailsList.is-horizontalConstrained {
    overflow: visible;
  }
`;
