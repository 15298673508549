import React from "react";
import {
  SelectionMode,
  DetailsList,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import { ListContainer } from "./style";

const CommonScrollList = ({
  columns,
  list,
  loading,
  onItemInvoked,
  idList,
}) => {
  if (!loading && list.length === 0) {
    return (
      <MessageBar messageBarType={MessageBarType.info}>
        Nenhum item foi encontrado
      </MessageBar>
    );
  }

  return (
    <ListContainer>
      <DetailsList
        items={list}
        getKey={(item) => item.id || item.key}
        setKey='none'
        className={idList}
        columns={columns}
        selectionMode={SelectionMode.none}
        selectionPreservedOnEmptyClick={false}
        onItemInvoked={onItemInvoked}
      />
    </ListContainer>
  );
};

export default CommonScrollList;
