import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
const enviroment = process.env.REACT_APP_CRYPTO_ENVIROMENT;

let firebaseConfig = null;

if (enviroment === 'development') {
  firebaseConfig = {
    apiKey: "AIzaSyD0c6lrRFaDnIj9eL7qY4RuE6SMdLu2FYw",
    authDomain: "crypto-investidor-dev.firebaseapp.com",
    projectId: "crypto-investidor-dev",
    storageBucket: "crypto-investidor-dev.appspot.com",
    messagingSenderId: "798817247051",
    appId: "1:798817247051:web:24e5a7917c8d9a4125d2ce",
    measurementId: "G-TLLJEL2JF0"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyDfZ0IAKD49uz1-ytsFuljL9YyZARscbw4",
    authDomain: "crypto-investidor-fb0e9.firebaseapp.com",
    projectId: "crypto-investidor-fb0e9",
    storageBucket: "crypto-investidor-fb0e9.appspot.com",
    messagingSenderId: "82885790944",
    appId: "1:82885790944:web:8c87ac7c9e0c9a4bff1485",
    measurementId: "G-JHHVB7NN47",
  };
}

const firestoreAutoId = () => {
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let autoId = ''

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(
      Math.floor(Math.random() * CHARS.length)
    )
  }
  return autoId
}

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();
const storage = app.storage();
const auth = app.auth();

export { db, auth, firestoreAutoId, firebase, storage };
