import styled from 'styled-components';
import { PrimaryButton, Spinner, Icon } from '@fluentui/react';

export const CustomButton = styled(PrimaryButton)`
  position: relative;
  border-radius: 1.5px;
`;

export const ButtonText = styled.p`
  margin: 0px 4px;
  line-height: 100%;
  display: block;
  font-weight: 600;
  color: ${({ isDisabled }) => (isDisabled ? '#000' : '#fff')};
`;

export const CustomSpinner = styled(Spinner)`
  position: absolute;
  width: 100%;
  background-color: #f7f1f1;
  inset: 0;
`;

export const CheckIcon = styled(Icon)`
  color: green;
  font-size: 25px;
  position: absolute;
  width: 100%;
`;
