import styled from 'styled-components';
import { FontIcon } from '@fluentui/react/lib/Icon';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  position: relative;
  width: 100%;
  margin-top: 120px;
  h1 {
    margin-top: 12px;
  }
  p {
    font-size: 18px;
    margin-top: 12px;
  }
`;

export const UserIcon = styled(FontIcon)`
  font-size: 37px;
  margin-bottom: 12px;
`;