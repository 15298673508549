const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  openDetailPanel: false,
  successMessage: '',
  cupom: {
    couponId: null,
    type: 0,
    name: "",
    percentage: 0,
    validUntil: '',
    active: true,
    planId: null,
    planName: "",
  },
  cupomLoading: false,
  cupomError: null,
  filters: {
    name: '',
    percentage: '',
    startDate: '',
    endDate: '',
    active: null,
  }
};

const signalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CUPOM_LIST_LOADING":
      return {
        ...state,
        listLoading: action.payload,
        listError: null,
      };
    case "CUPOM_LIST_ERROR":
      return {
        ...state,
        listLoading: false,
        list: [],
        listError: action.payload,
      };
    case "CUPOM_LIST_SUCCESS":
      return {
        ...state,
        listLoading: false,
        list: action.payload,
      };

    case "GET_CUPOM_BY_ID_SUCCESS":
      return {
        ...state,
        cupom: action.payload,
        cupomError: null,
      };
    case "GET_CUPOM_BY_ID_FAIL":
      return {
        ...state,
        cupom: initialState.cupom,
        cupomError: action.payload,
      };

    case "CUPOM_UPDATE_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case "CUPOM_PAINEL_IS_OPEN":
      return {
        ...state,
        openPanel: action.payload,
        cupom: !action.payload ? initialState.cupom : state.cupom,
      };

    case "UPDATE_CUPOM_VALUE":
      return {
        ...state,
        cupom: {
          ...state.cupom,
          [action.payload.key]: action.payload.value,
        },
      };

    case "CUPOM_UPDATE_LOADING":
      return {
        ...state,
        cupomLoading: true,
        cupomError: null,
      };
    case "CUPOM_UPDATE_SUCCESS":
      return {
        ...state,
        cupomLoading: false,
        successMessage: "Cupom sincronizado com sucesso.",
        // cupom: action.payload,
      };
    case "CUPOM_UPDATE_MESSAGE":
      return {
        ...state,
        successMessage: action.payload,
        // cupom: action.payload,
      };
    case "CUPOM_UPDATE_ERROR":
      return {
        ...state,
        cupomLoading: false,
        cupomError: action.payload,
      };

    default:
      return state;
  }
};

export default signalReducer;
