import { TextField, ComboBox, DefaultButton } from "@fluentui/react";
import styled from "styled-components";

export const FiltersContainer = styled.div`
  width: 100%;
  margin-top: 6px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-bottom: solid 1px #e9dada;
  padding-bottom: 16px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SearchInput = styled(TextField)`
  width: 290px;
`;

export const SearchButton = styled(DefaultButton)`
  font-size: 16px;
  margin-left: 4px;
`;

export const FilterOptions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  position: relative;
  top: 5px;
`;

export const Select = styled(ComboBox)`
  max-width: 110px;
`;

export const Divider = styled.div`
  width: 1px;
  height: 32px;
  background-color: #e9dada;
  margin: 0 16px;
`;
