import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../../core/ui/buttonFluent";
import {
  clearCreateErr,
  createGroup,
} from "../../../../../../core/redux/actions/communityActions";
import { DialogContent, Check, Error, CheckContainer } from "./style";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  TextField,
} from "@fluentui/react";

const dialogContentProps = {
  type: DialogType.largeHeader,
  title: "Criar novo grupo",
};

export default function CreateGroup({ hideDialog, toggleHideDialog }) {
  const { loadingCreate, createError } = useSelector(
    (store) => store.community
  );
  const [groupName, setGroupName] = useState("");
  const [onlyAdmin, setOnlyAdmin] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [subRestriction, setSubRestriction] = useState(false);

  const dispatch = useDispatch();

  const checkCanCreate = () => {
    let error = "";
    setErrorMessage(null);

    if (!groupName) {
      error = "Escreva o nome do grupo.";
    } else if (groupName.length < 3) {
      error = "Mínimo de 3 caracteres.";
    }

    if (error) {
      setErrorMessage(error);
      return false;
    }

    return true;
  };

  const clearValues = () => {
    setGroupName("");

    if (onlyAdmin) {
      setOnlyAdmin(false);
    }

    if (subRestriction) {
      setSubRestriction(false);
    }
  };

  const handleGroup = async () => {
    if (checkCanCreate()) {
      dispatch(
        createGroup(
          groupName,
          onlyAdmin,
          toggleHideDialog,
          clearValues,
          setErrorMessage,
          subRestriction
        )
      );
    }
  };

  const clearErrors = () => {
    setErrorMessage(null);
  };

  useEffect(() => {
    if (createError) {
      const timer = setTimeout(() => {
        dispatch(clearCreateErr());
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [createError]);

  return (
    <Dialog
      hidden={hideDialog}
      onDismiss={toggleHideDialog}
      dialogContentProps={dialogContentProps}
    >
      <DialogContent>
        <TextField
          placeholder='Escreve o nome do grupo...'
          label='Nome do grupo'
          onFocus={() => clearErrors()}
          onChange={(e, val) => setGroupName(val)}
        />
        <CheckContainer>
          <Check
            label='Somente administradores'
            onChange={(ev, val) => setOnlyAdmin(val)}
          />
          <Check
            label='Restrição dos 7 dias'
            checked={subRestriction}
            onChange={(ev, val) => setSubRestriction(val)}
          />
        </CheckContainer>
      </DialogContent>
      {createError && <Error>{createError}</Error>}
      {errorMessage && <Error>{errorMessage}</Error>}
      <DialogFooter>
        <Button
          onClick={() => handleGroup()}
          text='Criar'
          loading={loadingCreate}
        />
        <DefaultButton onClick={toggleHideDialog} text='Cancelar' />
      </DialogFooter>
    </Dialog>
  );
}
