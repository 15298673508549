import { postRefreshToken } from "../../services/user";
import mem from "mem";

const refreshToken = async () => {
  try {
    const authCredentialsAdmin = JSON.parse(localStorage.getItem("authCredentialsAdmin"));
    const response = await postRefreshToken({
      externalUUID: authCredentialsAdmin?.externalUUID,
      email: authCredentialsAdmin?.email,
      refreshToken: authCredentialsAdmin?.refreshToken,
    });

    if (!response?.accessToken) {
      localStorage.removeItem("authCredentialsAdmin");
    }

    localStorage.setItem("authCredentialsAdmin", JSON.stringify(response));

    return response;
  } catch (error) {
    localStorage.removeItem("authCredentialsAdmin");
    console.log(error);
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshToken, {
  maxAge,
});
