import { DefaultButton, Stack } from "@fluentui/react";
import moment from "moment";

export const validExpiration = (date) => {
  const now = moment.utc().subtract(3, "hours");

  if (moment(now).isAfter(moment(date).add(1, "days").startOf("day")))
    return false;

  return true;
};

// Plan Names
const planNames = (planName) => {
  switch (planName) {
    case "TCI 1":
      return "Plano TCI - Anual à vista";
    case "TCI 2":
      return "Plano TCI - Anual parcelado";
    case "TCI 3":
      return "Plano TCI - Mensal";
    case "ICI 1":
      return "Plano ICI - Anual à vista";
    case "ICI 2":
      return "Plano ICI - Anual parcelado";
    case "ICI 3":
      return "Plano ICI - Mensal";
    default:
      return planName;
  }
};

// Plan Names
const permissionNames = (planName) => {
  switch (planName) {
    case "TCI 1":
      return "TCI";
    case "TCI 2":
      return "TCI";
    case "TCI 3":
      return "TCI";
    case "ICI 1":
      return "ICI";
    case "ICI 2":
      return "ICI";
    case "ICI 3":
      return "ICI";
    case "ICI Liberado":
      return "ICI";
    case "TCI Liberado":
      return "TCI";
    case "Pro Liberado":
      return "PRO";
    case "PRO Liberado":
      return "PRO";
    case "PRO 1":
      return "PRO";
    case "PRO 2":
      return "PRO";
    default:
      return "FREE";
  }
};

// Plan Names
const subscriptionStatus = (status, planName) => {
  if (!Boolean(planName)) return "";

  switch (status) {
    case 0:
      return "Ativo";
    case 1:
      return "Pagamento Pendente";
    case 2:
      return "Cancelado";
    case 3:
      return "Expirado";
    case 4:
      return "Renovado";
    case 5:
      return "Reembolsado";
    case 6:
      return "Extornado";
    case 7:
      return "Indefinido";
    case 8:
      return "Futuro";
    default:
      return status;
  }
};

const checkActive = (name, status, expirationDate, user) => {
  const planName = permissionNames(name);

  if (planName === "TCI" || planName === "ICI" || planName === "PRO") {
    if (Boolean(expirationDate) && !validExpiration(expirationDate))
      return "Free";

    // Check if is canceled and expiration date
    if (status === 2) {
      if (!validExpiration(expirationDate)) return "Free";

      return planName;
    }

    // Check if is active/renewed
    if (status === 0 || status === 4) return planName;
  }

  return "Free";
};

const columns = (onEdit) => [
  {
    key: "column1",
    name: "Nome",
    fieldName: "name",
    minWidth: 230,
    maxWidth: 230,
    isResizable: true,
    styles: "styleColumn",
  },
  {
    key: "column2",
    name: "Email",
    fieldName: "email",
    minWidth: 180,
    maxWidth: 180,
    styles: "styleColumn",
    isResizable: true,
  },
  // {
  //   key: "column3",
  //   name: "Documento",
  //   fieldName: "statusTrade",
  //   minWidth: 180,
  //   maxWidth: 180,
  //   styles: "styleColumn",
  //   isResizable: true,
  // },
  {
    key: "column4",
    name: "Celular",
    fieldName: "phone",
    minWidth: 180,
    maxWidth: 180,
    isResizable: true,
    styles: "styleColumn",
  },
  {
    key: "column5",
    name: "Plano",
    fieldName: "planName",
    minWidth: 180,
    maxWidth: 180,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => planNames(item.planName),
  },
  // {
  //   key: "column6",
  //   name: "Pagamento",
  //   fieldName: "dateStop",
  //   minWidth: 180,
  //   maxWidth: 180,
  //   isResizable: true,
  //   styles: "styleColumn",
  // },
  {
    key: "column7",
    name: "Expira em",
    fieldName: "date",
    minWidth: 180,
    maxWidth: 180,
    styles: "styleColumn",
    isResizable: true,
    onRender: (item) =>
      item.expireAt &&
      moment(item.expireAt).locale("pt-br").format("DD/MM/YYYY"),
  },
  {
    key: "column8",
    name: "Nível de acesso",
    fieldName: "permission",
    minWidth: 180,
    styles: "styleColumn",
    isResizable: true,
    onRender: (user) =>
      checkActive(
        user?.planName,
        user?.subscriptionStatus,
        user?.expireAt,
        user
      ),
  },
  {
    key: "column9",
    name: "Status",
    fieldName: "subscriptionStatus",
    minWidth: 180,
    styles: "styleColumn",
    isResizable: true,
    onRender: (user) =>
      subscriptionStatus(user?.subscriptionStatus, user?.planName),
  },
  {
    key: "column10",
    name: "Ações",
    fieldName: "",
    minWidth: 110,
    maxWidth: 110,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => (
      <Stack horizontal>
        <DefaultButton text="Editar" onClick={() => onEdit(item.id)} />
      </Stack>
    ),
  },
];

export default columns;
