import React from  'react';
import SideBar from '../../container/SideBar';
import NavBar from '../../container/navBar';
import {
  Container,
  ContentContainer,
} from './style';


function PageSkeleton({ children }) {
  return (
    <Container>
      <NavBar />
        <ContentContainer>
          <SideBar />
          {children}
        </ContentContainer>
    </Container>
  );
}

export default PageSkeleton;
