const env = window.location.hostname;
let redirectUri;
if (env === 'localhost') {
  redirectUri = 'http://localhost:3000/';
} else {
  redirectUri = `https://${env}`;
}

const authConfig = {
  "msalConfig": {
    "auth": {
      "clientId": "3b94ef35-6607-4386-95f7-6620355058d8",
      "authority": "https://login.microsoftonline.com/6024a7b0-bca2-4b9d-b1a6-d2a11d87415a",
      "prompt": "consent",
      "redirectUri": redirectUri,
    },
    "cache": {
      "cacheLocation": "sessionStorage",
      "storeAuthStateInCookie": false
    }
  },
  "loginRequest": {
    "scopes": ["openid", "profile", "offline_access"]
  },
  "tokenRequest": {
    "scopes": ["openid", "profile", "offline_access"]
  }
}

export default authConfig;













































