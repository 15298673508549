import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { createFilter } from "react-select";
import {
  createSignal,
  finishSignal,
  getSignalsById,
  getSignalsList,
  getTradesPercentile,
  sendNewNote,
  setPainelDetailsIsOpen,
  setPainelIsOpen,
  setSignalId,
} from "../../../../core/redux/actions/signalsAction";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
// import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { useId, useBoolean } from "@fluentui/react-hooks";
import HistoryPanel from "../HistoryPanel";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { ComboBox, TextField, Stack, Separator } from "@fluentui/react";
import {
  DefaultButton,
  IconButton,
  PrimaryButton,
} from "@fluentui/react/lib/Button";
import {
  horizontalGapStackTokens,
  HistoricalContainer,
  ButtonStyles,
  ContainerGraphic,
  InstructionsContainer,
  Row,
  FiltersSection,
  PercentInput,
  ButtonContainer,
  TargetsValues,
  EditGraphic,
  ShowGraphicEdit,
  EntryInput,
  InputSize,
  ShowGraphic,
  TargetsGrid,
  GraphicInput,
  DeleteIconContainer,
  Alerts,
} from "./style";
import { getCoinsList } from "../../../../core/redux/actions/coinsActions";
import { isValidUrl } from "../../../../core/utils/validations";
import CreateSignalNotification from "../createSignalNotification";
import SignalEndNotification from "../signalEndNotification";
import PopupSignal from "../../../../core/ui/popup";
import Signal from "../../../../core/entities/signal";
import CoinSelectBox from "../../../../core/container/searchCoin/coinSelectBox";
import { SignalImageModal } from "../signalImage";
import { signalShowMensage } from "../../../../core/redux/actions/signalsAction";

const dropdownStyles = { dropdown: { width: 90 } };
const comboBoxStyles = { root: { maxWidth: 2000 } };
const PanelStyles = { root: { padding: "400" } };
const stackTokens = { childrenGap: 20, padding: "0 20px 0 0" };
const addIcon = { iconName: "Add" };
const deleteIcon = { iconName: "Delete" };

const Success = () => (
  <div role='status'>
    <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
      Sinal encerrado com sucesso
    </MessageBar>
  </div>
);

const Error = () => (
  <div>
    <MessageBar messageBarType={MessageBarType.error}>
      Erro ao enviar sinal
    </MessageBar>
  </div>
);

const optionsExchange = [
  { key: "Binance", text: "Binance" },
  { key: "Binance Future", text: "Binance Future" },
];

const percentInput = {
  fieldGroup: {
    borderLeft: "0px solid transparent !important",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

const valueInput = {
  fieldGroup: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
};

const optionsTypeOfTrade = [
  { key: 0, text: "Scalp" },
  { key: 1, text: "Day Trade" },
  { key: 2, text: "Swing Trade" },
];

const optionsPosition = [
  { key: 0, text: "Long" },
  { key: 1, text: "Short" },
];

const optionsLeveragen = [
  { key: 1, text: "1X" },
  { key: 3, text: "3X" },
  { key: 5, text: "5X" },
  { key: 10, text: "10X" },
  { key: 20, text: "20X" },
  { key: 25, text: "25X" },
  { key: 50, text: "50X" },
];

const optionsTypeOfInput = [
  { key: 0, text: "Mercado" },
  { key: 1, text: "Rompimento" },
  { key: 2, text: "Retração" },
];

const statusSignal = [
  { key: 0, text: "Closed" },
  { key: 1, text: "Active" },
  { key: 2, text: "Stop" },
  { key: 3, text: "Wait" },
];

export const SidePanelSignals = () => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const dispatch = useDispatch();
  const {
    openPanel,
    stopLoss,
    targets,
    entryRange,
    signal,
    noteLoading,
    signalError,
    loadingSignal,
    openDetailPanel,
    showMensage,
    signalId,
  } = useSelector((store) => store.signal);
  const { coinList, exchange, coinsListLoading } = useSelector(
    (store) => store.coins
  );

  const [isChecked, setIsChecked] = React.useState(true);

  const [count, setCount] = useState(0);
  const [countInTimeout, setCountInTimeout] = useState(0);

  const [exchangeOption, setExchangeOption] = useState("Binance");
  const [tradeOption, setTradeOption] = useState(0);
  const [positionOption, setPositionOption] = useState(0);
  const [leveragenOption, setLeveragenOption] = useState(1);
  const [inputOption, setInputOption] = useState(0);
  const [coin, setCoin] = useState(null);
  const [statusSignal, setStatusSignal] = useState(0);
  const [coinParity, setCoinParity] = useState(null);
  const [coinSignal, setCoinSignal] = useState(null);
  const [enterValue, setEnterValue] = useState(null);
  const [maxEnterValue, setMaxEnterValue] = useState(null);
  const [stopLossValue, setStopLossValue] = useState(null);
  const [instructionSignal, setInstructionSignal] = useState(
    "Para essa operação estamos utilizando uma banca de 5%"
  );
  const [freeSignal, setFreeSignal] = useState(false);
  const [targetsValue, setTargetsValue] = useState([0]);
  const [telegramSignal, setTelegramSignal] = useState(true);
  const [pushSignal, setPushSignal] = useState(true);
  const [discordSignal, setDiscordSignal] = useState(true);
  const [graphicValue, setGraphicValue] = useState(null);
  const [signalLoadingRedux, setSignalLoadingRedux] = useState(false);
  // const [showMensage, setShowMensage] = useState(false);
  const [fieldsPercent, setFieldsPercent] = useState(null);
  const [lastIndex, setLastIndex] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [detailGraphic, setDetailGraphic] = useState(null);
  const [initialMessage, setInitialMessage] = useState([]);
  const changeTargets = targets;

  const getPercent = (value, entryVal) => {
    let percent = (value / entryVal) * 100;
    percent = percent > 100 ? percent - 100 : 100 - percent;

    return handleInputValues(parseFloat(percent.toFixed(2)));
  };

  const handleInitialMessage = () => {
    setInitialMessage([
      {
        note: instructionSignal,
        graphic: graphicValue,
      },
    ]);
  };

  useEffect(() => {
    if (signal) {
      setExchangeOption(signal?.signalType || "Binance"); //signalType é a exchange
      setTradeOption(signal?.tradeType || 0);
      setPositionOption(signal?.position || 0);
      setLeveragenOption(signal?.leverage || null);
      setInputOption(signal?.entryType || 0);
      setCoin(signal?.symbol || null);
      setEnterValue(signal?.input || null);
      setMaxEnterValue(signal?.inputMax || null);
      setStopLossValue(signal?.stopLoss || null);
      setDetailGraphic(signal?.graphic || null);
      setCoinSignal(signal?.symbol || null);
      // setDiscordSignal(signal?.symbol || null)
      // setTelegramSignal(signal?.symbol || null)

      const targets = signal?.targets
        .sort((a, b) => (a.number > b.number ? 1 : -1))
        .map((target) => target.value);
      setTargetsValue(targets || [0]);

      const targetsPercent = signal?.targets
        .sort((a, b) => (a.number > b.number ? 1 : -1))
        .map((tar) => tar.percentual);

      setFieldsPercent({
        ...fieldsPercent,
        entryInputValue: getPercent(signal?.inputMax, signal?.input),
        stopLossInputValue: getPercent(signal?.stopLoss, signal?.input),
        targets: targetsPercent,
      });
    }
  }, [signal]);

  useEffect(() => {
    if (!openDetailPanel && openPanel && !signal) {
      dispatch(getTradesPercentile(tradeOption, setFieldsPercent));
    }
  }, [tradeOption, openPanel, exchangeOption]);

  useEffect(() => {
    if (enterValue && !openDetailPanel) {
      handlePercentValue(
        enterValue,
        positionOption === 0 ? entryRange : -entryRange,
        setMaxEnterValue
      );
      handlePercentValue(
        enterValue,
        positionOption === 0 ? -stopLoss : stopLoss,
        setStopLossValue
      );

      if (!signal) {
        const newTargets = [];
        changeTargets.forEach((percent) => {
          newTargets.push(
            handlePercentValue(
              enterValue,
              positionOption === 0 ? percent : -percent,
              null
            )
          );
        });

        setTargetsValue(newTargets);
      }
    }
  }, [stopLoss, enterValue, changeTargets, positionOption]);

  useEffect(() => {
    if (
      exchange !== exchangeOption &&
      openPanel &&
      !openDetailPanel &&
      !signalId
    ) {
      dispatch(getCoinsList(exchangeOption === "Binance" ? 0 : 1));
    }
  }, [exchangeOption, openPanel]);

  const onChange = (ev, checked) => {
    setIsChecked(!!checked);
  };

  function _onChange(ev, checked) {
    console.log("toggle is " + (checked ? "checked" : "not checked"));
  }

  const addTarget = () => {
    let arr = [];

    arr.push(0);

    if (targetsValue?.length < 6) {
      setTargetsValue([...targetsValue, 0]);
      setFieldsPercent({
        ...fieldsPercent,
        targets: [...fieldsPercent.targets, 0],
      });
    }
  };

  const updateTarget = (indexToChange, val) => {
    setLastIndex(indexToChange);
    const newTargets = targetsValue?.map((item, index) => {
      if (index === indexToChange) return val;
      return item;
    });

    setTargetsValue(newTargets);
  };

  useEffect(() => {
    if (lastIndex >= 0 && !signal) {
      let percent = (targetsValue?.[lastIndex] / enterValue) * 100;
      percent = percent > 100 ? percent - 100 : 100 - percent;
      const newTargetsPercent = fieldsPercent?.targets?.map((item, index) => {
        if (index === lastIndex)
          return isFinite(percent)
            ? handleInputValues(parseFloat(percent.toFixed(2)))
            : 0;
        return item;
      });

      setFieldsPercent({
        ...fieldsPercent,
        targets: newTargetsPercent,
      });
    }
  }, [targetsValue]);

  const convertTargetToPercet = (value, callback, id, label) => {
    let percent = (value / enterValue) * 100;
    percent = percent > 100 ? percent - 100 : 100 - percent;

    setFieldsPercent({
      ...fieldsPercent,
      [id]: parseFloat(percent.toFixed(2)),
      [label]: isFinite(percent.toFixed(2))
        ? handleInputValues(parseFloat(percent.toFixed(2)))
        : 0,
    });

    callback(value);
  };

  const updateTargetPercent = (base, newPercent, indexTarget) => {
    setLastIndex(null);
    let total =
      positionOption === 0
        ? base + (base * newPercent) / 100
        : base - (base * newPercent) / 100;

    const newTargets = targetsValue?.map((target, index) => {
      if (indexTarget === index) return handleInputValues(total);
      return target;
    });

    const newTargetsPercent = fieldsPercent?.targets?.map((item, index) => {
      if (index === indexTarget) return newPercent;
      return item;
    });

    setFieldsPercent({
      ...fieldsPercent,
      targets: newTargetsPercent,
    });

    setTargetsValue(newTargets);
  };

  const handlePercentValue = (value, percent, callback) => {
    let total = (value / 100) * (percent + 100);

    if (!callback) return handleInputValues(total);

    callback(handleInputValues(total));
  };

  const handlePositionOpt = () => {
    if (signal) return optionsPosition;

    if (exchangeOption === "Binance Future") {
      return optionsPosition;
    }

    return [{ key: 0, text: "Long" }];
  };

  const [choice, setChoice] = React.useState(undefined);
  const showAll = choice === "all";

  const determineOrder = (arr) => {
    if (arr?.length < 2) {
      return "not enough items";
    }
    let ascending = null;
    let nextArr = arr?.slice(1);
    for (var i = 0; i < nextArr?.length; i++) {
      if (nextArr?.[i] === arr?.[i]) {
        continue;
      } else if (ascending === null) {
        ascending = nextArr?.[i] > arr?.[i];
      } else if (ascending !== nextArr?.[i] > arr?.[i]) {
        return "unsorted";
      }
    }

    if (ascending === null) {
      return "all items are equal";
    }

    return ascending ? "ascending" : "descending";
  };

  const deleteTarget = (idx) => {
    const newTargets = targetsValue.filter((target, index) => index !== idx);
    const newTargetsPercents = fieldsPercent?.targets?.filter(
      (target, index) => index !== idx
    );

    setTargetsValue(newTargets);
    setFieldsPercent({
      ...fieldsPercent,
      targets: newTargetsPercents,
    });
  };

  const validatePostFields = () => {
    setFormErrors(null);
    let validated = true;
    let error = null;
    let id = {};

    if (positionOption === 0 && maxEnterValue < stopLossValue) {
      id["maxEntry"] = "Deve ser maior que o Stop Loss";
      validated = false;
    } else if (positionOption === 1 && stopLossValue < maxEnterValue) {
      id["stopLoss"] = "Deve ser maior que a Entrada máxima";
      validated = false;
    } else if (tradeOption !== 0 && tradeOption !== 1 && tradeOption !== 2) {
      id["tradeOption"] = "Selecione uma opção válida";
      validated = false;
    } else if (positionOption !== 0 && positionOption !== 1) {
      id["positionOption"] = "Selecione uma opção válida";
      validated = false;
    } else if (exchangeOption === "Binance" && positionOption !== 0) {
      id["positionOption"] = "Selecione uma opção válida";
      validated = false;
    } else if (inputOption !== 0 && inputOption !== 1 && inputOption !== 2) {
      id["inputOption"] = "Selecione uma opção válida";
      validated = false;
    } else if (!coinParity) {
      id["coin"] = "Selecione uma opção válida";
      validated = false;
    } else if (!enterValue) {
      id["enterValue"] = "Informe um vasetGraphicValuelor";
      validated = false;
    } else if (!maxEnterValue) {
      id["maxEnterValue"] = "Informe um valor";
      validated = false;
    } else if (!stopLossValue) {
      id["stopLossValue"] = "Informe um valor";
      validated = false;
    } else if (
      positionOption === 0 &&
      determineOrder(targetsValue) !== "ascending"
    ) {
      id["targetsOrder"] = "Valores devem ser crescentes";
      validated = false;
    } else if (
      positionOption === 1 &&
      determineOrder(targetsValue) !== "descending"
    ) {
      id["targetsOrder"] = "Valores devem ser decrescentes";
      validated = false;
    }

    if (!validated) {
      setFormErrors(id);
    } else {
      setFormErrors({});
    }

    return validated;
  };

  const clearValues = () => {
    setCoin(undefined);
    setCoinParity(undefined);
    setGraphicValue(undefined);
    setEnterValue(undefined);
    setInputOption(0);
    setTradeOption(0);
    setPositionOption(0);
    setMaxEnterValue(undefined);
    setLeveragenOption(1);
    setStopLossValue(undefined);
    setTargetsValue(undefined);
    setInitialMessage([]);
    setInstructionSignal(
      "Para essa operação estamos utilizando uma banca de 5%"
    );
    setGraphicValue("");
  };

  const allowRemoveTarget = () => {
    if (tradeOption === 0) return 3;
    if (tradeOption === 1) return 4;
    if (tradeOption === 2) return 5;
  };

  const handleExchange = (val) => {
    setExchangeOption(val.key);
    clearValues();
  };

  const submitSignal = () => {
    if (!validatePostFields()) return;

    const signalObj = {
      input: parseFloat(enterValue),
      inputMax: parseFloat(maxEnterValue),
      stopLoss: parseFloat(stopLossValue),
      graphic: signal || signalId ? graphicValue : initialMessage[0]?.graphic,
      exchange: exchangeOption,
      tradeType: tradeOption,
      note: signal || signalId ? instructionSignal : initialMessage[0]?.note,
      signalType: exchangeOption === "Binance" ? 1 : 2,
      entryType: inputOption,
      targets: targetsValue?.map((target, index) => ({
        number: index + 1,
        value: target,
        percentual: parseFloat(fieldsPercent?.targets?.[index]),
      })),
      coinParity: {
        name: coinParity.label,
        symbol: coinParity.value,
        value: coinParity.coinValue,
        coinType: coinParity.type,
      },
      sendToTelegram: telegramSignal,
      sendToDiscord: discordSignal,
      sendPush: pushSignal,
      publishFreeAccount: freeSignal,
      leverage: leveragenOption,
      position: positionOption,
    };

    dispatch(createSignal(signalObj, signal?.id || null));
  };

  const handleInputValues = (val, setValue, targets, idx) => {
    if (!val) return;

    let formatted = typeof val === "string" ? val.replaceAll(",", ".") : val;
    const decimals = Math.pow(10, 8);
    const numFloat = parseFloat(formatted);

    if (setValue) {
      setValue(Math.round((numFloat + Number.EPSILON) * decimals) / decimals);
    }

    if (targets) {
      const newTargets = targetsValue?.map((item, index) => {
        if (index === idx)
          return Math.round((numFloat + Number.EPSILON) * decimals) / decimals;
        return item;
      });

      setTargetsValue(newTargets);
    }

    return Math.round((numFloat + Number.EPSILON) * decimals) / decimals;
  };

  const changePercent = (id, value, label, callback) => {
    let total = 0;
    let isShort = positionOption === 1;

    if (id !== "stopLossPercent") {
      total = isShort
        ? enterValue - (enterValue * parseFloat(value)) / 100
        : enterValue + (enterValue * parseFloat(value)) / 100;
    }

    if (id === "stopLossPercent") {
      total = isShort
        ? enterValue + (enterValue * parseFloat(value)) / 100
        : enterValue - (enterValue * parseFloat(value)) / 100;
    }

    handleInputValues(total, callback);

    setFieldsPercent({
      ...fieldsPercent,
      [id]: parseFloat(value),
      [label]: value,
    });
  };

  const clearFormErrors = (id) => {
    setFormErrors({
      ...formErrors,
      [id]: false,
    });
  };

  const isDisabled = () => {
    if (signal || signalId || loadingSignal) {
      return true;
    } else {
      return false;
    }
  };

  const closePanel = () => {
    dispatch(setSignalId(""));
    clearValues();
    dispatch(setPainelIsOpen(false));
    dispatch(setPainelDetailsIsOpen(false));
  };

  const handleExchangeValue = () => {
    if (signal) {
      return exchangeOption === 1 ? "Binance" : "Binance Future";
    } else {
      return exchangeOption;
    }
  };

  useEffect(() => {
    if (signalId) {
      dispatch(getSignalsById(signalId));
      setInstructionSignal(
        "Para essa operação estamos utilizando uma banca de 5%"
      );
      setGraphicValue("");
    }
  }, [signalId]);

  const isDisabledInstructions = () => {
    if (
      (!signal && initialMessage.length === 1) ||
      noteLoading ||
      signal?.statusTrade === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Panel
      isOpen={openPanel}
      onDismiss={() => {
        if (!openDetailPanel && signalId) {
          dispatch(getSignalsList());
        }
        setExchangeOption("Binance");
        closePanel();
      }}
      headerText={signal ? "Editar sinal" : "Criar sinal"}
      headerClassName={{
        backgroundColor: "#fff",
        padding: "12px 0",
        marginTop: "-18px",
      }}
      closeButtonAriaLabel='Close'
      customWidth={'1320px'}
      type={PanelType.custom}
      isFooterAtBottom={true}
      styles={PanelStyles}
    >
      <Stack horizontal tokens={horizontalGapStackTokens}>
        <Stack>
          {(choice === "success" || showAll) && <Success />}
          <FiltersSection>
            <Row>
              <ComboBox
                disabled={isDisabled()}
                label='Exchange'
                selectedKey={handleExchangeValue()}
                onFocus={() => clearFormErrors("exchangeOption")}
                errorMessage={
                  formErrors.exchangeOption ? formErrors.exchangeOption : null
                }
                options={optionsExchange}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
                onChange={(item, val) => handleExchange(val)}
              />
              <ComboBox
                disabled={isDisabled()}
                label='Tipo de trade'
                selectedKey={tradeOption}
                onFocus={() => clearFormErrors("tradeOption")}
                errorMessage={
                  formErrors.tradeOption ? formErrors.tradeOption : null
                }
                value={tradeOption}
                options={optionsTypeOfTrade}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
                onChange={(item, val) => setTradeOption(val.key)}
              />
              <ComboBox
                disabled={isDisabled()}
                label='Posição'
                selectedKey={positionOption}
                onFocus={() => clearFormErrors("positionOption")}
                errorMessage={
                  formErrors.positionOption ? formErrors.positionOption : null
                }
                value={positionOption}
                options={handlePositionOpt()}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
                onChange={(item, val) => {
                  dispatch(getTradesPercentile(tradeOption, setFieldsPercent));
                  setPositionOption(val.key);
                }}
              />
              <Dropdown
                label='Alavancagem'
                placeholder='1X'
                selectedKey={leveragenOption}
                onFocus={() => clearFormErrors("leveragenOption")}
                errorMessage={
                  formErrors.leveragenOption ? formErrors.leveragenOption : null
                }
                value={leveragenOption}
                options={optionsLeveragen}
                disabled={exchangeOption !== "Binance Future" || isDisabled()}
                styles={dropdownStyles}
                calloutProps={{ doNotLayer: true }}
                onChange={(item, val) => setLeveragenOption(val.key)}
              />
              <ComboBox
                disabled={isDisabled()}
                label='Tipo de entrada'
                selectedKey={inputOption}
                onFocus={() => clearFormErrors("inputOption")}
                errorMessage={
                  formErrors.inputOption ? formErrors.inputOption : null
                }
                value={inputOption}
                options={optionsTypeOfInput}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
                onChange={(item, val) => setInputOption(val.key)}
              />
            </Row>
            <Stack className='Searchbox' tokens={horizontalGapStackTokens}>
              <CoinSelectBox
                disabled={isDisabled() || coinsListLoading}
                value={!coinParity ? "" : coinParity}
                filterOption={createFilter({ ignoreAccents: false })}
                placeholder={signal ? coinSignal : "Pesquisar moeda"}
                loading={coinsListLoading}
                error={formErrors.coin}
                onChange={(e) => {
                  setCoinParity(e);
                  setCoin(e.value);
                  setEnterValue(e.coinValue);
                  handlePercentValue(
                    e.coinValue,
                    positionOption === 0 ? entryRange : -entryRange,
                    setMaxEnterValue
                  );
                  handlePercentValue(
                    e.coinValue,
                    positionOption === 0 ? -stopLoss : stopLoss,
                    setStopLossValue
                  );
                }}
                options={coinList}
              />
            </Stack>
          </FiltersSection>
          {coin && (
            <FiltersSection>
              <Stack horizontal tokens={horizontalGapStackTokens}>
                <EntryInput>
                  <TextField
                    disabled={isDisabled()}
                    type='number'
                    label='Entrada'
                    readOnly={inputOption === 0 ? true : false}
                    onBlur={(ev) =>
                      handleInputValues(ev.target.value, setEnterValue)
                    }
                    value={enterValue || ""}
                    inputProps={{ lang: "en-US" }}
                    onFocus={() => clearFormErrors("enterValue")}
                    errorMessage={
                      formErrors.enterValue ? formErrors.enterValue : null
                    }
                    onChange={(ev, val) => setEnterValue(val)}
                  />
                </EntryInput>
                <TargetsValues>
                  <InputSize>
                    <TextField
                      disabled={isDisabled()}
                      label='Entrada máxima'
                      type='number'
                      value={!maxEnterValue ? "" : maxEnterValue}
                      styles={valueInput}
                      patt
                      onBlur={(ev) =>
                        handleInputValues(ev.target.value, setMaxEnterValue)
                      }
                      onFocus={() => clearFormErrors("maxEntry")}
                      errorMessage={
                        formErrors.maxEntry ? formErrors.maxEntry : null
                      }
                      onChange={(ev, val) =>
                        convertTargetToPercet(
                          val,
                          setMaxEnterValue,
                          "maxEntryPercent",
                          "entryInputValue"
                        )
                      }
                    />
                  </InputSize>
                  <PercentInput
                    disabled={isDisabled()}
                    label='%'
                    type='number'
                    styles={percentInput}
                    value={
                      !fieldsPercent?.entryInputValue || !coin
                        ? ""
                        : fieldsPercent?.entryInputValue
                    }
                    onChange={(ev, val) =>
                      changePercent(
                        "maxEntryPercent",
                        val,
                        "entryInputValue",
                        setMaxEnterValue
                      )
                    }
                  />
                </TargetsValues>
                <TargetsValues>
                  <InputSize>
                    <TextField
                      disabled={isDisabled()}
                      label='Stop loss'
                      type='number'
                      onBlur={(ev) =>
                        handleInputValues(ev.target.value, setStopLossValue)
                      }
                      onFocus={() => clearFormErrors("stopLoss")}
                      value={stopLossValue}
                      styles={valueInput}
                      errorMessage={
                        formErrors.stopLoss ? formErrors.stopLoss : null
                      }
                      onChange={(ev, val) =>
                        convertTargetToPercet(
                          val,
                          setStopLossValue,
                          "stopLossPercent",
                          "stopLossInputValue"
                        )
                      }
                    />
                  </InputSize>
                  <PercentInput
                    disabled={isDisabled()}
                    type='number'
                    label='%'
                    styles={percentInput}
                    value={
                      !fieldsPercent?.stopLossInputValue || !coin
                        ? ""
                        : fieldsPercent?.stopLossInputValue
                    }
                    onChange={(ev, val) =>
                      changePercent(
                        "stopLossPercent",
                        val,
                        "stopLossInputValue",
                        setStopLossValue
                      )
                    }
                  />
                </TargetsValues>
              </Stack>
            </FiltersSection>
          )}
          {coin && (
            <FiltersSection>
              <TargetsGrid>
                {targetsValue?.map((target, index) => (
                  <TargetsValues key={index}>
                    <InputSize>
                      <TextField
                        disabled={isDisabled() || !enterValue}
                        label={`Alvo ${index + 1}`}
                        value={targetsValue[index]}
                        type='number'
                        styles={valueInput}
                        onBlur={(ev) =>
                          handleInputValues(ev.target.value, null, true, index)
                        }
                        onChange={(ev, val) => updateTarget(index, val)}
                      />
                    </InputSize>
                    <PercentInput
                      disabled={isDisabled() || !enterValue}
                      label={`%`}
                      type='number'
                      styles={percentInput}
                      value={
                        !fieldsPercent?.targets?.[index] || !coin
                          ? ""
                          : fieldsPercent?.targets?.[index]
                      }
                      onChange={(ev, val) =>
                        updateTargetPercent(enterValue, val, index)
                      }
                    />
                    {index + 1 > allowRemoveTarget() && !isDisabled() && (
                      <DeleteIconContainer>
                        <IconButton
                          disabled={!enterValue || signal}
                          iconProps={deleteIcon}
                          title='Remover'
                          ariaLabel='Remover'
                          style={{ backgroundColor: "transparent" }}
                          onClick={() => deleteTarget(index)}
                        />
                      </DeleteIconContainer>
                    )}
                  </TargetsValues>
                ))}
                {targetsValue?.length < 6 && !isDisabled() && (
                  <Stack.Item align='end'>
                    <IconButton
                      disabled={!enterValue || signal}
                      iconProps={addIcon}
                      title='Adicionar'
                      ariaLabel='Adicionar'
                      onClick={() => addTarget()}
                    />
                  </Stack.Item>
                )}
              </TargetsGrid>
            </FiltersSection>
          )}
          <Stack tokens={horizontalGapStackTokens}>
            {coin && (
              <Stack horizontal style={{ width: "100%" }}>
                <Stack grow tokens={stackTokens}>
                  <Checkbox
                    disabled={isDisabled()}
                    label='Conta Free'
                    checked={freeSignal}
                    onChange={(ev, val) => setFreeSignal(val)}
                  />
                  <Toggle
                    disabled={isDisabled()}
                    onText='Discord'
                    offText='Discord'
                    checked={discordSignal}
                    onChange={(ev, val) => setDiscordSignal(val)}
                  />
                  <Toggle
                    disabled={isDisabled()}
                    onText='Telegram'
                    offText='Telegram'
                    checked={telegramSignal}
                    onChange={(ev, val) => setTelegramSignal(val)}
                  />
                  <Toggle
                    disabled={isDisabled()}
                    onText='Push'
                    offText='Push'
                    checked={pushSignal}
                    onChange={(ev, val) => setPushSignal(val)}
                  />
                </Stack>
                {/* <ContainerGraphic grow>
                {isValidUrl(graphicValue) && (
                  <a href={graphicValue} target="_blank" rel="noreferrer">
                    <img src={graphicValue} style={{ maxWidth: "400px" }} />
                  </a>
                )}
              </ContainerGraphic> */}
              </Stack>
            )}

            <Stack horizontal>
              {signal || signalId ? (
                <SignalEndNotification
                  finishSignal={() =>
                    dispatch(finishSignal(signal?.id || signalId))
                  }
                  disabled={signal?.statusTrade === 0}
                  secondaryText='Sinal encerrado com sucesso'
                  text={loadingSignal ? "Encerrando..." : "Encerrar sinal"}
                />
              ) : (
                <CreateSignalNotification
                  loading={loadingSignal}
                  created={signalId}
                  finishSignal={() => dispatch(finishSignal(signalId))}
                  disabled={!coin || signal?.statusTrade === 0}
                  submitSignal={() => submitSignal()}
                />
              )}
            </Stack>

            {showMensage && (
              <Alerts>
                <MessageBar
                  loadingSignal={true}
                  messageBarType={
                    signalError ? MessageBarType.error : MessageBarType.success
                  }
                >
                  {signalError ? signalError : "Acão realizada com sucesso"}
                </MessageBar>
              </Alerts>
            )}
          </Stack>
        </Stack>

        <Separator vertical />

        <Stack grow={3}>
          <InstructionsContainer>
            <HistoricalContainer>
              <HistoryPanel
                messages={signal?.instructions || initialMessage || []}
                removeInitialMessage={setInitialMessage}
                detailPanel={openDetailPanel}
                signalId={signalId}
              />
            </HistoricalContainer>
            <TextField
              label='Instruções:'
              multiline
              rows={6}
              disabled={isDisabledInstructions()}
              value={instructionSignal}
              onChange={(ev, val) => setInstructionSignal(val)}
            />
            <ButtonContainer>
              <EditGraphic>
                <GraphicInput
                  placeholder='Adicione um gráfico'
                  value={graphicValue}
                  disabled={isDisabledInstructions()}
                  onChange={(ev, val) => setGraphicValue(val)}
                  maskChar='?'
                />
                <SignalImageModal
                  isModalOpen={isModalOpen}
                  hideModal={hideModal}
                  graphicValue={graphicValue}
                />
                {isValidUrl(graphicValue) && (
                  <ShowGraphicEdit>
                    <ShowGraphic onClick={() => showModal()}>
                      Ver gráfico
                    </ShowGraphic>
                  </ShowGraphicEdit>
                )}
              </EditGraphic>
              <ButtonStyles
                disabled={isDisabledInstructions()}
                textColor={isDisabledInstructions() ? "#a19f9d" : "#fff"}
                onClick={() => {
                  if (openDetailPanel || signalId) {
                    dispatch(
                      sendNewNote(instructionSignal, graphicValue, signal.id)
                    );
                  } else {
                    handleInitialMessage();
                  }

                  if (openDetailPanel || signal) {
                    setInstructionSignal(
                      "Para essa operação estamos utilizando uma banca de 5%"
                    );
                    setGraphicValue("");
                  }
                }}
              >
                {noteLoading ? "Enviando..." : "Adicionar recomendação"}
              </ButtonStyles>
            </ButtonContainer>
          </InstructionsContainer>
        </Stack>
      </Stack>
    </Panel>
  );
};

export default SidePanelSignals;
