import currency from "currency.js";

export const formatPricePrecision = (value) => {
    if (value === null || value === undefined) return null;
    if (typeof value !== "number") return null;
  
    const real = (valuePure) =>
      currency(valuePure, { symbol: "R$ ", separator: ".", decimal: "," });
  
    return real(value).format();
  };