import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import listColumns from "./columns";
import Filters from "./components/filters";
import InfinityScrollList from "../../../../core/container/infinityScrollList";
import {
  getClientsList,
  getClientDetails,
  handleOpenPanel,
} from "../../../../core/redux/actions/clientsAction";

const ClientList = () => {
  const dispatch = useDispatch();
  const { clientList, hasMore, clientId, loadingList, panelIsOpen } = useSelector(
    (store) => store.clients
  );

  useEffect(() => {
    dispatch(getClientsList());
  }, [clientId]);

  const handleOpenEditPanel = (clientId) => {
    dispatch(handleOpenPanel(!panelIsOpen));
    dispatch(getClientDetails(clientId));
  };

  return (
    <>
      <Filters />
      <InfinityScrollList
        idList='clientList'
        loading={true}
        enableShimmer={true}
        newSignal={clientId}
        columns={listColumns(handleOpenEditPanel)}
        list={hasMore ? [...clientList, 0, 0, 0] : clientList}
        getMore={() => hasMore && !loadingList && dispatch(getClientsList())}
      />
    </>
  );
};

export default ClientList;
