import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Nav = styled.nav`
  background: #000;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  z-index: 10;
`;
export const NavLk = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    color: #15cdfc;
  }
`;
export const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  margin-right: -24px;
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

export const NavBtnLink = styled.nav`
  border-radius: 4px;
  background: #256ce1;
  padding: 10px 22px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

export const Bar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  width: 100%;
  border-bottom: 1px solid grey;
`;

export const horizontalGapStackTokens = {
  childrenGap: 25,
  padding: "10px 0",
};