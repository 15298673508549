import React, { useEffect, useState } from "react";
import Chat from "./containers/chat";
import Groups from "./containers/groups";
import { Container } from "./style";

export default function Community() {
  const [chat, setChat] = useState({name: ''});

  return (
    <Container>
      <Groups chatName={chat?.name} setChat={setChat} />
      <Chat chat={chat} />
    </Container>
  );
}
