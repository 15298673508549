import {
  requestCupomsList,
  requestUpdateCupom,
  requestCreateCupom,
} from "../../services/cupom";

/**
 * @description Search cupom by id in the list
 * @param {string} id
 */
export const getCupomById = (id) => async (dispatch, store) => {
  if (!id) return;

  try {
    if (!store().cupom.list) throw(new Error("List de cupons não encontrada"));    
    const data = store().cupom.list.find((item) => item.couponId === id);
    if (!data) throw(new Error("Cupom não encontrado"));
  
    dispatch({ type: "GET_CUPOM_BY_ID_SUCCESS" , payload: data });
  } catch (error) {
    dispatch({
      type: "GET_CUPOM_BY_ID_FAIL",
      payload: error,
    });
  }
};

/**
 * @description Search cupom by id in the list
 */
export const getCupomsList = () => async (dispatch, store) => {
  if (store().cupom.listLoading) return;

  dispatch({ type: "CUPOM_LIST_LOADING" });

  try {
    const response = await requestCupomsList(store().cupom.filters);
    dispatch({
      type: "CUPOM_LIST_SUCCESS",
      payload: response || [],
    });
  } catch (e) {
    console.error("Ocorreu um erro ao carregar a lista de cupons", e);
    dispatch({ type: "CUPOM_LIST_ERROR", payload: false });
  }
};

/**
 * @description set cupom painel is open
 * @param {boolean} isOpen
 */
export const setPainelIsOpen = (isOpen) => ({
  type: "CUPOM_PAINEL_IS_OPEN",
  payload: isOpen,
});

/**
 * @description update cupom filters values
 * @param {string} filterName
 */
export const updateFilter = (filterName, value) => {
  const newValue = {};
  newValue[filterName] = value;

  return {
    type: "CUPOM_UPDATE_FILTER",
    payload: newValue,
  };
};

export const updateCupomValue = (key, value) => ({
  type: "UPDATE_CUPOM_VALUE",
  payload: { key, value },
});

export const resetError = () => ({
  type: "CUPOM_UPDATE_ERROR",
  payload: '',
});

export const resetSuccess = () => ({
  type: "CUPOM_UPDATE_MESSAGE",
  payload: '',
});

export const syncCupomValues = () => async (dispatch, store) => {
  const { cupom } = store().cupom;

  dispatch({ type: "CUPOM_UPDATE_LOADING" });

  try {
    if (cupom.couponId) {
      await requestUpdateCupom(cupom);
      dispatch(getCupomsList(false));
    } else {
      await requestCreateCupom(cupom);
      dispatch(getCupomsList(false));
      dispatch(setPainelIsOpen(false));
    }

    dispatch({ type: "CUPOM_UPDATE_SUCCESS" });
  } catch (error) {
    const message = error?.response?.data?.errors?.Messages?.[0];
    dispatch({
      type: "CUPOM_UPDATE_ERROR",
      payload: message || 'Error ao sincronizar o cupom'
    });
  }
};
