import { ComboBox, DefaultButton } from "@fluentui/react";
import styled from "styled-components";

export const Container = styled.div`
`;

export const SectionTitle = styled.span`
  font-size: 26px;
  font-weight: 500;
  border-bottom: solid 1px #e9dada;
  padding-bottom: 5px;
`;

export const Section = styled.div`
  border-radius: 2px;
  margin-bottom: 30px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 22px;
`;

export const ActionButton = styled.div`
  align-self: flex-end;
  margin-top: 22px;
`;

export const Message = styled.div`
  margin-top: -22px;
`;

export const StatusBadge = styled.span`
  padding: 4px 12px;
  border-radius: 2px;
  font-weight: 500;
  min-width: 100%;
  color: ${({ statusTxt }) => {
    if (statusTxt === "active" || statusTxt === "paid") return `#107c10`;
    if (statusTxt === "failed") return "#a51b03";
    if (statusTxt === "canceled") return "#107c10";
    if (statusTxt === "inactive") return "#a51b03";
    return "#484d03";
  }};
  background-color: ${({ statusTxt }) => {
    if (statusTxt === "active" || statusTxt === "paid") return `#5fe2537a`;
    if (statusTxt === "failed") return "#ee5a6642";
    if (statusTxt === "canceled") return "#5fe2537a";
    if (statusTxt === "inactive") return "#ee5a6642";
    return "#fff48c";
  }};
`;
