import { DefaultButton } from '@fluentui/react';
import styled from 'styled-components';

export const AddButton = styled(DefaultButton)`
  width: fit-content;
`;

export const PanelStyles = { root: { padding: "400" } };
export const footerStyles = { display: "flex", gap: "10px" };

export const Message = styled.div`
  margin-top: 14px;
`;
