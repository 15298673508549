import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DefaultButton } from "../../../signals/components/sidePanelSignals/style";
import { panelStyles, Container } from "./style";
import {
  ComboBox,
  DatePicker,
  Dropdown,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from "@fluentui/react";
import {
  resetError,
  resetSuccess,
  setPainelIsOpen,
  syncCupomValues,
  updateCupomValue,
} from "../../../../core/redux/actions/cupomsActions";

const plans = [
  // {
  //   key: "0B177A50-C53A-4738-9D20-90252FE79A5C",
  //   text: "ICI - Anual à vista",
  // },
  // {
  //   key: "C499EDA4-9B7C-4A6D-8C16-FC3A24DEF508",
  //   text: "ICI - Anual Parcelado",
  // },
  // {
  //   key: "E32A04B5-53E4-43E0-9528-394881EE5CA0",
  //   text: "ICI - Mensal",
  // },
  // {
  //   key: "680B3C6D-DC67-4E1E-A014-FB5C210468AB",
  //   text: "TCI - Anual à vista",
  // },
  // {
  //   key: "682809BF-B9B2-4625-AD9D-E3B139E27C70",
  //   text: "TCI - Anual Parcelado",
  // },
  // {
  //   key: "71DB5B28-E5F9-46C4-A35C-8E61091267BD",
  //   text: "TCI - Mensal",
  // },
  {
    key: "DD427B69-DCA9-481F-BA98-E7F05A5BA93D",
    text: "Crypto Investidor PRO - Anual à vista",
  },
  {
    key: "E1C3E3F2-7889-4A43-98E3-8910D6AE094A",
    text: "Crypto Investidor PRO - Anual parcelado",
  },
];

const CupomPanel = () => {
  const dispatch = useDispatch();
  const [planIds, setPlanIds] = useState([]);
  const { openPanel, cupom, cupomLoading, cupomError, successMessage } =
    useSelector((state) => state.cupom);

  const [fieldError, setFieldError] = useState({});

  const validateFields = (field, value) => {
    switch (field) {
      case "name":
        if (value.length < 3) {
          setFieldError({
            ...fieldError,
            name: "O nome do cupom deve ter no mínimo 3 caracteres",
          });
        } else {
          setFieldError({ ...fieldError, name: "" });
        }
        dispatch(updateCupomValue(field, value));
        break;
      case "percentage":
        if (value < 0 || value > 100) {
          setFieldError({
            ...fieldError,
            percentage: "A porcentagem deve ser entre 0 e 100",
          });
        } else if (isNaN(value)) {
          setFieldError({
            ...fieldError,
            percentage: "A porcentagem deve ser um número",
          });
        } else {
          setFieldError({ ...fieldError, percentage: "" });
          dispatch(updateCupomValue(field, value));
        }
        break;
      case "value":
        if (parseFloat(value) && isNaN(parseFloat(value))) {
          setFieldError({
            ...fieldError,
            percentage: "A desconto deve ser um número",
          });
        } else {
          setFieldError({ ...fieldError, value: "" });
          dispatch(updateCupomValue(field, value));
        }
        break;
      case "validUntil":
        if (value < new Date()) {
          setFieldError({
            ...fieldError,
            validUntil: "A data deve ser maior que a data atual",
          });
        } else {
          setFieldError({ ...fieldError, validUntil: "" });
          dispatch(updateCupomValue(field, value));
        }
        break;
      default:
        break;
    }
  };

  const validValue = () => {
    if (cupom?.type === 0 && !cupom?.percentage){
      setFieldError({
        ...fieldError,
        percentage: "A porcentagem deve ser um número",
      });
      return false
    }
    if (cupom?.type === 1 && !cupom?.value) {
      setFieldError({
        ...fieldError,
        value: "Insira um valor",
      });
      return false
    }
    return true
  }

  const dispatchForm = () => {
    if (fieldError.name || fieldError.percentage || fieldError.validUntil) {
      return;
    }

    if (cupom?.type === 1) validateFields("percentage", null);

    if (!cupom?.couponId) {
      dispatch(updateCupomValue("planIds", planIds));
    }

    if (validValue()) {
      dispatch(syncCupomValues());
    }
  };

  useEffect(() => {
    if (cupomError) {
      const timer = setTimeout(() => {
        dispatch(resetError());
      }, 4500);

      return () => clearTimeout(timer);
    }
  }, [cupomError]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        dispatch(resetSuccess());
      }, 4500);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handlePlanSelect = (option) => {
    if (cupom?.couponId) {
      dispatch(updateCupomValue("planId", option.key));
    } else {
      let plans = [];

      if (!option?.selected) {
        plans = planIds.filter((id) => id !== option.key);
      } else {
        plans.push(...planIds, option.key);
      }

      setPlanIds(plans);
    }
  };

  useEffect(() => {
    if (cupom?.couponId) {
      if (cupom?.value) dispatch(updateCupomValue("type", 1))
    }
  }, [cupom?.couponId])

  return (
    <Panel
      isOpen={openPanel}
      onDismiss={() => dispatch(setPainelIsOpen(false))}
      headerText='Cupom'
      closeButtonAriaLabel='Close'
      type={PanelType.smallFixedFar}
      isFooterAtBottom={true}
      styles={panelStyles}
    >
      <Container>
        <TextField
          label='Código'
          placeholder='Código do cupom'
          value={cupom?.name}
          onChange={(e) => validateFields("name", e.target.value)}
        />
        {fieldError.name && (
          <span style={{ color: "red" }}>{fieldError.name}</span>
        )}
        {cupom?.type === 0 ? (
          <TextField
            label='Porcentagem'
            placeholder='Porcentagem do cupom'
            value={cupom?.percentage}
            onChange={(e) => validateFields("percentage", e.target.value)}
          />
        ) : (
          <TextField
            label='Valor desconto'
            placeholder='Valor do desconto fixo'
            value={cupom?.value}
            type='number'
            onChange={(e) => validateFields("value", e.target.value)}
          />
        )}
        {fieldError.percentage && (
          <span style={{ color: "red" }}>{fieldError.percentage}</span>
        )}
            {fieldError.value && (
          <span style={{ color: "red" }}>{fieldError.value}</span>
        )}
        <DatePicker
          label='Válido até'
          placeholder='Selecione uma data...'
          value={cupom?.validUntil ? new Date(cupom?.validUntil) : null}
          showMonthPickerAsOverlay={true}
          onSelectDate={(date) => validateFields("validUntil", date)}
          formatDate={(date) => moment(date).format("DD/MM/YYYY")}
        />
        <ComboBox
          label='Tipo de cupom'
          placeholder='Selecione o tipo de cupom'
          options={[
            { key: 0, text: "Porcentagem" },
            { key: 1, text: "Valor fixo" },
          ]}
          selectedKey={cupom?.type}
          onChange={(e, option) =>
            dispatch(updateCupomValue("type", option.key))
          }
        />
        <ComboBox
          label='Plano'
          placeholder='Selecione um plano'
          options={plans}
          onChange={(e, option) => handlePlanSelect(option)}
          multiSelect={cupom?.couponId ? false : true}
          selectedKey={cupom?.planId?.toUpperCase()}
        />
        <Toggle
          label='Ativo'
          onText='Sim'
          offText='Não'
          checked={cupom?.active}
          onChange={(e, checked) =>
            dispatch(updateCupomValue("active", checked))
          }
        />

        <Stack horizontal horizontalAlign='end'>
          <Stack.Item align='end'>
            <DefaultButton
              text='Cancelar'
              onClick={() => dispatch(setPainelIsOpen(false))}
            />
          </Stack.Item>
          <Stack.Item align='end'>
            <PrimaryButton
              text={cupomLoading ? "Carregando..." : "Salvar"}
              onClick={dispatchForm}
              disabled={
                cupomLoading || fieldError.name || fieldError.percentage
              }
            />
          </Stack.Item>
        </Stack>
        {cupomError && (
          <MessageBar messageBarType={MessageBarType.error}>
            {cupomError}
          </MessageBar>
        )}
        {successMessage && (
          <MessageBar messageBarType={MessageBarType.success}>
            {successMessage}
          </MessageBar>
        )}
      </Container>
    </Panel>
  );
};

export default CupomPanel;
