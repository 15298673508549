import { coinApi, coinApiWallet } from "../constants/api";
import axiosPrivate from "../utils/api/privateApi";

export const requestCoinList = (type = 0) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .get(`${coinApi}/${type}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestCoinListWallet = () =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .get(`${coinApiWallet}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });

export const requestCoinDetails = (id) =>
  new Promise((resolve, reject) => {
    axiosPrivate
      .get(`/admin/Wallet/coin/${id}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
