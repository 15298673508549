import { DefaultButton, Stack } from "@fluentui/react";
import moment from "moment";
import {
  CoinsContainer,
  CoinImage,
  ImageContainer,
  NumberOfCoins,
} from "./style";

// Frequency
const frequency = (frequency) => {
  switch (frequency) {
    case 0:
      return "Operações diárias";
    case 1:
      return "Operações semanais";
    case 2:
      return "Operações mensais";
    case 3:
      return "Operações anuais";
    default:
      return "";
  }
};

// Frequency
const walletType = (type) => {
  switch (type) {
    case 0:
      return "Hold";
    case 1:
      return "Moderado";
    case 2:
      return "Arriscado";
    default:
      return "";
  }
};

// Frequency
const renderCoins = (type) => {
  switch (type) {
    case 0:
      return "Hold";
    case 1:
      return "Moderado";
    case 2:
      return "Arriscado";
    default:
      return "";
  }
};

const removeDuplicates = (arr) => {
  const uniqueIds = [];

  const unique = arr.filter(element => {
    const isDuplicate = uniqueIds.includes(element.coinDto.id);
    if (!isDuplicate) {

      uniqueIds.push(element.coinDto.id);

      return true;
    }

    return false;
  });

  return unique
}

export default (onEdit) => [
  {
    key: "column1",
    name: "Nome",
    fieldName: "name",
    minWidth: 180,
    maxWidth: 300,
    styles: "styleColumn",
    isResizable: true,
  },
  {
    key: "column2",
    name: "Frequência",
    fieldName: "frequency",
    minWidth: 180,
    maxWidth: 300,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => frequency(item?.frequency),
  },
  {
    key: "column3",
    name: "Tipo",
    fieldName: "walletType",
    minWidth: 180,
    maxWidth: 180,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => walletType(item?.walletType),
  },
  {
    key: "column4",
    name: "Moedas",
    fieldName: "coins",
    minWidth: 180,
    maxWidth: 300,
    isResizable: true,
    styles: "styleColumn",
    onRender: (wallet) => (
      <CoinsContainer>
        {removeDuplicates(wallet?.items)?.length > 5
          ? removeDuplicates(wallet?.items)?.slice(0, 5).map((coin) => {
              return (
                <ImageContainer key={coin.id}>
                  <CoinImage src={coin?.coinDto?.url} />
                </ImageContainer>
              );
            })
          : removeDuplicates(wallet?.items)?.map((coin) => {
              return (
                <ImageContainer key={coin.id}>
                  <CoinImage src={coin?.coinDto?.url} />
                </ImageContainer>
              );
            })}
        {removeDuplicates(wallet?.items)?.length > 5 && (
          <NumberOfCoins>
            <span>+{wallet?.items?.length - 5}</span>
          </NumberOfCoins>
        )}
      </CoinsContainer>
    ),
  },
  {
    key: "column5",
    name: "Criado em",
    fieldName: "createdAt",
    minWidth: 180,
    maxWidth: 300,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) =>
      moment(item.createdAt).locale("pt-br").format("DD/MM/YYYY"),
  },
  {
    key: "column6",
    name: "Ultima movimentação",
    fieldName: "lastDateOperation",
    minWidth: 180,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) =>
      moment(item.lastDateOperation || item.updatedAt)
        .locale("pt-br")
        .format("DD/MM/YYYY"),
  },
  {
    key: "column7",
    name: "Ações",
    fieldName: "",
    minWidth: 100,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => (
      <Stack horizontal>
        <DefaultButton text='Editar' onClick={() => onEdit(item)} />
      </Stack>
    ),
  },
];
