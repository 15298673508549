import styled from 'styled-components';
import { FontIcon } from '@fluentui/react/lib/Icon';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  position: relative;
  width: 100%;
`;

export const HeaderLogo = styled.img`
  max-width: 100px;
  opacity: 0;
`;

export const Icon = styled(FontIcon)`
  cursor: pointer;
  position: relative;
  top: 1px;
  margin-right: 15px;
  font-size: 20px;
`;

export const UserContainer = styled.div``;

export const UserIcon = styled.button`
  cursor: pointer;
  border: solid 1px #f0e9e9;
  width: 30px;
  height: 30px;
  border-radius: 60%;
  background: #fff;
  padding: 2px 0 0 2px;
  font-size: 16px;
  transition: 0.1s ease;
  color: #000;

  &:hover {
    color: #312f2f;
  }
`;

export const UserInfo = styled.div`
  position: absolute;
  right: 12px;
  top: 90%;
  width: 260px;
  padding: 18px 0;
  padding-top: 60px;
  background: #fff;
  border: solid 1px rgb(243, 242, 241);
  box-shadow: -2px 2px 10px 4px rgba(235, 228, 228, 0.2);
  pointer-events: ${(props) => props.open ? 'unset' : 'none'};
  opacity: ${(props) => props.open ? '1' : '0'};
  transition: opacity 0.1s ease;
  z-index: 11;
`;

export const InfoIcon = styled.span`
  min-width: 55px;
  min-height: 55px;
  border: solid 1px #b4b4b1;
  color: #000;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60%;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 18px;
`;

export const InfoHeader = styled.img`
  position: absolute;
  top: 12px;
  left: 18px;
  max-width: 105px;
`;

export const User = styled.p`
  font-size: 18px;
`;

export const LogoutButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 18px;
  background: transparent;
  border: solid 1px transparent;
  transition: 0.1s ease;
  font-size: 14px;
  color: #302929;

  &:hover {
    cursor: pointer;
    background: rgb(243, 242, 241);
    border-color: rgb(243, 242, 241);
  }
`;

export const Menu = styled.div`
  border-top: solid 1px #f0ebeb;
  margin-top: 22px;
  margin-bottom: -18px;
`;

export const MenuAction = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 22px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: 0.2s ease;

  &:hover {
    background-color: #f8f3f3;
  }
`;