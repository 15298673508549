import { BrowserRouter, Routes, Route } from "react-router-dom";
import Autentication from "./AuthProvider";
import PageSkeleton from "./pageSkeleton";
import Login from "../../modules/login";
import Signals from "../../modules/signals";
import Client from "../../modules/client";
import Wallet from "../../modules/wallet";
import News from "../../modules/news";
import Tutorials from "../../modules/tutorials";
import PaymentStatement from "../../modules/paymentStatement";
import FullPageLoader from "../container/fullPageLoader";
import Cupoms from "../../modules/cupoms";
import Community from "../../modules/community";
import { useSelector } from "react-redux";
import AccessDenied from "../container/accessDenied";

function AppRouter() {
  const { adInfo, loadingAd } = useSelector((store) => store.auth);
  // Control routes
  const renderRoutes = () => {
    switch (adInfo?.jobTitle) {
      case "Técnico":
        return (
          <>
            <Route path='/' element={<Signals />} />
            <Route path='/comunidade' element={<Community />} />
            <Route path='/portfolio' element={<Wallet />} />
          </>
        );
      case "Administrativo":
        return (
          <>
            <Route path='/' element={<Client />} />
            <Route path='/cupons' element={<Cupoms />} />
          </>
        );
      case "Master":
        return (
          <>
            <Route path='/' element={<Signals />} />
            <Route path='/login' element={<Login />} />
            <Route path='/clientes' element={<Client />} />
            <Route path='/portfolio' element={<Wallet />} />
            <Route path='/tutorials' element={<Tutorials />} />
            <Route path='/news' element={<News />} />
            <Route path='/extratos' element={<PaymentStatement />} />
            <Route path='/cupons' element={<Cupoms />} />
            <Route path='/comunidade' element={<Community />} />
          </>
        );
      default:
        return <Route path='/' element={<AccessDenied />} />;
    }
  };

  return (
    <Autentication nonAuthenticated={<FullPageLoader />}>
      <BrowserRouter>
        <PageSkeleton>
          <Routes>{!loadingAd && renderRoutes()}</Routes>
        </PageSkeleton>
      </BrowserRouter>
    </Autentication>
  );
}

export default AppRouter;
