import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessageBar, MessageBarType } from "@fluentui/react";

import {
  getSignalsById,
  getSignalsList,
  getUpdateList,
  setPainelDetailsIsOpen,
  setPainelIsOpen,
} from "../../core/redux/actions/signalsAction";
import { getCoinsList } from "../../core/redux/actions/coinsActions";

import InfinityScrollList from "../../core/container/infinityScrollList";
import HeaderFilterList from "./components/headerFilterList";
import SidePanelSignals from "./components/sidePanelSignals";
import listColumns from "./listColumns";
import { horizontalGapStackTokens, Container } from "./style";

const SignalsView = () => {
  const dispatch = useDispatch();
  const {
    signalsList,
    hasMore,
    signalsListLoading,
    signalListError,
    openDetailPanel,
    signalId,
  } = useSelector((store) => store.signal);

  useEffect(() => {
    dispatch(getSignalsList());

    if (openDetailPanel) {
      dispatch(getCoinsList());
    }
  }, [signalId]);

  useEffect(() => {
    if (signalId) {
      dispatch(getUpdateList());
    }
  }, [signalId])

  const handleOpenEditPanel = (id) => {
    dispatch(setPainelIsOpen(true));
    dispatch(setPainelDetailsIsOpen(true));
    dispatch(getSignalsById(id));
  };

  return (
    <Container>
      {signalListError && (
        <MessageBar messageBarType={MessageBarType.error} onDismiss={() => null}>
          OCORREU UM ERRO AO CHAMAR A LISTA DE SINAIS
        </MessageBar>
      )}
      <HeaderFilterList />
      <InfinityScrollList
        idList="signalsList"
        loading={true}
        enableShimmer={true}
        newSignal={signalId}
        columns={listColumns(handleOpenEditPanel)}
        list={hasMore ? [...signalsList, 0, 0, 0] : signalsList}
        getMore={() => hasMore && !signalsListLoading && dispatch(getSignalsList())}
      />
      <SidePanelSignals />
    </Container>
  );
};

export default SignalsView;
