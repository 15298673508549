import { DefaultButton, PrimaryButton } from '@fluentui/react';
import styled from 'styled-components';

export const AddButton = styled(DefaultButton)`
  width: fit-content;
`;

export const PanelStyles = { root: { padding: "400" } };
export const footerStyles = { display: "flex", gap: "10px" };

export const FiltersSection = styled.div`
  width: 100%;
  background-color: #faf6f6ee;
  padding: 12px 18px;
  border-radius: 2px;
  margin-bottom: 20px;
`;

export const ButtonStyles = styled(PrimaryButton)`
  min-width: 150px;
  height: 38px;
  justify-content: space-between;
  padding: 8px;

  span {
    color: ${(props) => props.disabled ? '#000' : "#fff"};
  }
`;

export const Header = styled.div`
  border-bottom: solid 1px #e9dada;
  padding-bottom: 4px;
  margin-top: 6px;
`;

export const FrequencyContainer = styled(FiltersSection)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export const ComboBoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

export const LinkIcon = styled.div`
  z-index: 10;
  position: absolute;
  right: 0;
  top: 0;
`;

export const OperationTitle = styled.span`
  font-size: 14px;
  color: #322130;
  font-weight: 600;
  justify-self: flex-start;
`;

export const OperationTextArea = styled.div`
  width: 100%;
  height: 120px;
  border: solid 1px #2c2b2b;
  border-radius: 1.5px;
  margin-top: 12px;
  display: block;
  background: #fff;
  padding: 12px;

  a {
    color: #7373f3;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: relative;
  margin-top: 20px;
`;