import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMessages from "../../../../../../core/utils/hooks/useMessages";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FontIcon,
  Persona,
  PersonaSize,
  IconButton,
  Callout,
  DirectionalHint,
} from "@fluentui/react";
import reactStringReplace from "react-string-replace";
import { ChatImageModal } from "../imageModal";
import { useBoolean, useId } from "@fluentui/react-hooks";
import ReplyMessage from "../replyMessage";
import {
  banUser,
  deleteMessage,
  removeBan,
} from "../../../../../../core/redux/actions/communityActions";

import {
  Messages,
  MessageContainer,
  Message,
  Text,
  CreatedAt,
  NoMessages,
  MessageHeader,
  IncomingName,
  MessageContent,
  ChatImage,
  MessageAlign,
  Link,
  ImageActions,
  Options,
  OptionsCallout,
  OptionsContainer,
  Option,
  LoadingInfo,
  DeletedMessage,
  UndoDeleted,
} from "./style";
import LinkPreview from "../linkPreview";

const more = { iconName: "More" };

export default function ReadMessages({
  chatName,
  openImage,
  imageModal,
  hideImage,
  previewImages,
  handleReply,
  messagesEndRef,
}) {
  const dispatch = useDispatch();
  const { account } = useSelector((store) => store.auth);
  const { messages, getMoreMessages, loading, setMessages, shouldScroll } =
    useMessages(chatName);
  const { groups, loadingGroups } = useSelector((store) => store.community);
  const [showOptions, { toggle: toggleOptions }] = useBoolean(false);
  const [messageId, setMessageId] = useState(0);
  const buttonId = useId(`options-button`);
  const [processMessages, setProcessMessages] = useState(false);

  const handleToggleOptions = (id) => {
    setMessageId(id);
    toggleOptions();
  };

  const handleTextRendered = (text) => {
    if (!text) return "";

    let formatted = text;

    if (text.match(/<blockquote/)) {
      formatted = text.split("</blockquote>").pop();
    }

    const removeAllHtml = formatted.replace(/(<([^>]+)>)/gi, "");

    return removeAllHtml;
  };

  const renderLinksAndText = (text) => {
    if (!text) return "";
    let urlArr = [];

    const formattedText = handleTextRendered(text).replace(/\&nbsp;/g, " ");
    const tagsArray = text.match(/<[^>]+>[^<]*<\/[^>]+>/g); // match all html tags
    const regex = /<a.*?href=['"](.*?)['"]/;

    tagsArray?.map((tag) => {
      let obj = { url: regex.exec(tag)?.[1], text: handleTextRendered(tag) };
      urlArr.push(obj);
    });

    let replacedText = formattedText;
    let key = 1;

    if (urlArr.length > 0) {
      urlArr.forEach(
        (link, idx) =>
          (replacedText = reactStringReplace(
            replacedText,
            link.text,
            (match, i) => (
              <Link key={(key += 1)} href={link.url} target='_blank'>
                {match}
              </Link>
            )
          ))
      );
      return replacedText;
    } else {
      // Match URLs
      replacedText = reactStringReplace(
        formattedText,
        /(https?:\/\/\S+)/g,
        (match, i) => (
          <Link key={match + i} href={match} target='_blank'>
            {match}
          </Link>
        )
      );
      return replacedText;
    }
  };

  const handleDelete = (isDeleted, id) => {
    const message = messages?.filter((msg, idx) => idx === messageId);
    const msgKey = id || message?.[0]?.key;

    if (showOptions) {
      toggleOptions();
    }

    setMessages(
      messages.map((msg) =>
        msg.key === msgKey ? { ...msg, deleted: isDeleted } : msg
      )
    );

    dispatch(deleteMessage(chatName, msgKey, isDeleted));
  };

  const handleBan = (isBanned) => {
    const message = messages?.filter((msg, idx) => idx === messageId);
    const userId = message?.[0]?.id;
    const msgKey = message?.[0]?.key;

    if (showOptions) {
      toggleOptions();
    }

    setMessages(
      messages.map((msg) =>
        msg.key === msgKey ? { ...msg, deleted: isBanned } : msg
      )
    );
    if (isBanned) {
      dispatch(deleteMessage(chatName, msgKey, isBanned, isBanned));
      dispatch(banUser(userId, message?.[0]?.email));
    } else {
      dispatch(deleteMessage(chatName, msgKey, isBanned, isBanned));
      dispatch(removeBan(userId));
    }
  };

  const isDeletedOrBanned = () => {
    const message = messages?.filter((msg, idx) => idx === messageId);

    return message?.[0]?.banned || message?.[0]?.deleted;
  };

  const isBanned = () => {
    const message = messages?.filter((msg, idx) => idx === messageId);

    return message?.[0]?.banned;
  };

  const getMessage = () => {
    const message = messages?.filter((msg, idx) => idx === messageId);
    handleReply(message?.[0]);
  };

  const getMessagedId = () => {
    const message = messages?.filter((msg, idx) => idx === messageId);
    return message?.[0]?.id;
  };

  const asyncTime = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const triggerProcess = async () => {
    setProcessMessages(true);
    await asyncTime(600);
    setProcessMessages(false);
  };

  useEffect(() => {
    triggerProcess();
  }, [chatName]);

  useEffect(() => {
    if (shouldScroll) {
      setTimeout(() => {
        messagesEndRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 50);
    }
  }, [messages]);

  return (
    <MessageContainer
      id='scrollableDiv'
      style={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column-reverse",
        overflowX: "hidden",
        overflowY: processMessages ? "hidden" : "auto",
      }}
    >
      {loading ||
        (processMessages && (
          <LoadingInfo>
            <span>Carregando...</span>
          </LoadingInfo>
        ))}
      <div ref={messagesEndRef} />
      {showOptions && (
        <OptionsCallout
          directionalHint={DirectionalHint.topAutoEdge}
          target={`#${buttonId}`}
          onDismiss={toggleOptions}
        >
          <OptionsContainer>
            {!isDeletedOrBanned() && (
              <Option onClick={() => getMessage()}>
                <FontIcon aria-label='Reply' iconName='Reply' /> Responder
              </Option>
            )}
            {isDeletedOrBanned() && !isBanned() ? (
              <Option onClick={() => handleDelete(false)}>
                <FontIcon aria-label='Delete' iconName='Delete' /> Desfazer
              </Option>
            ) : (
              !isBanned() && (
                <Option onClick={() => handleDelete(true)}>
                  <FontIcon aria-label='Delete' iconName='Delete' /> Excluir
                </Option>
              )
            )}
            {getMessagedId() !== account.localAccountId &&
              (isBanned() ? (
                <Option onClick={() => handleBan(false)}>
                  <FontIcon aria-label='Cancel' iconName='Cancel' /> Desbanir
                  usuário
                </Option>
              ) : (
                <Option onClick={() => handleBan(true)}>
                  <FontIcon aria-label='Cancel' iconName='Cancel' /> Banir
                  usuário
                </Option>
              ))}
          </OptionsContainer>
        </OptionsCallout>
      )}
      <ChatImageModal
        isModalOpen={openImage}
        hideModal={hideImage}
        image={imageModal}
      />
      {!processMessages && !loadingGroups && groups.length === 0 && (
        <NoMessages>
          <FontIcon
            aria-label='CommentAdd'
            iconName='CommentAdd'
            style={{ fontSize: "64px", color: "#252424" }}
          />
          <span>Nenhum grupo criado</span>
          <p>Crie um grupo para receber e enviar mensagens.</p>
        </NoMessages>
      )}
      {!processMessages && !chatName && (
        <NoMessages>
          <FontIcon
            aria-label='CannedChat'
            iconName='CannedChat'
            style={{ fontSize: "64px", color: "#252424" }}
          />
          <span>Nenhum grupo selecionado</span>
          <p>Selecione um grupo no painel ao lado.</p>
        </NoMessages>
      )}
      {messages?.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={messages?.length} //This is important field to render the next data
            next={getMoreMessages}
            hasMore={loading ? false : true}
            inverse
            style={{ display: "flex", flexDirection: "column" }}
            loader={null}
            scrollableTarget='scrollableDiv'
          >
            <Messages style={{ opacity: processMessages ? 0 : 1 }}>
              {messages?.map((message, idx) => (
                <MessageAlign
                  key={message.key}
                  outgoing={message.id === account.localAccountId}
                >
                  <MessageContent
                    outgoing={message.id === account.localAccountId}
                  >
                    {message.id !== account.localAccountId && (
                      <Persona
                        text={message?.name?.split(" ").slice(0, 2).join(" ")}
                        size={PersonaSize.size40}
                        hidePersonaDetails
                      />
                    )}
                    <Message outgoing={message.id === account.localAccountId}>
                      <MessageHeader>
                        <div>
                          {message.id !== account.localAccountId && (
                            <IncomingName>
                              {message?.name}{" "}
                              {message?.admin && (
                                <span className='admin'>(Admin)</span>
                              )}
                              {message?.banned && (
                                <span className='ban'>(Banido)</span>
                              )}
                              <span style={{ color: "#DB6714"}}>- {message?.email}</span>
                            </IncomingName>
                          )}
                          <CreatedAt>
                            {moment(message?.created?.toDate()).calendar()}
                          </CreatedAt>
                        </div>

                        <Options className='more-options'>
                          <IconButton
                            iconProps={more}
                            title='Opções'
                            ariaLabel='Opções'
                            onClick={() => handleToggleOptions(idx)}
                            id={messageId === idx ? buttonId : ""}
                          />
                        </Options>
                      </MessageHeader>
                      {message?.banned || message?.deleted ? (
                        <DeletedMessage>
                          Essa mensagem foi excluída.{" "}
                          {!message?.banned && (
                            <UndoDeleted
                              onClick={() => handleDelete(false, message?.key)}
                            >
                              <span>Desfazer</span>
                            </UndoDeleted>
                          )}
                        </DeletedMessage>
                      ) : (
                        <>
                          {message?.repliedMessage &&
                            message?.repliedMessage?.name && (
                              <ReplyMessage
                                reply={message?.repliedMessage}
                                previewImages={previewImages}
                                readMessages
                              />
                            )}
                          {message.text !== "" && (
                            <Text>{renderLinksAndText(message.text)}</Text>
                          )}
                          {message?.linkPreview?.map((preview, idx) => (
                            <div key={idx}>
                              <LinkPreview
                                received={message.id !== account.localAccountId}
                                preview={preview}
                              />
                            </div>
                          ))}
                          {message?.images?.length > 0 &&
                            message.images.map((img, idx) => (
                              <ImageActions
                                key={idx}
                                onClick={() => previewImages(img, true)}
                              >
                                <ChatImage src={img} />
                              </ImageActions>
                            ))}
                        </>
                      )}
                    </Message>
                  </MessageContent>
                </MessageAlign>
              ))}
            </Messages>
          </InfiniteScroll>
        </>
      )}
    </MessageContainer>
  );
}
