import styled from "styled-components";

export const Container = styled.div`
  margin: 6px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fffcfc;
  border-radius: 6px;
  border: solid 1px #ece7e7;
  overflow: hidden;
  cursor: pointer;
  height: 370px;
`;

export const LinkImageWeb = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 220px;
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
`;

export const Link = styled.p`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
`;

export const Description = styled.p`
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
  margin-top: 12px;
`;

export const LinkContent = styled.div`
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  height: 140px;
  justify-content: space-between;
`;

export const LinkDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HiddenContent = styled.p`
  opacity: 0;
  top: 0;
  pointer-events: none;
`;
