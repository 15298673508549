import { maskCurrency } from "./masks";

/**
 * Formatar moeda
 */
export const currencyMask = (value) => {
  const onlyDigits = value
    .split("")
    .filter((s) => /\d/.test(s))
    .join("")
    .padStart(3, "0");
  const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
  return maskCurrency(digitsFloat);
};

export const formatValue = (value, quantity) => {
  if (value === null || value === undefined) return null;
  let totalValue = quantity ? value * quantity : value * 1;
  return currencyMask(totalValue.toString());
};

export const convertExponential = (number) => {
  if (!number) return "0";

  var sign = +number < 0 ? "-" : "",
    toStr = number.toString();
  if (!/e/i.test(toStr)) {
    return number;
  }
  var [lead, decimal, pow] = number
    .toString()
    .replace(/^-/, "")
    .replace(/^([0-9]+)(e.*)/, "$1.$2")
    .split(/e|\./);
  return +pow < 0
    ? sign +
        "0." +
        "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
};
