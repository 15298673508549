import React, { useEffect, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import CreateGroup from "./components/createGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroup,
  getGroups,
} from "../../../../core/redux/actions/communityActions";
import EditGroup from "./components/editGroup";

import { Shimmer, ShimmerElementType, TooltipHost } from "@fluentui/react";
import GroupSelector from "./components/groupSelector";

import {
  Container,
  Header,
  HeaderTitle,
  GroupsContainer,
  Group,
  GrupoName,
  CreateButton,
  ActionButtons,
  GroupAction,
  GroupsTitle,
  UnreadDot,
  GroupIcon,
} from "./style";

const addGroup = { iconName: "CommentAdd" };
const edit = { iconName: "Edit" };

export default function Groups({ setChat, chatName }) {
  const [hideDialogCreate, { toggle: toggleHideDialogCreate }] =
    useBoolean(true);
  const [hideDialogEdit, { toggle: toggleHideDialogEdit }] = useBoolean(true);
  const [group, setGroup] = useState(null);
  const { groups, loadingGroups } = useSelector((store) => store.community);
  const adminGroups = groups?.filter((group) => group.onlyAdmin);
  const openGroups = groups?.filter((group) => !group.onlyAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroups());
  }, []);

  const getGroup = (value) => {
    setGroup(value);
    toggleHideDialogEdit();
  };

  return (
    <>
      <CreateGroup
        hideDialog={hideDialogCreate}
        toggleHideDialog={toggleHideDialogCreate}
      />
      <EditGroup
        hideDialog={hideDialogEdit}
        group={group}
        toggleHideDialog={toggleHideDialogEdit}
      />
      <Container>
        <Header>
          <HeaderTitle>Chat</HeaderTitle>
          <TooltipHost content='Criar grupo'>
            <CreateButton
              iconProps={addGroup}
              onClick={toggleHideDialogCreate}
            />
          </TooltipHost>
        </Header>
        <GroupsContainer>
          {loadingGroups ? (
            [...Array(5)].map((_, i) => {
              return (
                <Shimmer
                  style={{ padding: "12px 12px 0 12px", marginBottom: 4 }}
                  key={i}
                  shimmerElements={[
                    { type: ShimmerElementType.line, width: 400, height: 40 },
                  ]}
                />
              );
            })
          ) : (
            <>
              {openGroups?.length > 0 && (
                <GroupsTitle>Grupos abertos</GroupsTitle>
              )}
              {openGroups &&
                openGroups.map((group, idx) => (
                  <div key={idx}>
                    <GroupSelector
                      setChat={() => setChat(group)}
                      chatName={chatName}
                      selected={chatName === group?.name}
                      admin={group.onlyAdmin}
                      groupName={group?.name}
                      getGroup={() => getGroup(group)}
                    />
                  </div>
                ))}

              {adminGroups?.length > 0 && (
                <GroupsTitle>Grupos fechados</GroupsTitle>
              )}
              {adminGroups &&
                adminGroups.map((group, idx) => (
                  <Group
                    key={idx}
                    onClick={() => setChat(group)}
                    selected={chatName === group?.name}
                  >
                    <GrupoName selected={chatName === group?.name}>
                      <GroupIcon
                        aria-label='Closed'
                        iconName={
                          group.onlyAdmin ? "SecurityGroup" : "ReminderGroup"
                        }
                        selected={chatName === group?.name}
                      />
                      {group?.name}
                    </GrupoName>
                    <ActionButtons
                      className='actions'
                      selected={chatName === group?.name}
                    >
                      <GroupAction
                        iconProps={edit}
                        onClick={() => getGroup(group)}
                        selected={chatName === group?.name}
                      />
                    </ActionButtons>
                  </Group>
                ))}
            </>
          )}
        </GroupsContainer>
      </Container>
    </>
  );
}
