import * as React from 'react';
import { useId } from '@fluentui/react-hooks';
import { IconButton, Modal } from '@fluentui/react';
import { Container, Graphic, CloseModal } from './style';

const close = { iconName: 'ChromeClose' };

export const SignalImageModal = ({ isModalOpen, hideModal, graphicValue }) => {
  const titleId = useId('title');

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      isBlocking={false}
    >
      {graphicValue && (
        <Container>
          <CloseModal>
            <IconButton
              onClick={() => hideModal()}
              iconProps={close}
              title='Close'
              ariaLabel='Close'
            />
          </CloseModal>
          <Graphic src={graphicValue} />
        </Container>
      )}
    </Modal>
  );
};
