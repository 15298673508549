import styled from "styled-components";

export const Container = styled.div`
  max-width: 980px;
  min-width: 980px;
  min-height: 562px;
  max-height: 562px;
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  justify-content: center;
`;

export const ChatImage = styled.img`
  max-width: 980px;
  max-height: 562px;
  object-fit: cover;
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
