import React, { useEffect, useRef, useState } from "react";
import SendMessage from "./components/sendMessage";
import ReadMessages from "./components/readMessages";
import { useBoolean } from "@fluentui/react-hooks";

import { Container, Header, HeaderTitle, Hashtag } from "./style";

export default function Chat({ chat }) {
  const [imageModal, setImageModal] = useState("");
  const [openImage, { setTrue: showImage, setFalse: hideImage }] = useBoolean(false);
  const [openReply, setOpenReply] = useState(false);
  const [replyMessage, setReplyMessage] = useState(null);
  const messagesEndRef = useRef(null);

  const handleReply = (message) => {
    const reply = {
      text: message?.text,
      name: message?.name,
      createdAt: message?.created,
      id: message?.id,
      images: message?.images || [],
      key: message?.key,
      linkPreview: message?.linkPreview || []
    }

    setReplyMessage(reply);
    setOpenReply(true);
  }

  const previewImages = (img, fromChat = false) => {
    if (!img) return;
  
    if (fromChat) {
      setImageModal(img);
    }
  
    if (img.href) {
      setImageModal(img.href);
    } else if (!fromChat) {
      setImageModal(URL.createObjectURL(img));
    }

    showImage();
  };

  const closeReply = () => {
    setOpenReply(false);
    setReplyMessage(null);
  }

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({
      block: "end",
    });
  };

  return (
    <Container>
      <Header>
        <Hashtag>#</Hashtag>
        <HeaderTitle>{chat.name}</HeaderTitle>
      </Header>
      <ReadMessages
        chatName={chat.name}
        openImage={openImage}
        imageModal={imageModal}
        hideImage={hideImage}
        previewImages={previewImages}
        handleReply={handleReply}
        messagesEndRef={messagesEndRef}
      />
      <SendMessage
        chat={chat}
        openImage={openImage}
        imageModal={imageModal}
        hideImage={hideImage}
        previewImages={previewImages}
        openReply={openReply}
        replyMessage={replyMessage}
        closeReply={closeReply}
        scrollToBottom={scrollToBottom}
      />
    </Container>
  );
}
