import * as React from 'react';
import { Stack } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { useDispatch } from 'react-redux';

export const CreateSignalNotification = ({ submitSignal, loading, disabled, created, finishSignal }) => {
  const buttonId = useId('targetButton');
  const dispatch = useDispatch();
  const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);

  const SecondaryButtonProps = React.useMemo(
    () => ({
      children: 'Não',
      onClick: toggleTeachingBubbleVisible,
    }),
    [toggleTeachingBubbleVisible],
  );

  const handleButtonText = () => {
    if (loading) return 'Enviando...';
    if (created) return 'Encerrar sinal';
    return 'Enviar sinal';
  }

  const handleCreateSignal = () => {
    if (created) {
      finishSignal();
    } else {
      submitSignal();
    }
  }

  return (
    <Stack>
      <DefaultButton
        id={buttonId}
        disabled={disabled}
        onClick={toggleTeachingBubbleVisible}
        loading={loading}
        text={handleButtonText()}
      />
      {teachingBubbleVisible && (
        <TeachingBubble
          target={`#${buttonId}`}
          primaryButtonProps={{
            children: 'Sim',
            onClick: () => {
              handleCreateSignal();
              toggleTeachingBubbleVisible();
            },
          }}
          secondaryButtonProps={SecondaryButtonProps}
          onDismiss={toggleTeachingBubbleVisible}
          headline="Tem certeza que deseja enviar sinal?"
        > 
        </TeachingBubble>
      )}
    </Stack>
  );
};

export default  CreateSignalNotification;

