import { ComboBox, DefaultButton } from "@fluentui/react";
import styled from "styled-components";

export const Container = styled.div``;

export const SectionTitle = styled.span`
  font-size: 26px;
  font-weight: 500;
  border-bottom: solid 1px #e9dada;
  padding-bottom: 5px;
`;

export const Section = styled.div`
  background-color: #faf6f6ee;
  padding: 12px 22px;
  border-radius: 2px;
  margin-bottom: 30px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 22px;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 22px;
  gap: 12px;
`;

export const Message = styled.div`
  margin-top: -22px;
`;

export const Select = styled(ComboBox)``;

export const SubStatus = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 12px;
  left: 18px;
`;

export const Status = styled.span`
  margin-left: 6px;
  font-weight: 500;
  font-size: 14px;
`;
