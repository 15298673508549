import { addDoc, collection } from "firebase/firestore";
import { db, firebase } from "../../constants/firebase";
import Signal from "../../entities/signal";
import {
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "@firebase/firestore";

import {sendMessagePushNotification } from '../../../core/services/community.js'

export const clearCreateErr = () => ({
  type: "CLEAR_ERRORS",
  payload: null,
});

export const requestMessages = (groupName) => async (dispatch, store) => {
  try {
    dispatch({ type: "GET_MESSAGES_START" });
    const groupRef = collection(db, "chat", groupName, "messages");
    const snapshot = await getDocs(groupRef);
    const messages = snapshot.docs.map((doc) => doc.data());

    dispatch({ type: "GET_MESSAGES_SUCCESS", payload: messages });
  } catch (e) {
    dispatch({
      type: "GET_MESSAGES_FAILURE",
      payload: "Ocorreu um erro ao buscar o chat",
    });
    console.log(e);
  }
};

// send message
export const sendMessage =
  (group, newMessage, user, replyMessage, images, scrollToBottom, linkPreview) =>
  async (dispatch, store) => {
    try {
      dispatch({ type: "SEND_MESSAGE_START" });
      const groupRef = collection(db, "chat", group.name, "messages");
      const lastMessageRef = doc(db, "chatLastMessages", group.name);
      const messageObj = {
        name: user?.name?.split(" ").slice(0, 2).join(" "),
        email: user?.username,
        text: newMessage,
        id: user?.localAccountId,
        repliedMessage: replyMessage || {},
        created: firebase.firestore.FieldValue.serverTimestamp(),
        images: images,
        deleted: false,
        banned: false,
        admin: true,
        linkPreview: linkPreview || []
      }
      
      await addDoc(groupRef, messageObj);

      await setDoc(lastMessageRef, {
        userId: user?.localAccountId,
        text: newMessage,
        created: messageObj?.created,
      });
  
      if (scrollToBottom) {
        scrollToBottom();
      }

      dispatch({ type: "SEND_MESSAGE_SUCCESS" });
      if(group.onlyAdmin) {
        await sendMessagePushNotification(group.name)
      }
    } catch (e) {
      dispatch({
        type: "SEND_MESSAGE_FAILURE",
        payload: "Ocorreu um erro ao enviar sua mensagem",
      });
      console.log(e);
    }
  };

// delete message
export const deleteMessage =
  (groupName, id, shouldDelete, isBanned) => async (dispatch, store) => {
    try {
      dispatch({ type: "DELETE_MESSAGE_START" });
      const groupRef = doc(db, "chat", groupName, "messages", id);

      await updateDoc(groupRef, {
        deleted: shouldDelete,
        banned: isBanned ?? false,
      });

      dispatch({ type: "DELETE_MESSAGE_SUCCESS" });
    } catch (e) {
      dispatch({
        type: "DELETE_MESSAGE_FAILURE",
        payload: "Ocorreu um erro ao enviar sua mensagem",
      });
      console.log(e);
    }
  };

// request groups
export const getGroups = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_GROUPS_START" });

    const chatDoc = collection(db, "chat");
    const chatSnap = await getDocs(chatDoc);
    let groupsArr = [];
    
    chatSnap.forEach((doc) => {
      const obj = {
        name: doc.id,
        ...doc.data(),
      };
      groupsArr.push(obj);
    });

    dispatch({ type: "GET_GROUPS_SUCCESS", payload: groupsArr });
  } catch (e) {
    dispatch({
      type: "GET_GROUPS_FAILURE",
      payload: "Ocorreu um erro buscar os grupos",
    });
    console.log(e);
  }
};

// update chat group
export const updateGroup =
  (groupName, onlyAdmin, toggleHideDialog, subRestriction) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_GROUP_START" });

      const groupRef = doc(db, "chat", groupName);

      await setDoc(groupRef, { onlyAdmin: onlyAdmin, subRestriction: subRestriction });
      dispatch(getGroups());
      toggleHideDialog();
      dispatch({ type: "UPDATE_GROUP_SUCCESS" });
    } catch (e) {
      dispatch({
        type: "UPDATE_GROUP_FAILURE",
      });
      console.log(e);
    }
  };

// delete chat group
export const deleteGroup =
  (groupName, toggleHideDialog) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_GROUP_START" });

      const groupRef = doc(db, "chat", groupName);

      await deleteDoc(groupRef);
      dispatch(getGroups());
      toggleHideDialog();
      dispatch({ type: "UPDATE_GROUP_SUCCESS" });
    } catch (e) {
      dispatch({
        type: "UPDATE_GROUP_FAILURE",
      });
      console.log(e);
    }
  };

// create chat group
export const createGroup =
  (groupName, onlyAdmin, toggleHideDialog, clearValues, setErrorMessage, subRestriction) =>
  async (dispatch) => {
    try {
      dispatch({ type: "CREATE_GROUP_START" });

      const checkDuplicate = doc(db, "chat", groupName);
      const snapshot = await getDoc(checkDuplicate);

      if (snapshot.exists()) {
        dispatch({
          type: "CREATE_GROUP_FAILURE",
          payload: "Esse grupo já existe",
        });
        return;
      } else {
        const groupRef = doc(db, "chat", groupName);
        await setDoc(groupRef, { onlyAdmin: onlyAdmin, subRestriction: subRestriction, created: firebase.firestore.FieldValue.serverTimestamp() });

        toggleHideDialog();
        dispatch(getGroups());
        clearValues();
        dispatch({ type: "CREATE_GROUP_SUCCESS" });
      }
    } catch (e) {
      dispatch({
        type: "CREATE_GROUP_FAILURE",
        payload: "Ocorreu um erro ao criar o grupo",
      });
      console.log(e);
    }
  };

// ban user
export const banUser = (id, email) => async (dispatch) => {
  try {
    dispatch({ type: "BAN_USER_START" });

    const banRef = doc(db, "bannedUsers", id);
    const snapshot = await getDoc(banRef);

    if (snapshot.exists()) {
      dispatch({
        type: "BAN_USER_FAILURE",
        payload: "Usuario ja esta banido",
      });
      return;
    }

    await setDoc(banRef, { email: email, userId: id, created: firebase.firestore.FieldValue.serverTimestamp(), });

    dispatch({ type: "BAN_USER_SUCCESS" });
  } catch (e) {
    dispatch({
      type: "BAN_USER_FAILURE",
      payload: "Ocorreu um erro ao criar o grupo",
    });
    console.log(e);
  }
};

// remove user ban
export const removeBan = (id) => async (dispatch, store) => {
  try {
    dispatch({ type: "BAN_USER_START" });
    const banRef = doc(db, "bannedUsers", id);

    await deleteDoc(banRef);

    dispatch({ type: "BAN_USER_SUCCESS" });
  } catch (e) {
    dispatch({
      type: "BAN_USER_FAILURE",
      payload: "Ocorreu um erro ao enviar sua mensagem",
    });
    console.log(e);
  }
};
