import moment from "moment";
import { formatPricePrecision } from "../../../../../../core/utils/formatPrice";
import { StatusBadge } from "./style";

const renderDate = (date) => {
  if (date) {
    return moment(date).locale("pt-br").format("DD/MM/YYYY");
  } else {
    return "";
  }
};

const renderPaymentMethod = (payment) => {
  switch (payment) {
    case "credit_card":
      return "Cartão de crédito";
    case "credit":
      return "Cartão de crédito";
    case "boleto":
      return "Boleto";
    case "debit_card":
      return "Cartão de Débito";
    case "pix":
      return "Pix";
    case "Pix":
        return "Pix";
    default:
      return payment;
  }
};

// Plan Names
const planNames = (planName) => {
  switch (planName) {
    case "TCI 1":
      return "Plano TCI - Anual à vista";
    case "TCI 2":
      return "Plano TCI - Anual parcelado";
    case "TCI 3":
      return "Plano TCI - Mensal";
    case "ICI 1":
      return "Plano ICI - Anual à vista";
    case "ICI 2":
      return "Plano ICI - Anual parcelado";
    case "ICI 3":
      return "Plano ICI - Mensal";
    default:
      return "";
  }
};

// Plan Names
const renderStatus = (status, extract) => {
  switch (status) {
    case "active":
      return "Pago";
    case "pending":
      return "Pendente";
    case "Pagamento Pendente":
      return "Pendente";
    case "Reembolsado":
      return "Estornado";
    case "refounded":
      return "Estornado";
    case "Ativo":
      return "Pago";
    case "Cancelado":
      return "Pago";
    case "Falha":
      return "Falha";
    case "Estornado":
      return "Estornado";
    case "Futuro":
      return "Ativação em " + moment(extract?.startAt).format("DD/MM/YYYY");
    case "paid":
      return "Pago";
    case "scheduled":
      return "Agendado";
    case "failed":
      return "Falha";
    case "canceled":
      return "Pago";
    case "future":
      return "Ativação em " + moment(extract?.startAt).format("DD/MM/YYYY");
    default:
      return status;
  }
};

const liquidValue = (val, discount) => {
  if (!val) return "-";
  if (isNaN(val)) return "-";
  if (discount > 0) {
    return formatPricePrecision(val - (discount / 100) * val);
  } else {
    return formatPricePrecision(val);
  }
};

const handlePrice = (price) => {
  if (!Boolean(price) || price === 0) return 0;

  const formatedPrice = price / 100;

  if (formatedPrice < 100) {
    return price;
  } else {
    return formatedPrice;
  }
};

export const columns = [
  {
    key: "column1",
    name: "Data",
    fieldName: "createdAt",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item) => renderDate(item?.createdAt),
  },
  {
    key: "column2",
    name: "Plano",
    fieldName: "planName",
    minWidth: 190,
    maxWidth: 220,
    isResizable: true,
    onRender: (item) => planNames(item?.name),
  },
  {
    key: "column3",
    name: "Valor",
    fieldName: "value",
    minWidth: 150,
    maxWidth: 120,
    isResizable: true,
    onRender: (item) =>
      formatPricePrecision(handlePrice(item?.price)),
  },
  {
    key: "column5",
    name: "Desconto",
    fieldName: "discount",
    minWidth: 160,
    maxWidth: 160,
    isResizable: true,
    onRender: (item) => <span>{item?.discount}%</span>,
  },
  {
    key: "column6",
    name: "Valor líquido",
    fieldName: "liquidValue",
    minWidth: 160,
    maxWidth: 160,
    isResizable: true,
    onRender: (item) => (
      <span>
        {liquidValue(
          handlePrice(item?.price),
          item?.discount
        )}
      </span>
    ),
  },
  {
    key: "column7",
    name: "Modo de pagamento",
    fieldName: "paymentMethod",
    minWidth: 170,
    isResizable: true,
    onRender: (item) => renderPaymentMethod(item?.paymentMethod),
  },
  {
    key: "column8",
    name: "Situação",
    fieldName: "status",
    minWidth: 85,
    isResizable: true,
    onRender: (item) => (
      <StatusBadge statusTxt={item?.status}>
        {renderStatus(item?.status, item)}
      </StatusBadge>
    ),
  },
];
