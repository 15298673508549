import styled from 'styled-components';

export const Container = styled.div`
  max-width: 960px;
  min-width: 960px;
  min-height: 562px;
  position: relative;
`;

export const Graphic = styled.img`
  max-width: 100%;
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

