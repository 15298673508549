import React from "react";
import Button from "../../../../../../core/ui/buttonFluent";
import {
  FocusZone,
  FocusZoneTabbableElements,
  DirectionalHint,
  TextField,
  DefaultButton,
} from "@fluentui/react";

import { CalloutContainer, CalloutTitle, Input, ButtonsContainer } from "./style";

export const LinkCallout = ({
  isCalloutVisible,
  toggleIsCalloutVisible,
  buttonId,
  handleLink,
  createLink,
  closeLinkCallout,
  validLinks,
  clearLinkErrors,
}) => {
  return (
    <>
      {isCalloutVisible && (
        <CalloutContainer
          directionalHint={DirectionalHint.topAutoEdge}
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          <CalloutTitle>Inserir Link</CalloutTitle>
          <Input>
            <TextField
              label='Texto a ser exibido*'
              placeholder='Texto a ser exibido'
              onFocus={() => clearLinkErrors("text")}
              errorMessage={validLinks.text}
              autoComplete="new-password"
              onChange={(e, val) => handleLink("text", val)}
            />
            <TextField
              label='Endereço*'
              placeholder='https://...'
              errorMessage={validLinks.href}
              onFocus={() => clearLinkErrors("href")}
              autoComplete="new-password"
              onChange={(e, val) => handleLink("href", val)}
            />
          </Input>

          <ButtonsContainer>
            <DefaultButton onClick={() => closeLinkCallout()}>
              Cancelar
            </DefaultButton>
            <Button text='Inserir' onClick={() => createLink()} />
          </ButtonsContainer>
        </CalloutContainer>
      )}
    </>
  );
};

export default LinkCallout;