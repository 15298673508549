import { Checkbox, IconButton } from "@fluentui/react";
import styled from "styled-components";

export const DialogContent = styled.div``;

export const Check = styled(Checkbox)`
  padding-bottom: 10px;
`; 

export const Error = styled.span`
  color: red;
`;

export const CloseIcon = styled(IconButton)`
  position: absolute;
  top: -55px;
  right: -16px;
`;

export const CheckContainer = styled.div`
  margin-top: 12px;
`;