import {
  ComboBox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  DatePicker,
  TextField,
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { isValidUrl } from "../../../../core/utils/validations";
import {
  getCoinDetails,
  getCoinsListWallet,
} from "../../../../core/redux/actions/coinsActions";
import {
  closeWallet,
  getProfitData,
  handleOpenPanel,
  postNewWallet,
  putWallet,
  resetErrorUpdates,
  updateWalletName,
  updateWalletTypes,
} from "../../../../core/redux/actions/walletActions";
import AddCoin from "./components/addCoin";
import WalletComposition from "./components/walletComposition";
import WalletData from "./components/walletData";
import moment from "moment";
import Button from "../../../../core/ui/buttonFluent";
import {
  AddButton,
  FiltersSection,
  PanelStyles,
  ButtonStyles,
  Header,
  FrequencyContainer,
  ComboBoxContainer,
  LinkIcon,
  OperationTextArea,
  LinkContainer,
  OperationTitle,
} from "./style";
import LinkCallout from "./components/linkCallout";
import { convertExponential } from "../../../../core/utils/formatValue";

const linkIcon = { iconName: "AddLink" };

const frequencyOptions = [
  { key: 0, text: "Operações diárias" },
  { key: 1, text: "Operações semanais" },
  { key: 2, text: "Operações mensais" },
  { key: 3, text: "Operações anuais" },
];

const typeOptions = [
  { key: 0, text: "Hold" },
  { key: 1, text: "Moderado" },
  { key: 2, text: "Arriscado" },
];

export default function SidePanel() {
  const dispatch = useDispatch();
  const {
    panelIsOpen,
    walletDetails,
    walletDetailsLoading,
    walletDetailsError,
    walletProfitLoading,
    loadingTypes,
    coinDetailError,
  } = useSelector((store) => store.wallet);
  const { coinList, coinsListLoading, loadingDetail } = useSelector(
    (store) => store.coins
  );

  const [showFinishPanel, setShowFinishPanel] = useState(false);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId("callout-button");
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [validLinks, setValidLinks] = useState({});
  const [link, setLink] = useState({});
  const [reasonOperation, setReasonOperation] = useState("");
  const [frequency, setFrequency] = useState(null);
  const [walletType, setWalletType] = useState(null);
  const [saveTypes, setSaveType] = useState(false);
  const [walletCoins, setWalletCoins] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [coinError, setCoinError] = useState(false);
  const [createdDate, setCreatedDate] = useState("");

  const getCompositionPercent = (onlyUsdt = false) => {
    if (!Array.isArray(walletCoins)) return 0;

    let sum = 0;
    walletCoins.forEach((walletItem) => {
      if (onlyUsdt) {
        if (
          walletItem.remove === false &&
          walletItem.weight &&
          walletItem?.coinDto?.name === "USDT"
        ) {
          sum += parseFloat(walletItem.weight);
        }
      }
      if (!onlyUsdt) {
        if (
          walletItem.remove === false &&
          walletItem.weight &&
          walletItem?.coinDto?.name !== "USDT"
        ) {
          sum += parseFloat(walletItem.weight);
        }
      }
    });

    return isNaN(sum) ? 0 : sum;
  };

  const getCompositionPercents = () => {
    if (!Array.isArray(walletCoins)) return 0;

    let sum = 0;
    walletCoins.forEach((walletItem) => {
      if (walletItem.remove === false && walletItem.weight) {
        sum += parseFloat(walletItem.weight);
      }
    });
    return isNaN(sum) ? 0 : sum;
  };

  const composition = getCompositionPercents();

  useEffect(() => {
    if (panelIsOpen) {
      dispatch(getCoinsListWallet(0));
      setName(walletDetails.name);
      setAbout(walletDetails.description);
      setWalletCoins(walletDetails.items);
      setFrequency(walletDetails?.frequency);
      setWalletType(walletDetails?.walletType);
      setCreatedDate(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
      dispatch(getProfitData());
    } else {
      setWalletCoins([]);
      setIsEdited(false);
      setFrequency(null);
      setWalletType(null);
    }
  }, [panelIsOpen]);

  useEffect(() => {
    if (!walletDetailsLoading && isEdited) {
      setIsEdited(false);
    }
  }, [walletDetailsLoading]);

  const getDetail = () => {};

  const onAddCoin = (newCoin) => {
    let isNewCoin = true;

    dispatch(
      getCoinDetails(
        newCoin.id,
        isNewCoin,
        setWalletCoins,
        walletCoins,
        setCoinError
      )
    );
  };

  const onChangeWallet = (item) => {
    const newList = walletCoins.map((coin) => {
      if (item.coinDto === coin.coinDto) return item;
      return coin;
    });

    if (walletDetails.items !== newList && walletDetails.id) {
      setIsEdited(true);
    }

    setWalletCoins(newList);
  };

  const checkCanCreate = () => {
    let error = "";
    setErrorMessage(null);

    if (!name) {
      error = "Preencha o campo nome da carteira.";
    } else if (!about) {
      error = "Preencha o campo de descrição da carteira.";
    } else if (walletCoins.length === 0) {
      error = "Insira pelo menos uma moeda para inicializar a carteira";
    } else if (frequency === null) {
      error = "Você deve selecionar uma frequência";
    } else if (walletType === null) {
      error = "Você deve selecionar um tipo";
    } else if (composition > 100 || composition < 1) {
      error =
        "A composição de moedas esta acima de 100%, para criar deve estar entre 1% e 100%";
    }

    if (error) {
      setErrorMessage(error);
      return false;
    }

    return true;
  };

  const createWallet = async () => {
    if (!checkCanCreate()) return;

    const walletObj = {
      name: name,
      description: about,
      frequency: 0,
      walletType: 1,
      items: walletCoins,
    };

    if (composition !== 100) {
      walletObj.items.push({
        coinDto: { name: "USDT", id: "A0F2E141-C383-4479-83F2-7CA41CD30D3F" },
        createdAt: moment().locale("pt-br").subtract(3, "hour"),
        lastUpdatedAt: moment().locale("pt-br").subtract(3, "hour"),
        newItem: true,
        weight: 100 - composition,
        remove: false,
        entryValue: 1,
      });
    }

    dispatch(postNewWallet(walletObj, setSuccessMessage, setWalletCoins));
  };

  const checkCanUpdate = () => {
    let error = "";
    setErrorMessage(null);

    if (walletCoins.length === 0) {
      error = "Insira pelo menos uma moeda para inicializar a carteira";
    } else if (composition !== 100) {
      error = "A composição de moedas deve ser 100%";
    }

    if (error) {
      setErrorMessage(error);
      return false;
    }

    return true;
  };

  const updateWallet = () => {
    if (!checkCanUpdate()) return;

    const walletObj = {
      walletId: walletDetails.id,
      reasonOperation: reasonOperation.replace(/&nbsp;/g, " "),
      createdAt: moment(createdDate).format(`YYYY-MM-DD${moment().format('THH:mm:ssZ')}`),
      items: walletCoins.map((itemCoin) => ({
        ...itemCoin,
        value: Number(itemCoin?.entryValue?.toString()?.replace(",", ".")),
        entryValue: Number(itemCoin?.entryValue?.toString()?.replace(",", ".")),
        currentWeight: parseFloat(itemCoin.weight),
      })),
    };

    if (composition !== 100) {
      walletObj.items.push({
        coinDto: { name: "USDT", id: "A0F2E141-C383-4479-83F2-7CA41CD30D3F" },
        lastUpdatedAt: moment().locale("pt-br").subtract(3, "hour"),
        newItem: true,
        currentWeight: 100 - composition,
        remove: false,
        value: 1,
      });
    }
    dispatch(
      putWallet(walletObj, setSuccessMessage, setWalletCoins, walletDetails?.id)
    );
    setReasonOperation("");
  };

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(false);
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    if (walletDetailsError) {
      const timer = setTimeout(() => {
        dispatch(resetErrorUpdates());
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [walletDetailsError]);

  useEffect(() => {
    if (coinError) {
      const timer = setTimeout(() => {
        setCoinError(false);
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [coinError]);

  const footerContet = (
    <>
      {successMessage && (
        <>
          <MessageBar messageBarType={MessageBarType.success}>
            Ação realizada com sucesso
          </MessageBar>
          <Separator />
        </>
      )}
      <FiltersSection>
        <DefaultButton
          text='Encerrar Carteira'
          onClick={() => setShowFinishPanel(true)}
        />
        <Dialog
          hidden={!showFinishPanel}
          onDismiss={() => setShowFinishPanel(false)}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Você tem certeza que deseja encerrar a carteira?",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 600 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => setShowFinishPanel(false)}
              text='Não'
            />
            <DefaultButton
              onClick={() => {
                dispatch(closeWallet());
                setShowFinishPanel(false);
              }}
              text='Sim'
            />
          </DialogFooter>
        </Dialog>
      </FiltersSection>
    </>
  );

  const handleLink = (id, value) => {
    setLink({
      ...link,
      [id]: value,
    });
  };

  const createLink = () => {
    const textarea = document.getElementById("descriptionOperation");

    let linkTag = document.createElement("A");
    linkTag.href = link.href;
    linkTag.textContent = link.text;
    linkTag.setAttribute("target", "_blank");
    linkTag.onclick = function () {
      window.open(link.href, "_blank").focus();
    };

    if (!isValidUrl(linkTag.href)) {
      setValidLinks({
        ...validLinks,
        href: "Endereço inválido",
      });

      return;
    }

    if (!link.text) {
      setValidLinks({
        ...validLinks,
        text: "Informe o texto",
      });

      return;
    }

    textarea.appendChild(linkTag);
    setReasonOperation(textarea?.innerHTML);
    toggleIsCalloutVisible();
  };

  const clearLinkErrors = (id) => {
    setValidLinks({
      ...validLinks,
      [id]: false,
    });
  };

  const closeLinkCallout = () => {
    setLink({});
    toggleIsCalloutVisible();
  };

  return (
    <>
      <Header>
        <AddButton
          text='Criar Carteira'
          onClick={() => dispatch(handleOpenPanel(true))}
          iconProps={{ iconName: "Add" }}
          style={{ marginBottom: 12 }}
        />
      </Header>
      <Panel
        isOpen={panelIsOpen}
        onDismiss={() => dispatch(handleOpenPanel(false))}
        headerText={"Portfólio"}
        closeButtonAriaLabel='Close'
        onRenderFooterContent={() => (walletDetails?.id ? footerContet : null)}
        isFooterAtBottom={true}
        headerClassName={{
          backgroundColor: "#fff",
          padding: "12px 0",
          marginTop: "-18px",
        }}
        type={PanelType.medium}
        styles={PanelStyles}
      >
        <FiltersSection>
          <WalletData
            edit={Boolean(walletDetails.id)}
            name={name}
            onChangeName={setName}
            about={about}
            onChangeAbout={setAbout}
            loading={walletDetailsLoading}
            onUpdate={() =>
              dispatch(updateWalletName(name, about, setSuccessMessage))
            }
          />
        </FiltersSection>
        <FrequencyContainer>
          <ComboBoxContainer>
            <ComboBox
              label='Frequência'
              value={frequency}
              selectedKey={frequency}
              options={frequencyOptions}
              onChange={(e, option) => {
                setFrequency(option.key);
                setSaveType(true);
              }}
            />
            <ComboBox
              label='Tipo'
              options={typeOptions}
              value={walletType}
              selectedKey={walletType}
              onChange={(e, option) => {
                setWalletType(option.key);
                setSaveType(true);
              }}
            />
          </ComboBoxContainer>
          {Boolean(walletDetails.id) && saveTypes && (
            <PrimaryButton
              text={loadingTypes ? "Atualizando..." : "Salvar"}
              onClick={() =>
                dispatch(
                  updateWalletTypes(
                    frequency,
                    walletType,
                    name,
                    about,
                    setSuccessMessage,
                    setSaveType
                  )
                )
              }
            />
          )}
        </FrequencyContainer>
        <FiltersSection>
          <AddCoin
            coinList={coinList}
            coinsListLoading={coinsListLoading}
            loading={loadingDetail}
            onAddCoin={onAddCoin}
            disabled={!walletDetails?.id}
          />
          <WalletComposition
            walletComposition={walletCoins}
            onChangeWallet={onChangeWallet}
          />
          {coinError && (
            <>
              <MessageBar messageBarType={MessageBarType.error}>
                A moeda selecionada não foi encontrada na base.
              </MessageBar>
              <Separator />
            </>
          )}
          {isEdited && (
            <>
              {(errorMessage || walletDetailsError) && (
                <>
                  <MessageBar messageBarType={MessageBarType.error}>
                    {errorMessage || walletDetailsError}
                  </MessageBar>
                  <Separator />
                </>
              )}
              <LinkContainer>
                <LinkCallout
                  buttonId={buttonId}
                  isCalloutVisible={isCalloutVisible}
                  validLinks={validLinks}
                  clearLinkErrors={clearLinkErrors}
                  toggleIsCalloutVisible={toggleIsCalloutVisible}
                  handleLink={handleLink}
                  closeLinkCallout={closeLinkCallout}
                  createLink={createLink}
                />
                <OperationTitle>Descrição da alteração</OperationTitle>
                <LinkIcon>
                  <IconButton
                    id={buttonId}
                    iconProps={linkIcon}
                    title='Adicionar Link'
                    ariaLabel='Adicionar Link'
                    style={{ backgroundColor: "transparent" }}
                    onClick={() => toggleIsCalloutVisible()}
                    styles={{
                      icon: { color: "#000", fontSize: 18 },
                    }}
                  />
                </LinkIcon>
              </LinkContainer>
              <Stack gap={12}>
                <OperationTextArea
                  contentEditable='true'
                  id='descriptionOperation'
                  onInput={(e) => setReasonOperation(e.target.innerHTML)}
                ></OperationTextArea>
                {/* <TextField
                  label='Descrição da alteração'
                  id='descriptionOperation'
                  multiline
                  rows={3}
                  value={reasonOperation}
                  onChange={(e, val) => setReasonOperation(val)}
                /> */}
                <DatePicker
                  label={"Data da alteração"}
                  value={moment(createdDate).toDate()}
                  placeholder="Selecione uma data"
                  ariaLabel="Selecione uma data"
                  onSelectDate={(val) =>
                    setCreatedDate(moment(val).format(`YYYY-MM-DD${moment().format('THH:mm:ssZ')}`))
                  }
                  formatDate={(date) => moment(date).format("DD/MM/YYYY HH:mm")}
                />
                <ButtonStyles
                  text={
                    walletDetailsLoading
                      ? "Atualizando..."
                      : "Atualizar Carteira"
                  }
                  onClick={updateWallet}
                  disabled={
                    walletDetailsLoading || reasonOperation?.length === 0 || !createdDate
                  }
                />
                {reasonOperation?.length === 0 && (
                  <MessageBar messageBarType={MessageBarType.warning}>
                    Adicione uma descrição
                  </MessageBar>
                )}
              </Stack>
            </>
          )}
          <Separator />
          <Stack>
            <Text>{`Composição da carteira: ${getCompositionPercent()}% Crypto | ${getCompositionPercent(
              true
            )}% USDT`}</Text>
            <Text>{`Lucro acumulado: ${
              walletProfitLoading
                ? "Carregando"
                : convertExponential(walletDetails?.profit)
            }`}</Text>
          </Stack>
        </FiltersSection>
        <Stack style={{ marginTop: -20, marginBottom: 60 }}>
          {!walletDetails?.id && (
            <MessageBar messageBarType={MessageBarType.warning}>
              Na criação da carteira, a composição deve ser em 100% USDT.
            </MessageBar>
          )}
        </Stack>
        {!walletDetails.id && (
          <FiltersSection>
            {(errorMessage || walletDetailsError) && (
              <>
                <MessageBar messageBarType={MessageBarType.error}>
                  {errorMessage || walletDetailsError}
                </MessageBar>
                <Separator />
              </>
            )}

            <Button
              text={"Criar Carteira"}
              onClick={() => createWallet()}
              disabled={walletDetailsLoading}
              loading={walletDetailsLoading}
            />
          </FiltersSection>
        )}
      </Panel>
    </>
  );
}
