class Coin {
  constructor(coin) {
    this.name = coin.name;
    this.symbol = coin.symbol;
    this.value = coin.value;
    this.coinType = coin.coinType;
    this.key = coin.symbol;
    this.text = coin.name;
  }

  static createCoinList(list) {
    if (!Array.isArray(list)) return [];

    return list.map((newCoin) => new Coin(newCoin));
  }
}

export default Coin;