import { FontIcon, IconButton, TextField } from "@fluentui/react";
import { Textarea } from "@fluentui/react-components";
import { Spinner } from '@fluentui/react';
import styled from "styled-components";

export const MessageInput = styled(Textarea)`
  width: 100%;
  border-radius: 3px;
  padding-right: 20px;
`;

export const SendButton = styled(IconButton)`
  transition: 0.1s ease-in;

  i {
    font-size: 22px;
  }

  &:hover {
    background: #f5f1f1;
  }
`;

export const TextArea = styled.div`
  max-width: 974px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const SendMessageContainer = styled.div`
  max-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid 1px #f0eded;
  padding: 12px 12px;
  padding-bottom: 6px;
  flex-direction: column;
  overflow: hidden;
`;

export const PreviewContainer = styled.div`
  max-width: 190px;
  width: 100%;
  display: flex;
  gap: 12px;
  padding: 8px;
  border-radius: 2px;
  align-items: center;
  box-shadow: -2px 2px 10px 4px rgba(235, 228, 228, 0.2);
  background: #f1eeee;
  position: relative;
  padding-right: 30px;
  cursor: pointer;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Previews = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: -4px;
  flex-wrap: wrap;
`;

export const PreviewIcon = styled(FontIcon)``;

export const TextBox = styled.div`
  width: 100%;
  min-height: 46px;
  max-height: 150px;
  border: solid 1px #d6cfcf;
  border-radius: 1px;
  margin-top: 12px;
  display: block;
  background: #faf5f5;
  padding: 12px 12px;
  overflow: auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  a {
    color: #7373f3;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b0b4b0;
    background-clip: content-box;
    border-radius: 4px;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
`;

export const LinkIcon = styled.div``;

export const DeleteIcon = styled.div`
  position: absolute;
  top: 3px;
  right: 0;
  z-index: 2;

  i {
    font-size: 12px;
  }
`;

export const MessageTitle = styled.span`
  font-size: 14px;
  color: #322130;
  font-weight: 600;
  justify-self: flex-start;
`;

export const ChatActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const CustomSpinner = styled(Spinner)`
  background-color: transparent;
  align-self: flex-end;
`;