import React from "react";

import WalletList from "./containers/listWallet";
import SidePanel from "./containers/sidePanel";
import { Container } from "./style";

export default function PortfolioView() {
  return (
    <Container>
      <SidePanel />
      <WalletList />
    </Container>
  );
}
