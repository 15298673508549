import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import GlobalStyle from './core/ui/global';
import AppRouter from './core/providers/appRouter';
import ClientPush from './core/container/pushPanel';
import store from './core/redux/store';
import { ThemeProvider } from '@fluentui/react';
import { MsalProvider } from "@azure/msal-react";
import { msalApp } from './core/providers/AuthProvider';
import moment from 'moment';
import 'moment/locale/pt-br'
import { enableIndexedDbPersistence } from "firebase/firestore";
import { db } from './core/constants/firebase';
moment.locale('pt-BR');

const cryptoTheme = {
  palette: {
    themePrimary: '#DB6714',
    themeDarkAlt: '#DB6714',
    themeDark: '#DB6714',
  },
};

function App() {

  // useEffect(() => {
  //   enableIndexedDbPersistence(db)
  //     .then(() => console.log("Enabled offline persistence"))
  //     .catch((error) => {
  //       if (error.code == "failed-precondition") {
  //         // Multiple tabs open, persistence can only be enabled
  //         // in one tab at a a time.
  //         // ...
  //       } else if (error.code == "unimplemented") {
  //         // The current browser does not support all of the
  //         // features required to enable persistence
  //         // ...
  //       }
  //     });
  // }, []);

  return (
    <Provider store={store}>
      <MsalProvider instance={msalApp}>
        <GlobalStyle />
          <ThemeProvider theme={cryptoTheme}>
            <AppRouter/>
            <ClientPush />
          </ThemeProvider>
      </MsalProvider>
    </Provider>
  );
}

export default App;
