import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClient,
  handleOpenPanel,
  resetDeleteErrors,
} from "../../../../core/redux/actions/clientsAction";
import ClientInfoEdit from "./components/clientEdit";
// import Integrations from "./components/integrations";
import { PanelStyles, Message } from "./style";
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  TeachingBubble,
} from "@fluentui/react";
import PaymentInfo from "./components/paymentInfo";
import Extract from "./components/extract";
import { useState } from "react";
import { useId } from "@fluentui/react-hooks";
import { useEffect } from "react";

export default function ClientDetails() {
  const dispatch = useDispatch();
  const {
    panelIsOpen,
    loadingDelete,
    successDelete,
    failureDelete,
    clientDetails,
  } = useSelector((store) => store.clients);
  const [teachingBubbleVisible, setTeachingBubbleVisible] = useState(false);
  const buttonId = useId("targetDeleteUser");

  const SecondaryButtonProps = React.useMemo(
    () => ({
      children: "Não",
      onClick: () => setTeachingBubbleVisible(false),
    }),
    [teachingBubbleVisible]
  );

  useEffect(() => {
    if (successDelete) {
      const timer = setTimeout(() => {
        dispatch(resetDeleteErrors());
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [successDelete]);

  useEffect(() => {
    if (failureDelete) {
      const timer = setTimeout(() => {
        dispatch(resetDeleteErrors());
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [failureDelete]);

  return (
    <>
      <Panel
        isOpen={panelIsOpen}
        onDismiss={() => dispatch(handleOpenPanel(false))}
        headerText={"Editar cliente"}
        closeButtonAriaLabel='Close'
        isFooterAtBottom={true}
        headerClassName={{
          backgroundColor: "#fff",
          padding: "12px 0",
          marginTop: "-10px",
        }}
        type={PanelType.large}
        styles={PanelStyles}
      >
        <ClientInfoEdit />
        <PaymentInfo />
        <Extract />
        <DefaultButton
          onClick={() => setTeachingBubbleVisible(true)}
          id={buttonId}
          style={{ marginTop: 12 }}
        >
          {loadingDelete ? "Atualizando..." : "Desativar usuário"}
        </DefaultButton>
        {teachingBubbleVisible && (
          <TeachingBubble
            target={`#${buttonId}`}
            primaryButtonProps={{
              children: "Sim",
              onClick: () => {
                setTeachingBubbleVisible(false);
                dispatch(deleteClient(clientDetails?.userId));
              },
            }}
            secondaryButtonProps={SecondaryButtonProps}
            onDismiss={setTeachingBubbleVisible}
            headline='Tem certeza que deseja desativar esse usuário?'
          />
        )}
        {(successDelete || failureDelete) && (
          <Message>
            <MessageBar
              messageBarType={
                failureDelete ? MessageBarType.error : MessageBarType.success
              }
            >
              {successDelete
                ? "Usuário desativado com sucesso!"
                : "Ocorreu um erro ao desativar o usuário"}
            </MessageBar>
          </Message>
        )}
      </Panel>
    </>
  );
}
