import React, { useEffect } from "react";
import ClientDetails from "./containers/clientDetails";
import ClientList from "./containers/clientList";
import { Container } from "./style";

export default function Client() {
  return (
    <Container>
      <ClientDetails />
      <ClientList />
    </Container>
  );
}