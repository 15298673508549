import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import Instruction from "../../entities/instruction";
import {
  requestSignalsList,
  requestTradeTypePercentile,
  postSignal,
  requestSignalById,
  putSignal,
  putFinishSignal,
  postNewNote,
} from "../../services/signals";
import { RootState } from "../store";
import Dispatch from "redux";
import Signal from "../../entities/signal";
import moment from "moment";

export const handleSignalFilter = (data) => ({
  type: "SIGNAL_FILTER",
  payload: data,
});

export const getSignalsList = () => async (dispatch, store) => {
  if (!store().signal.hasMore) return;
  if (store().signal.signalsListLoading) return;

  dispatch({ type: "SIGNAL_LIST_LOADING", payload: true });

  try {
    const signalsList = await requestSignalsList(
      store().signal.page,
      store().signal.filters
    );

    if (Array.isArray(signalsList?.itens)) {
      const signalsArray = signalsList?.itens.map((item) => new Signal(item));
      const lastList = store().signal.signalsList;

      dispatch({
        type: "SIGNAL_LIST",
        payload: {
          list: lastList.concat(signalsArray),
          page: signalsList.pageIndex + 1,
          hasMore: signalsList.hasNextPage,
        },
      });
    }
  } catch (e) {
    console.error("Ocorreu um erro ao CHAMAR A LISTA DE SINAIS", e);
    dispatch({ type: "SIGNAL_LIST_ERROR", payload: false });
  }

  dispatch({ type: "SIGNAL_LIST_LOADING", payload: false });
};

export const getUpdateList = () => async (dispatch, store) => {
  dispatch({ type: "SIGNAL_LIST_LOADING", payload: true });

  try {
    const signalsList = await requestSignalsList(1, store().signal.filters);

    dispatch({
      type: "SIGNAL_LIST",
      payload: {
        list: signalsList.itens,
        page: signalsList.pageIndex + 1,
        hasMore: signalsList.hasNextPage,
      },
    });

  } catch (e) {
    console.error("Ocorreu um erro ao CHAMAR A LISTA DE SINAIS", e);
    dispatch({ type: "SIGNAL_LIST_ERROR", payload: false });
  }

  dispatch({ type: "SIGNAL_LIST_LOADING", payload: false });
};

export const resetListResults = () => (dispatch) => {
  dispatch({
    type: "SIGNAL_LIST_RESET",
  });

  dispatch(getSignalsList());
};

export const setFilterPainelIsOpen = (isOpen) => ({
  type: "SIGNAL_FILTER_PANEL_OPEN",
  payload: isOpen,
});

export const setPainelIsOpen = (isOpen) => ({
  type: "SIGNAL_PANEL_OPEN",
  payload: isOpen,
});

export const setPainelDetailsIsOpen = (isOpen) => ({
  type: "SIGNAL_DETAILPANEL_OPEN",
  payload: isOpen,
});

export const setPopupIsOpen = (isOpen) => ({
  type: "SIGNAL_POPUP_OPEN",
  payload: isOpen,
});

export const setSignalId = (data) => ({
  type: "SET_SIGNAL_ID",
  payload: data,
});

export const getTradesPercentile =
  (tradeOption, callback) => async (dispatch) => {
    dispatch({ type: "SIGNAL_TRADE_PERCENTILE_LOADING", payload: true });

    try {
      const percentiles = await requestTradeTypePercentile(tradeOption);

      const targets = [];
      if (percentiles.target1) targets.push(percentiles.target1);
      if (percentiles.target2) targets.push(percentiles.target2);
      if (percentiles.target3) targets.push(percentiles.target3);
      if (percentiles.target4) targets.push(percentiles.target4);
      if (percentiles.target5) targets.push(percentiles.target5);

      let stopLossValue = parseFloat((percentiles.stopLoss * 100).toFixed(2));
      let entryRangeValue = parseFloat(
        (percentiles.entryRange * 100).toFixed(2)
      );
      const percentTargets = targets.map((item) =>
        parseFloat((item * 100).toFixed(2))
      );

      callback({
        maxEntryPercent: stopLossValue,
        stopLossPercent: entryRangeValue,
        entryInputValue: entryRangeValue,
        stopLossInputValue: stopLossValue,
        targets: percentTargets,
      });

      dispatch({
        type: "SIGNAL_TRADE_PERCENTILE",
        payload: {
          stopLoss: stopLossValue,
          entryRange: entryRangeValue,
          targets: percentTargets,
        },
      });
    } catch (e) {
      console.error("Ocorreu um erro ao CHAMAR AS PORCENTAGENS DE SINAIS", e);
    }

    dispatch({ type: "SIGNAL_TRADE_PERCENTILE_LOADING", payload: false });
  };

export const createSignal =
  (signalObj, signalId = null) =>
  async (dispatch) => {
    dispatch({ type: "SIGNAL_DETAIL_LOADING", payload: true });
    let id = "";

    try {
      if (signalId) {
        await putSignal(signalObj, signalId);
      } else {
        id = await postSignal(signalObj);
      }
      dispatch(setSignalId(id));
    } catch (e) {
      dispatch({
        type: "SIGNAL_DETAIL_ERROR",
        payload: "Ocorreu um erro ao POSTAR UM NOVO SINAL",
      });
      console.error("Ocorreu um erro ao POSTAR UM NOVO SINAL", e);
    }

    dispatch({ type: "SIGNAL_DETAIL_LOADING", payload: false });
  };

export const finishSignal =
  (signalId = null) =>
  async (dispatch) => {
    if (!signalId) return null;

    try {
      dispatch({ type: "SIGNAL_DETAIL_LOADING", payload: true });
      await putFinishSignal(signalId);
      dispatch({ type: "SIGNAL_FINISH", payload: false });
      dispatch(getUpdateList());
      dispatch(getSignalsById(signalId));
    } catch (e) {
      dispatch({
        type: "SIGNAL_DETAIL_ERROR",
        payload: "Ocorreu um erro ao ENCERRAR O SINAL",
      });
      console.error("Ocorreu um erro ao ENCERRAR O SINAL", e);
    }

    dispatch({ type: "SIGNAL_DETAIL_LOADING", payload: false });
  };

export const sendNewNote =
  (note, graphicValue, signalId = null) =>
  async (dispatch, store) => {
    if (!signalId) return null;

    dispatch({ type: "SIGNAL_NOTE_LOADING", payload: true });

    try {
      const newNotes = await postNewNote(signalId, {
        note,
        graphic: graphicValue,
        date: moment().format("YYYY-MM-DDThh:mm:ss"),
      });

      dispatch({ type: "SIGNAL_NOTE", payload: newNotes });
    } catch (e) {
      console.error("Ocorreu um erro ao ENCERRAR O SINAL", e);
    }

    dispatch({ type: "SIGNAL_NOTE_LOADING", payload: false });
  };

//DATA DE CRIAÇÃO DO SINAL
export const signalCreationDate = (Signal, date) => async (dispatch) => {
  if (!Signal) return null;

  dispatch({ type: "SIGNAL_CREATE_DATE", payload: true });

  try {
    const signalDate = await signalDate(Signal, {
      date: moment().format("DD/MM/YYYY HH:mm:ss"),
    });

    dispatch({ type: "SIGNAL_CREATE_DATE", payload: signalDate });
  } catch (e) {
    console.log("teste data");
  }

  dispatch({ type: "SIGNAL_CREATE_DATE", payload: false });
};

//DATA DE ENCERRAMENTO DO SINAL
export const signalCloseDate = (signalId, date) => async (dispatch) => {
  if (!signalId) return null;

  dispatch({ type: "SIGNAL_CLOSE_DATE", payload: true });

  try {
    const signalCloseDate = await signalCloseDate(signalId, {
      date: moment().format("DD/MM/YYYY HH:mm:ss"),
    });

    dispatch({ type: "SIGNAL_DATE", payload: signalCloseDate });
  } catch (e) {
    console.log("teste data close");
  }

  dispatch({ type: "SIGNAL_CLOSE_DATA", payload: false });
};

export const setResetSignal = (isOpen) => ({
  type: "SIGNAL_RESET",
  payload: "isOpen",
});

export const updateFilter = (filterName, value) => {
  const newValue = {};
  newValue[filterName] = value;

  return {
    type: "SIGNAL_UPDATE_FILTER",
    payload: newValue,
  };
};

export const getSignalId = (isOpen) => {
  return {
    type: "SIGNAL_ID",
    payload: isOpen,
  };
};

export const getSignalListId = () => (dispatch) => {
  dispatch({
    type: "SIGNAL_LIST_ID",
  });
  dispatch(getSignalListId());
};

export const getSignalsById = (id) => async (dispatch, store) => {
  if (!id) {
    dispatch({ type: "SIGNAL_DETAIL", payload: null });
    return;
  }

  dispatch({ type: "SIGNAL_DETAIL_LOADING", payload: true });

  try {
    const signal = await requestSignalById(id);

    dispatch({
      type: "SIGNAL_DETAIL",
      payload: signal,
    });
  } catch (e) {
    console.error("Ocorreu um erro ao CHAMAR O DETALHE DE SINAIS ID =>", id, e);
    dispatch({
      type: "SIGNAL_DETAIL_ERROR",
      payload:
        "Ocorreu um erro ao carregar os dados deste sinal, tente novamente mais tarde.",
    });
  }

  dispatch({ type: "SIGNAL_DETAIL_LOADING", payload: false });
};

