import styled from 'styled-components';
import { Stack, Text } from '@fluentui/react';

export const Container = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  background: #fff;
  border: solid 1px rgb(243, 242, 241);
  box-shadow: -24px 24px 30px 16px rgba(235, 228, 228, 0.2);
  opacity: 1;
  transition: opacity 0.1s ease;
  z-index: 2;
`;


export const ComboBoxStyles = styled(Stack)`
 width: 290px;
 height: 254px;
 background: rgb(236, 236, 236);
`;

export const SearchBoxStyles = styled(Stack)``;

export const horizontalGapStackTokens = {
  childrenGap: 10,
  padding: "10px 0",
};

export const panelStyles = { root: { padding: "200" } };

export const dropdownStyles = {
  dropdown: { width: 300, childrenGap: 20 },
};