import * as React from 'react';
import { mergeStyleSets, DefaultButton, FocusTrapZone, Layer, Overlay, Popup } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useDispatch, useSelector } from "react-redux";
  import {setPopupIsOpen, setPainelIsOpen, createSignal, getTradesPercentile  }from '../../../../src/core/redux/actions/signalsAction';
import { Radio } from '@fluentui/react-components';


const popupStyles = mergeStyleSets({
  root: {
    bottom: '0',
    left: '0',
    position: 'fixed',
    right: '0',
    top: '0',
    
  },
  content: {
    background: '#fdfdfd',
    border: '1px solid #909090 ',
    left: '50%',
    maxWidth: '400px',
    fontfamily: "Poppins, serif" ,
    padding: '0 2em 2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',

  },
});

 const PopupSignal = () => {
  const dispatch = useDispatch();
  const [PopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
  return (
    <>
      <DefaultButton onClick={showPopup} text="Sim" />
      {/* text="Show modal popup" */}
      {PopupVisible && (
        <Layer>
          <Popup
            className={popupStyles.root}
            role="dialog"
            aria-modal="true"
            onClick={hidePopup}
            // onClick={() => {dispatch(setPainelIsOpen(false))}}
            onDismiss={() => dispatch(setPopupIsOpen(false))}
            // enableAriaHiddenSiblings={true}
            // onDismiss={dispatch}
          >
            <Overlay onClick={hidePopup} />
            <FocusTrapZone>
              <div role="document" className={popupStyles.content}>
              <h3>Sinal encerrado com sucesso</h3>
                <DefaultButton onClick={hidePopup}>Sair</DefaultButton> 
              </div>
            </FocusTrapZone>
          </Popup>
        </Layer>
      )}
    </>
  );
};

export default PopupSignal;