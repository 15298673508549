import React, { useEffect, useState } from "react";
import useUnreadMessages from "../../../../../../core/utils/hooks/useUnreadMessages";

import {
  Group,
  GrupoName,
  ActionButtons,
  GroupAction,
  UnreadDot,
  GroupIcon,
} from "./style";
import { useSelector } from "react-redux";
const addGroup = { iconName: "CommentAdd" };
const edit = { iconName: "Edit" };

export default function GroupSelector({
  setChat,
  chatName,
  selected,
  admin,
  groupName,
  getGroup,
}) {
  const { message } = useUnreadMessages(groupName);
  const { account } = useSelector((store) => store.auth);
  const [showUnread, setShowUnread] = useState(false);
  const unreadMessage = JSON.parse(
    localStorage.getItem(`lastMessage - ${groupName}`)
  );

  const getUnreadStorage = async () => {
    try {
      if (!unreadMessage) {
        setShowUnread(true);
        return;
      }

      if (message?.id === account?.localAccountId) {
        setShowUnread(false);
        return;
      }

      if (unreadMessage?.messageId !== message?.key) {
        setShowUnread(true);
        return;
      }

      setShowUnread(false);
    } catch (error) {
      console.log(error);
      setShowUnread(false);
    }
  };

  useEffect(() => {
    if (message) {
      getUnreadStorage();
    }
  }, [message, chatName]);

  return (
    <Group onClick={setChat} selected={selected}>
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <GrupoName selected={selected}>
          <GroupIcon
            aria-label='Closed'
            iconName={admin ? "SecurityGroup" : "ReminderGroup"}
            selected={selected}
          />
          {groupName}
        </GrupoName>
        {showUnread && !selected && <UnreadDot />}
      </div>
      <ActionButtons className='actions' selected={selected}>
        <GroupAction iconProps={edit} onClick={getGroup} selected={selected} />
      </ActionButtons>
    </Group>
  );
}
