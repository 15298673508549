import { Stack } from '@fluentui/react';
import styled from 'styled-components';

export const Container = styled(Stack)`
  width: calc(100% - 127px);
  display: flex;
  flex-direction: column;
  padding: 18px;
  background: #fff;
  border: solid 1px rgb(243, 242, 241);
  box-shadow: -2px 2px 10px 4px rgba(235, 228, 228, 0.2);
  opacity: 1;
  transition: opacity 0.1s ease;
  min-height: 100vh;
  z-index: 2;
`;


export const horizontalGapStackTokens = {
  childrenGap: 10,
  padding: "10px 0",
};