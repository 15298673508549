import { FontIcon, IconButton, TextField } from "@fluentui/react";
import { Textarea } from "@fluentui/react-components";
import { Spinner } from "@fluentui/react";
import styled from "styled-components";

export const ReplyContainer = styled.div`
  width: 100%;
  border-radius: 3px;
  background: #fff;
  box-shadow: -2px 2px 10px 4px rgba(235, 228, 228, 0.3);
  min-height: 30px;
  border: solid 1px #d6cfcf;
  padding: 8px;
  margin-bottom: -8px;
  overflow: hidden;
  border-bottom-left-radius: 0;
  border-bottom: none;
  border-bottom-right-radius: 0;
  ${({ readMessages }) => readMessages && `border-radius: 3px; border: solid 1px #e9e0e0`};
`;

export const MessageContainer = styled.div`
  width: 100%;
  min-height: 30px;
  padding: 6px 12px;
  background-color: #faf5f5;
  border-radius: 4px;
  border-left-style: solid;
  border-left-width: 4px;
  border-left-color: ${({ outgoing }) => (outgoing ? `#eb7420` : "#77a1fcba")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 100px;
  overflow: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b0b4b0;
    background-clip: content-box;
    border-radius: 4px;
  }
`;

export const ReplyHeader = styled.div`
  margin-bottom: 5px;
  position: relative;
  width: 100%;
`;

export const Author = styled.span`
  margin-right: 14px;
  font-weight: 600;
  font-size: 13px;
`;

export const CreatedAt = styled.span`
  font-size: 12px;
  color: #383838;
  padding-bottom: 4px;
`;

export const TextContainer = styled.div`
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
`;

export const Message = styled.p`
  margin: 0;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: -6px;
  right: -12px;

  i {
    font-size: 12px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 200px;
  margin-top: 6px;
`;

export const Link = styled.a`
  color: #1b3aec;
`;

export const ImageActions = styled.div`
  cursor: pointer;
`;
