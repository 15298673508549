import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import authConfig from "../constants/auth";
import { auth } from "../constants/firebase";
import {
  setAccount,
  setUserAuthData,
  requestTokensAuth,
  getUserAdInfo,
} from "../redux/actions/authActions";
import { OAuthProvider, signInWithPopup } from "firebase/auth";

const provider = new OAuthProvider('microsoft.com');
provider.addScope('user.read');

export const msalApp = new PublicClientApplication(authConfig.msalConfig);

provider.setCustomParameters({
  tenant: '6024a7b0-bca2-4b9d-b1a6-d2a11d87415a'
});

const Authentication = ({ nonAuthenticated, children }) => {
  const dispatch = useDispatch();

  const [loadingFirebase, setLoadingFirebase] = useState(false);
  const { isAuthenticated, loadingTokens, loadingAd, adInfo } = useSelector((store) => store.auth);

  const handleFirebaseAuth = () => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setLoadingFirebase(false);
      } else {
        setLoadingFirebase(true);
        signInWithPopup(auth, provider);
      }

    // if (user) {
    //   console.log("signin success");
    // } else {
    //   signInAnonymously(auth)
    //     .then(() => {
    //       console.log("firebase signin");
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    });
  };

  const logout = async () => {
    localStorage.removeItem("account");
    localStorage.removeItem("acessotoken");
    localStorage.removeItem("user-name");
    localStorage.removeItem("user-email");
    localStorage.removeItem("user-id");
    localStorage.removeItem("user-date");
  };

  const processLogIn = (response) => {
    if (response === null) {
      getToken();
      return;
    }

    const acessotoken = `bearer  ${response.accessToken}`;
    const name = response?.account?.name;
    const email = response?.account?.username;
    const id = response?.account?.localAccountId;
    const date = response?.account?.username;
    localStorage.setItem("account", JSON.stringify(response));
    localStorage.setItem("acessotoken", acessotoken);
    localStorage.setItem("user-name", name);
    localStorage.setItem("user-email", email);
    localStorage.setItem("user-id", id);
    localStorage.setItem("user-date", date);

    dispatch(setUserAuthData(acessotoken, name, email));
    // dispatch(setUserIdData(acessotoken, id, date)); //token
    dispatch(setAccount(response?.account));
  
    handleFirebaseAuth();

    if (response?.accessToken) {
      dispatch(getUserAdInfo(id, response?.accessToken))
    }

    const authCredentialsAdmin = JSON.parse(localStorage.getItem("authCredentialsAdmin"));

    if (!authCredentialsAdmin?.accessToken) {
      const body = {
        externalUUID: id,
        name: name,
        email: email,
      };

      dispatch(requestTokensAuth(body));
    }

    // // set token header
    // axios.defaults.headers.common.Authorization = acessotoken;

    return response;
  };

  const getToken = () => {
    const account = localStorage.getItem("account");

    if (!account) {
      msalApp.acquireTokenRedirect(authConfig.tokenRequest);
      return;
    }

    const localAccount = JSON.parse(account)?.account;
    const tokenRequest = {
      ...authConfig.tokenRequest,
      account: localAccount,
    };

    return msalApp
      .acquireTokenSilent(tokenRequest)
      .then(processLogIn)
      .catch(() => {
        logout();
        return msalApp.acquireTokenRedirect(tokenRequest);
      });
  };

  const handleAuthentication = () => {
    const storagedAccount = localStorage.getItem("account");

    if (storagedAccount) {
      getToken();
    } else {
      msalApp
        .handleRedirectPromise()
        .then(processLogIn)
        .catch(() => {
          logout();
          getToken();
        });
    }
  };

  useEffect(() => {
    if (adInfo?.jobTitle) {
      sessionStorage.setItem("jobTitle", adInfo?.jobTitle);
    }
  }, [adInfo?.jobTitle])
  
  useEffect(() => {
    handleAuthentication();
  }, []);

  if (!isAuthenticated || loadingTokens || loadingAd || loadingFirebase) return <>{nonAuthenticated}</>;

  return <>{children}</>;
};

export default Authentication;
