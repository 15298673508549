import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { columns } from "./column";
import { Container, SectionTitle, Section, Content } from "./style";
import { DetailsListLayoutMode, SelectionMode } from "@fluentui/react";
import { getClientExtract } from "../../../../../../core/redux/actions/clientsAction";

const values = [
  {
    createdAt: `${new Date()}`,
    planName: "TCI 1",
    value: 1920,
    value2: 960,
    discount: 10,
    paymentMethod: "Cartão de crédito",
    status: "Pago",
  },
  {
    createdAt: `${new Date()}`,
    planName: "ICI 1",
    value: 2120,
    value2: 960,
    discount: 10,
    paymentMethod: "Boleto",
    status: "Pago",
  },
  {
    createdAt: `${new Date()}`,
    planName: "ICI 2",
    value: 1920,
    value2: 960,
    discount: 10,
    paymentMethod: "Cartão de crédito",
    status: "Cancelado",
  },
  {
    createdAt: `${new Date()}`,
    planName: "TCI 2",
    value: 1920,
    value2: 960,
    discount: 10,
    paymentMethod: "Pix",
    status: "Expirado",
  },
  {
    createdAt: `${new Date()}`,
    planName: "TCI 2",
    value: 1920,
    value2: 960,
    discount: 10,
    paymentMethod: "Pix",
    status: "Expirado",
  },
  {
    createdAt: `${new Date()}`,
    planName: "TCI 2",
    value: 1920,
    value2: 960,
    discount: 10,
    paymentMethod: "Pix",
    status: "Expirado",
  },
  {
    createdAt: `${new Date()}`,
    planName: "TCI 2",
    value: 1920,
    value2: 960,
    discount: 10,
    paymentMethod: "Pix",
    status: "Expirado",
  },
];

export default function Extract() {
  const { extract, clientDetails } = useSelector((store) => store.clients);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clientDetails?.userId) {
      dispatch(getClientExtract(clientDetails?.userId))
    }
  }, [clientDetails])

  return (
    <Container>
      {extract?.length > 0 && (
        <Content>
          <SectionTitle>Extrato</SectionTitle>
          <Section>
            <ShimmeredDetailsList
              items={extract}
              columns={columns}
              setKey='set'
              disableSelectionZone
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn='Toggle selection'
              ariaLabelForSelectAllCheckbox='Toggle selection for all items'
              checkButtonAriaLabel='select row'
            />
          </Section>
        </Content>
      )}
    </Container>
  );
}
