import { IconButton, TextField } from "@fluentui/react";
import { Textarea } from "@fluentui/react-components";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-right: solid 1px #f1eded;
  background-color: #ffffff;
  box-shadow: -2px 2px 10px 2px rgba(116, 112, 112, 0.123);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  border-bottom: solid 1px #e0d6d6;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const HeaderTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

export const Hashtag = styled.span`
  font-size: 18px;
  color: #504b4b;
  position: relative;
  top: 2px;
  margin-right: 6px;
`;