const initialState = {
  coinList: [],
  coinsListLoading: false,
  coinDetail: null,
  loading: false,
  loadingDetail: false,
  exchange: 0,
};

const signalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "COINS_LIST_START":
      return {
        ...state,
        coinsListLoading: true,
      };
    case "COINS_LIST_SUCCESS":
      return {
        ...state,
        coinList: action.payload.list,
        exchange: action.payload.exchange,
        coinsListLoading: false,
      };
    case "COINS_LIST_FAILURE":
      return {
        ...state,
        coinsListLoading: false,
      };
    case "COIN_DETAILS_START":
      return {
        ...state,
        loadingDetail: true,
      };
    case "COIN_DETAILS_SUCCESS":
      return {
        ...state,
        coinDetail: action.payload,
        loadingDetail: false,
      };
    case "COIN_DETAILS_FAILURE":
      return {
        ...state,
        loadingDetail: false,
      };
    default:
      return state;
  }
};

export default signalReducer;
