import { useState, useEffect } from "react";
import moment from "moment";
import { db } from "../../constants/firebase";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";

const useMessages = (chatName) => {
  const [messages, setMessages] = useState([]);
  const [end, setEnd] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(true);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [originalCollection, setOriginalCollection] = useState([]);

  useEffect(() => {
    setMessages([]);
    setEnd(null);
    setOriginalCollection([]);
  }, [chatName]);

  const arrayWithNoDuplicates = (array, field) => {
    const arrayWithoutNoDuplicates = array.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t[field] === value[field])
    );
    return arrayWithoutNoDuplicates;
  };

  // Get first value cached an insert at the end of array
  useEffect(() => {
    const firstMessage = moment(messages?.[0]?.created?.toDate());
    const lastMessage = moment(
      messages[messages?.length - 1]?.created?.toDate()
    );

    if (firstMessage > lastMessage) {
      const first = messages?.[0];

      setMessages((currentList) =>
        arrayWithNoDuplicates(currentList?.slice(1)?.concat(first), "key")
      );
    }
  }, [messages, chatName]);

  useEffect(() => {
    if (chatName) {
      // Query
      const q = query(
        collection(db, "chat", chatName, "messages"),
        orderBy("created", "desc"),
        limit(15)
      );

      // Get values cache or server
      const unsubscribe = onSnapshot(
        q,
        { includeMetadataChanges: true },
        (snapshot) => {
          let messages = [];
          let originalCollection = [];

          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              messages.push({ ...change.doc.data(), key: change?.doc?.id });

              originalCollection.push(change.doc);
            }

            if (change.type === "modified") {
              setLastUpdated({ ...change.doc.data(), key: change?.doc?.id });
            }
          });

          setMessages((currentList) =>
            arrayWithNoDuplicates(
              currentList?.concat(messages?.slice()?.reverse()),
              "key"
            )
          );

          setOriginalCollection((currentList) =>
            currentList?.concat(originalCollection)
          );
        }
      );

      // Detach listener
      return () => unsubscribe();
    }
  }, [chatName]);

  useEffect(() => {
    // Find last element updated
    const index = messages.findIndex((msg) => msg?.key === lastUpdated?.key);

    if (index > -1) {
      messages[index] = lastUpdated;
    }
  }, [lastUpdated]);

  // Set end message for pagination
  useEffect(() => {
    if (!end) {
      setEnd(originalCollection[originalCollection?.length - 1]);
    }
  }, [originalCollection, end]);

  // Set last message for new message indicator
  useEffect(() => {
    let lastItem = messages[messages?.length - 1];

    if (Boolean(lastItem)) {
      localStorage.setItem(
        `lastMessage - ${chatName}`,
        JSON.stringify({ messageId: lastItem?.key, userId: lastItem?.id })
      );
    }
  }, [messages]);

  const getMoreMessages = async () => {
    try {
      setLoading(true);
      setShouldScroll(false);
      let ref = db.collection("chat").doc(chatName).collection("messages");
      // single query to get new startAt snapshot
      let additional = ref.orderBy("created", "desc").startAfter(end).limit(15);

      let documentSnapshots = await additional.get();

      // Cloud Firestore: Document Data
      let documentData = documentSnapshots.docs.map((doc) => ({
        ...doc.data(),
        key: doc.id,
      }));

      let lastVisible =
        documentSnapshots?.docs[documentSnapshots?.docs?.length - 1];
      setEnd(lastVisible);

      const newArray = documentData
        .reverse()
        .filter(({ key }) => !messages.some((e) => e.key === key));

      if (Array.isArray(newArray) && newArray?.length > 0) {
        setMessages([...newArray, ...messages]);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return {
    messages,
    getMoreMessages,
    shouldScroll,
    loading,
    setMessages,
  };
};

export default useMessages;
