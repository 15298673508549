import React from "react";
import { Container, UserIcon } from "./style";

const AccessDenied = () => {
  return (
    <Container>
      <UserIcon aria-label='UserRemove' iconName='UserRemove' />
      <h1>Sem permissão</h1>
      <p>
        Você não tem permissão para acessar o sistema, verifique com os
        administradores.
      </p>
    </Container>
  );
};

export default AccessDenied;
