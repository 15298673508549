import React from "react";

const PaymentStatement = () => {
  return (
    <div>
      <h1>Extrato de pagamento</h1>
    </div>
  );
}

export default PaymentStatement;